import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { Firestore, doc, onSnapshot, DocumentReference, docSnapshots, CollectionReference, collection, collectionSnapshots, query, orderBy, getDoc, setDoc, QueryDocumentSnapshot, DocumentData, where } from '@angular/fire/firestore';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { deleteDoc, getDocs } from 'firebase/firestore';
import { DigiDineTableComponent } from '../digi-dine-table/digi-dine-table.component';
import { BillOutDialogComponent } from '../../components/bill-out-dialog/bill-out-dialog.component';
import { AddDiscountDialogComponent } from '../../components/add-discount-dialog/add-discount-dialog.component';
@Component({
  selector: 'app-digi-dine-active-orders',
  templateUrl: './digi-dine-active-orders.component.html',
  styleUrls: ['./digi-dine-active-orders.component.less']
})
export class DigiDineActiveOrdersComponent implements OnInit {

  private paramSubscription: Subscription | undefined;
  constructor(private route: ActivatedRoute,private digiDineService : DigiDineService,
    public dialog: MatDialog,private firestore: Firestore) { }
  outletHashId : string ="";

  ngOnInit(): void {
    
    this.paramSubscription = this.route.params.subscribe(params => {
      this.outletHashId = params['param1']; 
      this.fetchTables(this.currentPage, this.pageSize);
      this.viewTables();
    });
  }
  pageSize: number = 100;
  totalItems!: number;
  currentPage: number = 1;
  dataSource : any;
  fetchTables(page: number, pageSize: number): void {
  
    this.digiDineService.getTables(page, pageSize,this.outletHashId).subscribe(response => {
      this.dataSource = response.response_data.items;
    },error=>{
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    
  this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  col: CollectionReference | undefined;
  tables :any;
  viewTables(){
    this.col = collection(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders`);
    const ordersQuery = query(
      this.col,  
      where('order_status', '==', 'ACTIVE')
    );
    const q = query(ordersQuery, orderBy("created_date", "asc"));  // Add this line

    collectionSnapshots(q).subscribe(snapCollection => {  // Update this line
      this.tables = snapCollection
      snapCollection.forEach(doc => {
        var docX : any = doc.data();
        this.viewOrders(doc.id,docX.table_number);
      });
    });
}

  orders : any = {};
  foodOrders : any[] = [];
  viewOrders(tableNumber : any,tableNumberActual : any) : any{
    var colOrders: CollectionReference = collection(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumber}/Orders`);
    const q = query(colOrders, orderBy("created_date", "asc"));  // Add this line
    collectionSnapshots(q).subscribe(snapCollection => {
      this.orders[tableNumber] =snapCollection;
      this.foodOrders = this.foodOrders.filter(order=> order.tableNumberId !=tableNumber);
      snapCollection.forEach(doc => {
        this.foodOrders = this.foodOrders.filter(order => order.id !== doc.id);
        this.timeElapsed(doc);
        var docX:any = doc.data();
        var dataPushed = {
          ...docX,
          id : doc.id,
          tableNumberId : tableNumber,
          table_number : tableNumberActual
        };
        this.timeElapsed2(dataPushed);
        this.foodOrders.push(dataPushed);
      });
    });
}


sortFoodByCreatedDateAsc(foodOrders : any[]){
  foodOrders.sort((a, b) => a.created_date.toDate().getTime() - b.created_date.toDate().getTime());

   let filteredOrders = foodOrders.filter(order => order.status !== "SERVED");
   let filteredOrders2 = filteredOrders.filter(order => order.show_in_kitchen === false);
  return filteredOrders2

}


updateOrderStatus(tableNumber :any , orderId : any,status:any){

  const dialogRef = this.dialog.open(ConfirmationDialogComponent);
  
  dialogRef.componentInstance.confirmMessage = "Are you sure you want to change the status?"

  dialogRef.afterClosed().subscribe(result => {
    if(result) { 
      var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumber}/Orders/${orderId}`);

        setDoc(docOrder, { 
          status : status,
        }, {
          merge: true,
        });

    } else {
    }
  });
  
}


checkIfAllServed(orders : QueryDocumentSnapshot<DocumentData>[]){

  if(orders.length > 0 ){
    var allServed : boolean = true;
    orders.forEach(doc  => {
      var docX :any = doc.data();
      if(docX.status !== 'SERVED'){
        allServed = false;
      }
    });
    return allServed;

  }else{
    return false;
  }



}
totalAmount(orders : any[]){
  var totalAmountVal = 0.0;
  orders.forEach(doc  => {
    var docX :any = doc.data();
    totalAmountVal = totalAmountVal + (docX.price*docX.quantity);
  });
  return totalAmountVal;
}



billOut(tableNumberId : any, totalAmount :any, table : any,discountAmount : any){
  var tableData = table.data();
  const dialogRef = this.dialog.open(BillOutDialogComponent, {
    width: '500px',
    // Pass any data you need to the dialog here
    data: {
      outlet_hash_id : this.outletHashId,
      order_id : tableNumberId,
      total_amount : totalAmount,
      table_data : tableData,
      discount_amount : discountAmount == null ? '0.00' :discountAmount
    }
  });

  dialogRef.afterClosed().subscribe(result => {
   
    if(result ==  true){
      let date: Date = new Date();
            var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}`);
            setDoc(docOrder, {
              completed_date :date,
              order_status : "COMPLETED"
            }, {merge:true}).then(() => {
              console.log("Document successfully written!");
            }).catch((error) => {
              console.error("Error writing document: ", error);
            });
    }
    console.log('The dialog was closed');
  });
       
}


addDiscount(tableNumberId : any, totalAmount :any, discountAmount : any){
  const dialogRef = this.dialog.open(AddDiscountDialogComponent, {
    width: '500px',
    // Pass any data you need to the dialog here
    data: {
      outlet_hash_id : this.outletHashId,
      order_id : tableNumberId,
      total_amount : totalAmount,
      discount_amount : discountAmount == null ? '' :discountAmount
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
       
}

killSession(tableNumberId : any){
  var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}`);
  deleteDoc(docOrder).then(() => {
    console.log("Document successfully deleted!");
  }).catch((error) => {
    console.error("Error writing document: ", error);
  });
}

cancelOrder(tableNumberId : any,orderId : any, name :any, quantity :any){

  if(quantity > 1){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
  
    dialogRef.componentInstance.confirmMessage = `Are you sure you wish to reduce the total order count for "${name}" by one?`
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) { 
        var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}/Orders/${orderId}`);
        setDoc(docOrder, { 
          quantity : quantity-1,
        }, {
          merge: true,
        });
  
      } else {
      }
    });
  }else{
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
  
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to cancel this order "${name}"?`
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) { 
        var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}/Orders/${orderId}`);
        deleteDoc(docOrder).then(() => {
          console.log("Document successfully deleted!");
        }).catch((error) => {
          console.error("Error writing document: ", error);
        });
  
      } else {
      }
    });
  }

 



}


removeOccupiedTables(dataSource:any[], tables:any[] ) {
  tables.forEach(table => {
     var tableX = table.data();
     dataSource = dataSource.filter(x=>x.table_number.toString() != tableX.table_number);
  });  
  return dataSource;
}

isLoading = false;

async startSession(tableNumber:any){
this.isLoading = true;
  var docTableExist: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}`);
  const ordersQuery = query(
    collection(docTableExist, 'ActiveOrders'),  // Replace 'YourSubCollection' with the actual subcollection name
    where('table_number', '==', tableNumber.toString()),
    where('order_status', '==', 'ACTIVE')
  );
  const querySnapshot = await getDocs(ordersQuery);

  if(querySnapshot.empty) {
      const tableNumberId = uuidv4();
        var docTable: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}`);
        
        getDoc(docTable).then((docTableSnapshot) => {
          if (!docTableSnapshot.exists()) {
            let date: Date = new Date();
            setDoc(docTable, {
                created_date :date,
                table_number : tableNumber.toString(),
                order_status : "ACTIVE"
            }).then(() => {
              this.isLoading = false;
              console.log("Document successfully written!");
            }).catch((error) => {
              console.error("Error writing document: ", error);
            });
          }
        });
  } 
}
private subscriptions: Subscription[] = [];
timeElapsed(order : any){
    const subscription = interval(1000).subscribe(() => {
      let currentDate = new Date();
      let createdDate = new Date(order.data().created_date.seconds * 1000); // Assuming created_date is a Timestamp
      let diff = Math.floor((currentDate.getTime() - createdDate.getTime()) / 1000); // Get the difference in seconds

      let hours = Math.floor(diff / 3600).toString().padStart(2, '0');
      let minutes = Math.floor(diff % 3600 / 60).toString().padStart(2, '0');
      let seconds = (diff % 60).toString().padStart(2, '0');


      order.elapsedTime = `${hours}:${minutes}:${seconds}`;
    });

    this.subscriptions.push(subscription);
}


timeElapsed2(order : any){
  const subscription = interval(1000).subscribe(() => {
    let currentDate = new Date();
    let createdDate = new Date(order.created_date.seconds * 1000); // Assuming created_date is a Timestamp
    let diff = Math.floor((currentDate.getTime() - createdDate.getTime()) / 1000); // Get the difference in seconds

    let hours = Math.floor(diff / 3600).toString().padStart(2, '0');
    let minutes = Math.floor(diff % 3600 / 60).toString().padStart(2, '0');
    let seconds = (diff % 60).toString().padStart(2, '0');
    
    order.elapsedTime = `${hours}:${minutes}:${seconds}`;
  });

  this.subscriptions.push(subscription);
}

}

