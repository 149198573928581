<div class="mat-elevation-z8">
   
  <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="transaction_id">
      <th mat-header-cell *matHeaderCellDef> TRX ID </th>
      <td mat-cell *matCellDef="let element"> {{element.transaction_id}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="source_account_number">
        <th mat-header-cell *matHeaderCellDef> SRC Account No. </th>
        <td mat-cell *matCellDef="let element"> {{element.source_account_number}} </td>
    </ng-container>
    
    
     <!--- Define columns -->
     <ng-container matColumnDef="source_bank_code">
        <th mat-header-cell *matHeaderCellDef> SRC Bank Code </th>
        <td mat-cell *matCellDef="let element"> {{element.source_bank_code}} </td>
      </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="destination_account_number">
        <th mat-header-cell *matHeaderCellDef> DST Account No. </th>
        <td mat-cell *matCellDef="let element"> {{element.destination_account_number}} </td>
      </ng-container>

      <!--- Define columns -->
     <ng-container matColumnDef="amount_currency">
        <th mat-header-cell *matHeaderCellDef> Currency </th>
        <td mat-cell *matCellDef="let element"> {{element.amount_currency}} </td>
      </ng-container>

       <!--- Define columns -->
     <ng-container matColumnDef="settlement_rail">
        <th mat-header-cell *matHeaderCellDef> Settlement </th>
        <td mat-cell *matCellDef="let element"> {{element.settlement_rail}} </td>
      </ng-container>

        <!--- Define columns -->
     <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Amount </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.amount | number:'1.2-2'}} </td>
      </ng-container>


        <!--- Define columns -->
    <ng-container matColumnDef="partner_status">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Status </th>
        <td mat-cell *matCellDef="let element" class="right-align" [ngClass]="{'warning': element.partner_status === 'PENDING', 'completed': element.partner_status === 'COMPLETED'}"> {{element.partner_status}} </td>
    </ng-container>
        

         <!--- Define columns -->
     <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> CRT DT </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>


    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      
</div>