import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditOutletsService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getOutlets(page: number, pageSize: number,  username : String): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}gvc/outlets?page_number=${page}&page_size=${pageSize}&username=${username}`, { headers });
  }


  getUserTransactionSummary(userId: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}gvc/user_transaction_summary?user_id=${userId}`, { headers });
  }

  
  getUserLedger(userId: number,page: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}gvc/user_ledger?user_id=${userId}&page_number=${page}&page_size=${pageSize}`, { headers });
  }

  getUnifiedLedger(userId: number,page: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}gvc/unified_ledger?user_id=${userId}&page_number=${page}&page_size=${pageSize}`, { headers });
  }

  outletsWallet(): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}gvc/outlets_wallet`, { headers });
  }

}
