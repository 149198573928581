import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-outlet-hash-dialog',
  templateUrl: './outlet-hash-dialog.component.html',
  styleUrls: ['./outlet-hash-dialog.component.less']
})
export class OutletHashDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<OutletHashDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onTrigger(event: any): void {
    // This will be called when the child emits an event
    // 'event' will hold the data you passed in the emit() call
    console.log('trigger back')
    this.dialogRef.close(true);
  }
}