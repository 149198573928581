import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuardService } from './security/auth-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { OutletsComponent } from './pages/outlets/outlets.component';
import { RegisteredCashInComponent } from './pages/registered-cash-in/registered-cash-in.component';
import { CashInsComponent } from './pages/cash-ins/cash-ins.component';
import { BanksComponent } from './pages/banks/banks.component';
import { CashOutsComponent } from './pages/cash-outs/cash-outs.component';
import { CashOutFormComponent } from './pages/cash-out-form/cash-out-form.component';
import { OutletLedgersComponent } from './pages/outlet-ledgers/outlet-ledgers.component';
import { SimulateCashInComponent } from './pages/simulate-cash-in/simulate-cash-in.component';
import { RequestPaymentComponent } from './pages/request-payment/request-payment.component';
import { VipPaysComponent } from './pages/vip-pays/vip-pays.component';
import { environment } from 'src/environments/environment';
import { LoginGVCComponent } from './pages-govipcenter/login/login-gvc.component';
import { DashboardGvcComponent } from './pages-govipcenter/dashboard-gvc/dashboard-gvc.component';
import { AuditTransactionsComponent } from './pages-govipcenter/audit-transactions/audit-transactions.component';
import { DigiDineModulesComponent } from './pages/applications/digi-dine/digi-dine-modules/digi-dine-modules.component';
import { DigiDineCategoryComponent } from './pages/applications/digi-dine/digi-dine-category/digi-dine-category.component';
import { DigiDineProductComponent } from './pages/applications/digi-dine/digi-dine-product/digi-dine-product.component';
import { DigiDineTableComponent } from './pages/applications/digi-dine/digi-dine-table/digi-dine-table.component';
import { DigiDineMenuComponent } from './pages/applications/digi-dine/digi-dine-menu/digi-dine-menu.component';
import { DigiDineActiveOrdersComponent } from './pages/applications/digi-dine/digi-dine-active-orders/digi-dine-active-orders.component';
import { DigiDineActiveOrdersKitchenComponent } from './pages/applications/digi-dine/digi-dine-active-orders-kitchen/digi-dine-active-orders-kitchen.component';
import { DigiDineOrderHistoryComponent } from './pages/applications/digi-dine/digi-dine-order-history/digi-dine-order-history.component';
import { RequestCheckoutComponent } from './pages/request-checkout/request-checkout.component';
import { CheckoutThankyouComponent } from './pages/checkout-thankyou/checkout-thankyou.component';
import { CheckoutsComponent } from './pages/checkouts/checkouts.component';
import { RequestCheckoutCh2Component } from './pages/request-checkout-ch2/request-checkout-ch2.component';
import { CheckoutThankyouCh2Component } from './pages/checkout-thankyou-ch2/checkout-thankyou-ch2.component';
import { DirectPaymentComponent } from './pages/direct-payment/direct-payment.component';
import { DirectPaymentsAcquiredComponent } from './pages/direct-payments-acquired/direct-payments-acquired.component';

const developmentRoutes : Routes =[
  {
    path: 'home',
    component: HomeComponent ,
    data: { breadcrumb: 'Home' } // <-- You'll need to create these components
  },
  {
    path: 'outlets',
    component: OutletsComponent ,
    data: { breadcrumb: 'Outlets' }  // <-- You'll need to create these components
  },
  
  {
    path: 'simulate_cash_in',
    component: SimulateCashInComponent,
    data: { breadcrumb: 'Simulate Cash In' }   // <-- You'll need to create these components
  },
  {
    path: 'registered_cash_in',
    component: RegisteredCashInComponent,
    data: { breadcrumb: 'Registered Cash In' }   // <-- You'll need to create these components
  },
  {
    path: 'cash_ins',
    component: CashInsComponent ,
    data: { breadcrumb: 'Cash Ins' } // <-- You'll need to create these components
  },
  {
    path: 'banks',
    component: BanksComponent,
    data: { breadcrumb: 'Banks' }  // <-- You'll need to create these components
  },
  {
    path: 'cash_outs',
    component: CashOutsComponent,
    data: { breadcrumb: 'Cash Outs' }  // <-- You'll need to create these components
  },
  {
    path: 'cash_out_form',
    component: CashOutFormComponent,
    data: { breadcrumb: 'Cash Out Form' }  // <-- You'll need to create these components
  },
  {
    path: 'outlet_ledgers',
    component: OutletLedgersComponent ,
    data: { breadcrumb: 'Outlet Ledgers' } // <-- You'll need to create these components
  },
  {
    path: 'request_payment',
    component: RequestPaymentComponent ,
    data: { breadcrumb: 'Request Payment' } // <-- You'll need to create these components
  },
  {
    path: 'request_checkout',
    component: RequestCheckoutComponent ,
    data: { breadcrumb: 'Request Checkout - Channel 1' } // <-- You'll need to create these components
  },
  {
    path: 'request_checkout_ch2',
    component: RequestCheckoutCh2Component ,
    data: { breadcrumb: 'Request Checkout - Channel 2' } // <-- You'll need to create these components
  },
  {
    path: 'thankyou',
    component: CheckoutThankyouComponent ,
    data: { breadcrumb: 'Confirmation Page' } // <-- You'll need to create these components
  },
  {
    path: 'thankyou-ch2',
    component: CheckoutThankyouCh2Component ,
    data: { breadcrumb: 'Confirmation Page' } // <-- You'll need to create these components
  },
  
  {
    path: 'checkouts',
    component: CheckoutsComponent ,
    data: { breadcrumb: 'Checkout List' } // <-- You'll need to create these components
  },
  
  {
    path: 'vip_pays',
    component: VipPaysComponent ,
    data: { breadcrumb: 'Payment List' } // <-- You'll need to create these components
  },

  {
    path: 'acquire-direct-payment',
    component: DirectPaymentComponent ,
    data: { breadcrumb: 'Acquire Direct Payment' } // <-- You'll need to create these components
  },
  {
    path: 'acquired-direct-payments',
    component: DirectPaymentsAcquiredComponent ,
    data: { breadcrumb: 'Acquired Direct Payments' } // <-- You'll need to create these components
  },
  
  {
    path: 'settings',
    component: SettingsComponent,
    data: { breadcrumb: 'Settings' }
  },

  {
    path: 'digi-dine/:param1',
    component: DigiDineModulesComponent,
    data: { breadcrumb: 'DigiDine' }
  },
  {
    path: 'digi-dine/categories/:param1',
    component: DigiDineCategoryComponent,
    data: { breadcrumb: 'DigiDine > Product Categories' }
  },
  {
    path: 'digi-dine/products/:param1',
    component: DigiDineProductComponent,
    data: { breadcrumb: 'DigiDine > Products' }
  },
  {
    path: 'digi-dine/tables/:param1',
    component: DigiDineTableComponent,
    data: { breadcrumb: 'DigiDine > Tables' }
  },
  {
    path: 'digi-dine/active-orders/:param1',
    component: DigiDineActiveOrdersComponent,
    data: { breadcrumb: 'DigiDine > Active Orders - Waiters/Cashiers View' }
  },
  {
    path: 'digi-dine/active-orders-kitchen/:param1',
    component: DigiDineActiveOrdersKitchenComponent,
    data: { breadcrumb: 'DigiDine > Active Orders > Kitchen View' }
  },
  {
    path: 'digi-dine/order-history/:param1',
    component: DigiDineOrderHistoryComponent,
    data: { breadcrumb: 'DigiDine > Order History' }
  },


  
  
  // More routes as needed...
];

const productionRoutes : Routes =[
  
  {
    path: 'outlets',
    component: OutletsComponent ,
    data: { breadcrumb: 'Outlets' }  // <-- You'll need to create these components
  },
  {
    path: 'registered_cash_in',
    component: RegisteredCashInComponent,
    data: { breadcrumb: 'Registered Cash In' }   // <-- You'll need to create these components
  },
  {
    path: 'cash_ins',
    component: CashInsComponent ,
    data: { breadcrumb: 'Cash Ins' } // <-- You'll need to create these components
  },
  {
    path: 'banks',
    component: BanksComponent,
    data: { breadcrumb: 'Banks' }  // <-- You'll need to create these components
  },
  {
    path: 'cash_outs',
    component: CashOutsComponent,
    data: { breadcrumb: 'Cash Outs' }  // <-- You'll need to create these components
  },
  {
    path: 'cash_out_form',
    component: CashOutFormComponent,
    data: { breadcrumb: 'Cash Out Form' }  // <-- You'll need to create these components
  },
  {
    path: 'outlet_ledgers',
    component: OutletLedgersComponent ,
    data: { breadcrumb: 'Outlet Ledgers' } // <-- You'll need to create these components
  },
  {
    path: 'request_payment',
    component: RequestPaymentComponent ,
    data: { breadcrumb: 'Request Payment' } // <-- You'll need to create these components
  },
  {
    path: 'vip_pays',
    component: VipPaysComponent ,
    data: { breadcrumb: 'Payment List' } // <-- You'll need to create these components
  },
  
  {
    path: 'settings',
    component: SettingsComponent,
    data: { breadcrumb: 'Settings' }
  },
  {
    path: 'digi-dine/:param1',
    component: DigiDineModulesComponent,
    data: { breadcrumb: 'DigiDine' }
  },
  {
    path: 'digi-dine/categories/:param1',
    component: DigiDineCategoryComponent,
    data: { breadcrumb: 'DigiDine > Product Categories' }
  },
  {
    path: 'digi-dine/products/:param1',
    component: DigiDineProductComponent,
    data: { breadcrumb: 'DigiDine > Products' }
  },
  {
    path: 'digi-dine/tables/:param1',
    component: DigiDineTableComponent,
    data: { breadcrumb: 'DigiDine > Tables' }
  },
  {
    path: 'digi-dine/active-orders/:param1',
    component: DigiDineActiveOrdersComponent,
    data: { breadcrumb: 'DigiDine > Active Orders - Waiters/Cashiers View' }
  },
  {
    path: 'digi-dine/active-orders-kitchen/:param1',
    component: DigiDineActiveOrdersKitchenComponent,
    data: { breadcrumb: 'DigiDine > Active Orders > Kitchen View' }
  },
  {
    path: 'digi-dine/order-history/:param1',
    component: DigiDineOrderHistoryComponent,
    data: { breadcrumb: 'DigiDine > Order History' }
  },
  // More routes as needed...
];

const productionGvcRoutes : Routes =[
  
  {
    path: 'audit-transactions',
    component: AuditTransactionsComponent ,
    data: { breadcrumb: 'Audit Outlets' }  // <-- You'll need to create these components
  }
  // More routes as needed...
];

const routes: Routes =  [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],  // <-- add this
    children: !environment.production ? developmentRoutes : productionRoutes
  },
  { path: 'login-gvc', component: LoginGVCComponent },
  {
    path: 'dashboard-gvc',
    component: DashboardGvcComponent,
    canActivate: [AuthGuardService],
    children: productionGvcRoutes   // <-- add this
  },
  { path: 'resto_menu/:param1', component: DigiDineMenuComponent },
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
