
  

  <mat-card class="login-card">
    <p  *ngIf="!isProd" class="mat-body">This demo shows the wallet ledger per outlet.</p>
  
    <form [formGroup]="cashOutForm" (ngSubmit)="onSubmit()" class="login-form">

        <mat-form-field>
            <mat-select placeholder="Select Outlet" formControlName="outlet_hash_id" required>
                <mat-option *ngFor="let outlet of outlets" [value]="outlet.outlet_hash_id">[Balance: {{outlet.wallet_amount | number:'1.2-2'}}] {{outlet.outlet_hash_id}} </mat-option>
            </mat-select>
          </mat-form-field>

  
      
        <button mat-raised-button type="submit" [disabled]="cashOutForm.invalid || isLoading" class="login-button">
            
            <span *ngIf="!isLoading">Load Ledger</span>
            <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
        </button>

        
      </form>


     
  </mat-card>



  <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="ledger_id">
      <th mat-header-cell *matHeaderCellDef> Ledger ID </th>
      <td mat-cell *matCellDef="let element"> {{element.ledger_id}} </td>
    </ng-container>

    <ng-container matColumnDef="product_id">
        <th mat-header-cell *matHeaderCellDef> Product ID </th>
        <td mat-cell *matCellDef="let element"> {{element.product_id}} </td>
      </ng-container>

      <ng-container matColumnDef="transaction_type">
        <th mat-header-cell *matHeaderCellDef> Transaction Type </th>
        <td mat-cell *matCellDef="let element"> {{element.transaction_type}} </td>
      </ng-container>
      
        <!--- Define columns -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'warning': element.status === 'PENDING', 'completed': element.status === 'COMPLETED', 'failed': element.status === 'FAILED'}"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Amount </th>
            <td mat-cell *matCellDef="let element" class="right-align" [ngClass]="{'negative': element.amount < 0, 'positive': element.amount >= 0}"> {{element.amount| number:'1.2-2'}} </td>
        </ng-container>

      <ng-container matColumnDef="running_total">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Running Total </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.running_total| number:'1.2-2'}} </td>
      </ng-container>


        <!--- Define columns -->
        <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>

          <!--- Define columns -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
        <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openDialog(element.transaction_details, element.transaction_type)" class="button-spacing">Details</button> </td>
      </ng-container>
     


    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>