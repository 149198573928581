import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-dashboard-gvc',
  templateUrl: './dashboard-gvc.component.html',
  styleUrls: ['./dashboard-gvc.component.less']
})
export class DashboardGvcComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  isLargeScreen = false;
  public isProd = environment.production;

  constructor(private breakpointObserver: BreakpointObserver,private router: Router) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isLargeScreen = false;
      } else {
        this.isLargeScreen = true;
      }
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => !this.isLargeScreen)
    ).subscribe(() => this.sidenav.close());
  }


  ngOnInit(): void {
  }

}
