import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dynamic-table-dialog',
  templateUrl: './dynamic-table-dialog.component.html',
  styleUrls: ['./dynamic-table-dialog.component.less']
})
export class DynamicTableDialogComponent  {
  data!: { [key: string]: any };
keys: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any) {
    if(inputData){
      this.keys = Object.keys(inputData);
      this.data = inputData; // data is an object
    }
   
  }
}
