import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionDetailDialogComponent } from 'src/app/components/transaction-detail-dialog/transaction-detail-dialog.component';
import { OutletService } from 'src/app/services/outlet.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-outlet-ledgers',
  templateUrl: './outlet-ledgers.component.html',
  styleUrls: ['./outlet-ledgers.component.less']
})
export class OutletLedgersComponent implements OnInit {
  public isProd = environment.production;
  isLoading = false;
  outlets: { outlet_hash_id: string, wallet_amount: number, max_limit: number, consumed_amount: number, transaction_count: number, transaction_cash_in_count: number, transaction_cash_out_count: number, limit_start_datetime: string, limit_end_datetime: string }[] = [];
  

  cashOutForm = this.fb.group({
    outlet_hash_id: ['', Validators.required]
  });

  constructor(private fb: FormBuilder,
    private outletService: OutletService,public dialog: MatDialog ) { }

  ngOnInit(): void {
    this.outletService.getOutletDetails().subscribe(response => {
      this.outlets = response.response_data;
    });
  }
  
  onSubmit(): void {
    if (this.cashOutForm.valid) {
      const data = {
        ...this.cashOutForm.value,
      };

      this.fetchData(data.outlet_hash_id,this.currentPage, this.pageSize);

    }
  }


  /** Outlet ledger list **/

  displayedColumns: string[] = ['ledger_id','product_id','transaction_type','status','amount','running_total','created_date','action'];
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;
  selectedOutletHashId! : string;

 
  fetchData(outletHashId: string, page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.selectedOutletHashId = outletHashId;
    this.outletService.getLedgerData(outletHashId,page, pageSize).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
   this.fetchData(this.selectedOutletHashId,this.currentPage, this.pageSize);
  }

  openDialog(transactionDetails : any, transactionType: string): void {
    
      this.dialog.open(TransactionDetailDialogComponent, {
        width: '500px',
        data: {
          details :transactionDetails,
          type: transactionType
        } // The transaction details object you want to display
      });
    
  }

}
