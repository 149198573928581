// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl : "https://gocashflex-api-uat.govipcenter.com/api/v1/",
  thankYouUrl : "https://sandbox-gcf.govipcenter.com/dashboard/thankyou",
  thankYouUrlCh2 : "https://sandbox-gcf.govipcenter.com/dashboard/thankyou-ch2",
  errorUrl : "https://sandbox-gcf.govipcenter.com/dashboard/error",
  
  firebaseConfig :{
    apiKey: "AIzaSyCdnpoTUNqea0P7O2amn1ZdbV_zoQsXuJ0",
    authDomain: "vipcomegawallet-prod.firebaseapp.com",
    projectId: "vipcomegawallet-prod",
    storageBucket: "vipcomegawallet-prod.appspot.com",
    messagingSenderId: "69721755972",
    appId: "1:69721755972:web:b4fec8a3b62b6f78550711",
    measurementId: "G-VL63TTQVLL"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
