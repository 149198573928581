<div class="thank-you-container">
    <h1>Thank You!</h1>
    <p>{{result.response_description}}</p>
    <label>Transaction ID : {{result.response_data.transaction_id}}</label>
    <label>Merchant Reference ID : {{result.response_data.merchant_reference_id}}</label>
    <label>Status Description : {{result.response_data.status_description}}</label>
    <label>Status Code : {{result.response_data.status_code}}</label>
    <label>Date and Time : {{result.response_data.timestamp}}</label>
    <br/>
    <a routerLink="/dashboard/checkouts">Click here to go back to the list of payment checkouts</a>


  </div>

