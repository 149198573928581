



<mat-card class="inner-card">
        <mat-card-content>
            <p class="mat-body description">This demo presents a walkthrough on how to request a payment checkout data structure guide</p>
            <h3>Customer Information</h3>
              
            <form [formGroup]="checkoutForm"  class="login-form">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Option" formControlName="bank_code" required>
                    <mat-option *ngFor="let bank of banks" [value]="bank.bank_code">{{bank.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
              
                <button mat-raised-button type="submit" (click)="onSubmit()"  class="login-button">
                    <!-- [disabled]="cashOutForm.invalid || isLoading" -->
                    <span *ngIf="!isLoading">Submit</span>
                    <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
                </button>
        
                
              <br/>

            <div class="json-container">
                <code style="font-size: smaller; line-height: 0%;">
                    <span class="brace">{{'{'}}</span><br>
                    &nbsp;&nbsp;<span class="parameter">"customer"</span>: <span class="brace">{{'{'}}</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"fname"</span>: <span class="value">"Juan"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"lname"</span>: <span class="value">"dela Cruz"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"mname"</span>: <span class="value">"Mercado"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"email"</span>: <span class="value">"juandelacruz@govipcenter.com"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"mobile"</span>: <span class="value">"+639012345678"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"phone"</span>: <span class="value">"+6388888888"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"customer_id"</span>: <span class="value">"ACMGCF00000001"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"address"</span>: <span class="brace">{{'{'}}</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"line1"</span>: <span class="value">"#88"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"line2"</span>: <span class="value">"Boston Ave"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"city"</span>: <span class="value">"Quezon City"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"province"</span>: <span class="value">"Metro Manila"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"zip_code"</span>: <span class="value">"1117"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"country"</span>: <span class="value">"Philippines"</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="brace">{{'}'}}</span><br>
                    &nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                    &nbsp;&nbsp;<span class="parameter">"amount"</span>: <span class="brace">{{'{'}}</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"cur"</span>: <span class="value">"PHP"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"num"</span>: <span class="value">"15000"</span><br>
                    &nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                    &nbsp;&nbsp;<span class="parameter">"from"</span>: <span class="brace">{{'{'}}</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"bank_code"</span>: <span class="value">"{{checkoutForm.get('bank_code')!.value}}"</span>,<br>
                    &nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                    &nbsp;&nbsp;<span class="parameter">"client"</span>: <span class="brace">{{'{'}}</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"return_url"</span>: <span class="value">"{{thankYouUrl}}"</span>,<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"fail_url"</span>: <span class="value">"{{errorUrl}}"</span><br>
                    &nbsp;&nbsp;<span class="brace">{{'}'}}</span><br>
                    <span class="brace">{{'}'}}</span>
                    </code>
                    
                    
        </div>
        </mat-card-content>
    </mat-card>
    
  

 
  
  

  

  