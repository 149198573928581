


import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { PaymentInstructionsComponent } from 'src/app/components/payment-instructions/payment-instructions.component';
import { CashInService } from 'src/app/services/cash-in.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { VipCheckoutService } from 'src/app/services/vip-checkout.service';

@Component({
  selector: 'app-direct-payment',
  templateUrl: './direct-payment.component.html',
  styleUrls: ['./direct-payment.component.less']
})
export class DirectPaymentComponent implements OnInit {


  public isProd = environment.production;
  isLoading = false;
  companyCode! : string;
  requestVIPPayForm = this.fb.group({
    reference_no: ['', [Validators.required, Validators.pattern('\\d{6,20}')]],
    amount: [ '', [Validators.required, Validators.pattern(/^\d+\.\d{2}$/)]]
  });

  constructor(private fb: FormBuilder,  
    private cashInService: CashInService,
    public dialog: MatDialog,
    private vipCheckoutService: VipCheckoutService) { }

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.pageSize);
  }

  onSubmit(): void {
    if (this.requestVIPPayForm.valid) {
      const data = {
        ...this.requestVIPPayForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to continue?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.cashInService.acquireQRPHCashIn(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    
                     
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: JSON.stringify(response, null, 2), // Format the JSON object
                        header: "Transaction acquired"
                      }
                    });
                    

                      // reset the form
                      this.ngOnInit();
                      this.requestVIPPayForm.reset();
                      this.requestVIPPayForm.markAsUntouched();
                      this.requestVIPPayForm.markAsPristine();
                     
                      
                  },
                  error => {
                    this.isLoading = false;
                    debugger
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_data == null ? error.error: error.error.response_data,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
          // user clicked No
        }
      });
  }






}


//LIST

displayedColumns: string[] = ['reference_no','qrph_invoice_no','amount_num','amount_cur' ];

dataSource = new MatTableDataSource([]);
isLoadingResults = false;
pageSize: number = 5;
totalItems!: number;
currentPage: number = 1;
forSettlement!: number;
forSettlementCount!: number;

fetchData(page: number, pageSize: number): void {
  this.isLoadingResults = true;  // Spinner starts before API call
  this.vipCheckoutService.getAvailableQRPHS(page, pageSize).subscribe(response => {
    this.dataSource.data = response.response_data.items;
    this.forSettlement = response.response_data.for_settlement;
    this.forSettlementCount = response.response_data.for_settlement_count;
    this.totalItems = response.response_data.total_count;
    this.isLoadingResults = false;  // Spinner stops after API call
  }, error =>{
    this.dataSource.data = [];
    this.totalItems = 0;
    this.isLoadingResults = false;  // S

  });
}

onPageChange(event: { pageIndex: number; pageSize: number; }): void {
  this.currentPage = event.pageIndex + 1;
  this.pageSize = event.pageSize;
  this.fetchData(this.currentPage, this.pageSize);
}

//LIST

}
