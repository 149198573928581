
    
      
      <mat-card class="inner-card">
        <mat-card-content>
            <p class="mat-body description">This demo presents a walkthrough on how to request a payment checkout data structure guide</p>
          <h3>Customer Information</h3>
          
  
          <form [formGroup]="checkoutForm"  class="login-form">
            <mat-form-field class="full-width">
              <mat-select placeholder="Option" formControlName="bank_code" required>
                <mat-option *ngFor="let bank of banks" [value]="bank.code">{{bank.value}}</mat-option>
              </mat-select>
            </mat-form-field>
            
            
          </form>

          <button (click)="onSubmit()" mat-raised-button type="submit" class="login-button">
            <span *ngIf="!isLoading">Submit</span>
            <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
          </button>
  
          <br/>

          <div class="json-container">
            <code style="font-size: smaller; line-height: 0%;">
                <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;<span class="parameter">"customer"</span>: <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"first_name"</span>: <span class="value">"Abel"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"last_name"</span>: <span class="value">"Maclead"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"billing_address"</span>: <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"line1"</span>: <span class="value">"#88"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"line2"</span>: <span class="value">"Boston Ave"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"city_municipality"</span>: <span class="value">"Philadelphia"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"zip"</span>: <span class="value">"19132"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"state_province_region"</span>: <span class="value">"PA"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"country_code"</span>: <span class="value">"US"</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"shipping_address"</span>: <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"line1"</span>: <span class="value">"#88"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"line2"</span>: <span class="value">"Boston Ave"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"city_municipality"</span>: <span class="value">"Philadelphia"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"zip"</span>: <span class="value">"19132"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"state_province_region"</span>: <span class="value">"PA"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"country_code"</span>: <span class="value">"US"</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"contact"</span>: <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"email"</span>: <span class="value">"amaclead@domain.com"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"mobile"</span>: <span class="value">"+639175000011"</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="brace">{{'}'}}</span><br>
                &nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                &nbsp;&nbsp;<span class="parameter">"payment"</span>: <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"description"</span>: <span class="value">"Payment for a product"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"amount"</span>: <span class="value">10.5</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"currency"</span>: <span class="value">"PHP"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"option"</span>: <span class="value">"{{checkoutForm.get('bank_code')!.value}}"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"other_references"</span>: <span class="value">[</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="value">"REF1"</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="value">"REF2"</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="value">]</span><br>
                &nbsp;&nbsp;<span class="brace">{{'}'}}</span>,<br>
                &nbsp;&nbsp;<span class="parameter">"route"</span>: <span class="brace">{{'{'}}</span><br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"callback_url"</span>: <span class="value">{{thankYouUrl}}</span>,<br>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="parameter">"notify_user"</span>: <span class="value">true</span><br>
                &nbsp;&nbsp;<span class="brace"></span>{{'}'}},<br>
                &nbsp;&nbsp;<span class="parameter">"time_offset"</span>: <span class="value">"+08:00"</span><br>
                <span class="brace">{{'}'}}</span>
                </code>
                
           
          </div>
        </mat-card-content>
      </mat-card>
  


