import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { CashOutService } from 'src/app/services/cash-out.service';
import { OutletService } from 'src/app/services/outlet.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cash-out-form',
  templateUrl: './cash-out-form.component.html',
  styleUrls: ['./cash-out-form.component.less']
})
export class CashOutFormComponent implements OnInit {
  public isProd = environment.production;
  isLoading = false;
  banks: { name: string, code: string, amountFrom: number, amountTo: number }[] = [];
  outlets: { outlet_hash_id: string, wallet_amount: number, max_limit: number, consumed_amount: number, transaction_count: number, transaction_cash_in_count: number, transaction_cash_out_count: number, limit_start_datetime: string, limit_end_datetime: string }[] = [];
  cashOutForm = this.fb.group({
    account_name: ['', Validators.required], //Juan dela Cruz
    account_number: ['', Validators.required], //900220000001
    amount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]], //100.00
    biller_code: ['', Validators.required], //CUOBPHM2XXX
    recipient_mobile_number: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]], //09998483938
    outlet_hash_id: ['', Validators.required]
  });

  

  constructor(private fb: FormBuilder,  
    private cashOutService: CashOutService,
    private outletService: OutletService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.cashOutService.getBankDetails().subscribe(response => {
      this.banks = response.response_data;
    });

    this.outletService.getOutletDetails().subscribe(response => {
      this.outlets = response.response_data;
    });
  }

  onSubmit(): void {
    if (this.cashOutForm.valid) {
      const data = {
        partner_reference_id: this.generatePartnerReferenceId(),
        ...this.cashOutForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to proceed your transaction?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.cashOutService.transactCashOut(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    if(response.response_result === "0001") {
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "Your cash out has been completed successfully!",
                          header: "Transaction successful"
                        }
                      });
                      // reset the form
                      this.ngOnInit();
                      this.cashOutForm.reset();
                      this.cashOutForm.markAsUntouched();
                      this.cashOutForm.markAsPristine();
                      


                    } else if(response.response_result === "0002") {
                      this.isLoading = false;
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: response.response_description,
                          header: "Transaction error"
                        }
                      });
                      // handle failure case
                    }
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_description,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
          // user clicked No
        }
      });

      // this.confirmDialogService.confirm('Are you sure you want to perform this cash out?').subscribe(confirmed => {
      //   if (confirmed) {
      //     this.cashOutService.transactCashOut(data).subscribe(
      //       response => {
      //         if(response.response_result === "0000") {
      //           console.log("Transaction successful");
      //           // handle success case
      //         } else if(response.response_result === "0002") {
      //           console.log("Transaction failed: ", response.response_description);
      //           // handle failure case
      //         }
      //       },
      //       error => console.error(error)
      //     );
      //   }
      // });
      // this.cashOutService.transactCashOut(data).subscribe(
      //   response => console.log(response),
      //   error => console.error(error)
      // );
    //}
  }
}

  generatePartnerReferenceId(): string {
    const date = new Date();
    const dateString = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0');
    const randomString = Math.random().toString(36).toUpperCase().substring(2, 10);
    return dateString + randomString;
  }
}
