import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicTableDialogComponent } from 'src/app/components/dynamic-table-dialog/dynamic-table-dialog.component';
import { AuditOutletsService } from 'src/app/services/audit-outlets.service';
import { CashInService } from 'src/app/services/cash-in.service';

@Component({
  selector: 'app-user-transaction-ledger',
  templateUrl: './user-transaction-ledger.component.html',
  styleUrls: ['./user-transaction-ledger.component.less']
})
export class UserTransactionLedgerComponent implements OnInit {
  @Input() userId: any;
  displayedColumns: string[] = ['transactionType','referenceNumber','transactionName','requestId','statusId','createdDate','completedDate','openingBalance', 'amount', 'runningTotal', 'discrepancy']; //, 'action'

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
   
  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 100;
  totalItems!: number;
  currentPage: number = 1;
  username! : String;
  actualBalance! : any;
  walletAdjustment! : any;
  totalWalletLedger! : any;
  maxPage: any;

  constructor(private auditOutletsService: AuditOutletsService,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.pageSize);
  }
  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.auditOutletsService.getUserLedger(this.userId,page, pageSize).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.username = response.response_data.username;
      this.actualBalance = response.response_data.wallet;
      this.walletAdjustment = response.response_data.wallet_adjustment;
      this.totalWalletLedger = response.response_data.ending_ledger;
      this.isLoadingResults = false;  // Spinner stops after API call
      this.maxPage = Math.ceil(this.totalItems/ this.pageSize);
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }


  goToPage() {
    if (this.currentPage >= 1 && this.currentPage <= this.maxPage) {
      this.fetchData(this.currentPage, this.pageSize);
      this.paginator.pageIndex = this.currentPage - 1; 
    }else{
     
      alert("The maximum page is " + this.maxPage + ". The list will fetch the last page.");
      this.currentPage=this.maxPage;
      this.fetchData(this.currentPage, this.pageSize);
      this.paginator.pageIndex = this.currentPage - 1; 
    }
  }
  
  computeDiscrepany(openingBalance : any,runningTotal : any, amount: any){

    return (openingBalance - (runningTotal + (amount * -1)))
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }

 

  openDialog(details : string){
    var jsonData = JSON.parse(details);
    this.openDynamicTableDialog(jsonData);
  }

  openDynamicTableDialog(data: any) {
    this.dialog.open(DynamicTableDialogComponent, {
      data,
      width: '100%',
    });
  }
}
