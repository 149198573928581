import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { DigiDineService } from 'src/app/services/digi-dine.service';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.less']
})
export class AddCategoryDialogComponent implements OnInit {

  isLoading = false;
  categoryForm = this.fb.group({
    name: ['', Validators.required], 
  });
  outletHashId : any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService) { 
    this.outletHashId= data;
  }


  
  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.categoryForm.valid) {
      const data = {
        outlet_hash_id: this.outletHashId,
        ...this.categoryForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to add new category?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.digiDineService.addCategory(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    if(response.response_result === "0001") {
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "Category successfully added!",
                          header: "Transaction successful"
                        }
                      });
                      // reset the form
                      // this.ngOnInit();
                      // this.cashOutForm.reset();
                      // this.cashOutForm.markAsUntouched();
                      // this.cashOutForm.markAsPristine();
                      this.dialog.closeAll();
                    } else if(response.response_result === "0002") {
                      this.isLoading = false;
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: response.response_description,
                          header: "Transaction error"
                        }
                      });
                      // handle failure case
                    }
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_description,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
        }
      });

     
  }
}

}
