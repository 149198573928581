
  
  
    <div class="mat-dialog-content">
        <h3>Bill Out</h3>
        <strong style="color: green;" *ngIf="tableData.payment_status == 'PAID'">PAID USING GOCASHFLEX</strong><br/>
        <strong>Total Amount : {{totalAmount| number:'1.2-2'}}</strong><br/>
        <strong>Discount Amount : {{discountAmount| number:'1.2-2'}}</strong><br/>
        <strong>Amount to be Paid : {{totalAmount-discountAmount| number:'1.2-2'}}</strong><br/>


        
        <strong *ngIf="tableData.payment_status == 'PAID' && tableData.payment_type == 'GOCASHFLEX'">Actual Digital Payment Amount : {{tableData.actual_payment_amount| number:'1.2-2'}}</strong><br/>
        <strong style="color:red; font-size: larger;" *ngIf="tableData.payment_status == 'PAID' && tableData.payment_type == 'GOCASHFLEX'">Discrepany : {{( (totalAmount-discountAmount)-tableData.actual_payment_amount)  | number:'1.2-2'}}</strong>
        <mat-card class="login-card">

            
         
            <form [formGroup]="billOutForm" (ngSubmit)="onSubmit()" class="login-form">

            <div *ngIf="tableData.payment_status != 'PAID'">
                <mat-form-field>
                    <mat-select placeholder="Payment Mode" formControlName="payment_mode" required (selectionChange)="onPaymentModeChange($event)">
                      <mat-option *ngFor="let paymentMode of paymentModeList" [value]="paymentMode.id">{{paymentMode.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
              <mat-form-field>
                <input matInput placeholder="Reference Number" formControlName="reference_number" required>
              </mat-form-field>
            </div>

            <div *ngIf="tableData.payment_status == 'PAID'">
                 <mat-form-field>
                    <input matInput placeholder="Payment Mode" formControlName="payment_mode" required readonly>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="Reference Number" formControlName="reference_number" required readonly>
                  </mat-form-field>
                </div>
             
            
              <button mat-raised-button type="submit" [disabled]="billOutForm.invalid || isLoading" class="login-button">
                  
                  <span *ngIf="!isLoading">Submit</span>
                  <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
              </button>
      
              
            </form>
        </mat-card>

    </div>
    
  
    