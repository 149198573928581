import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCategoryDialogComponent } from '../../components/add-category-dialog/add-category-dialog.component';
import { EditCategoryDialogComponent } from '../../components/edit-category-dialog/edit-category-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';


@Component({
  selector: 'app-digi-dine-table',
  templateUrl: './digi-dine-table.component.html',
  styleUrls: ['./digi-dine-table.component.less']
})
export class DigiDineTableComponent implements OnInit {

  private paramSubscription: Subscription | undefined;
  constructor(private route: ActivatedRoute,private digiDineService : DigiDineService,
    public dialog: MatDialog) { }
  outletHashId : string ="";

  
  displayedColumns: string[] = ['table_number', 'is_active', 'created_date', 'updated_date','action' ]; //'id', 
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 100;
  totalItems!: number;
  currentPage: number = 1;

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.outletHashId = params['param1']; 
      this.fetchData(this.currentPage, this.pageSize);
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }


  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.digiDineService.getTables(page, pageSize,this.outletHashId).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }


  isLoading = false;
  addTable(): void {
      const data = {
        outlet_hash_id: this.outletHashId
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to add new table?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.digiDineService.addTable(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    if(response.response_result === "0001") {
      this.fetchData(this.currentPage, this.pageSize);
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "Table successfully added!",
                          header: "Transaction successful"
                        }
                      });
                      
                    } else if(response.response_result === "0002") {
                      this.isLoading = false;
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: response.response_description,
                          header: "Transaction error"
                        }
                      });
                      // handle failure case
                    }
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_description,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
        }
      });

     
}
deleteTable(): void {
  const data = {
    outlet_hash_id: this.outletHashId
  };

  const dialogRef = this.dialog.open(ConfirmationDialogComponent);

  dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete last table?"

  dialogRef.afterClosed().subscribe(result => {
    if(result) { 
      this.isLoading = true;
      // user clicked Yes, make the API call
      this.digiDineService.deleteTable(data).subscribe(
              response => {
                // handle success case
                this.isLoading = false;
                if(response.response_result === "0001") {
                  this.fetchData(this.currentPage, this.pageSize);
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      content: "Table successfully deleted!",
                      header: "Transaction successful"
                    }
                  });
                  
                } else if(response.response_result === "0002") {
                  this.isLoading = false;
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      content: response.response_description,
                      header: "Transaction error"
                    }
                  });
                  // handle failure case
                }
              },
              error => {
                this.isLoading = false;
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    content: error.error.response_description,
                    header: "Transaction error"
                  }
                });
              }
            );
    } else {
    }
  });

 
}

printBase64Image(base64Image: string, table_number : number) {
  const printWindow = window.open();
  printWindow!.document.write(`<html><head><title>Print - Table Number ${table_number}</title></head><body>`);
  printWindow!.document.write('<img id="printImage" src="data:image/jpeg;base64,' + base64Image + '"/>');
  printWindow!.document.write('<script>var img = document.getElementById("printImage"); img.onload = function() { window.print(); }</script>');
  printWindow!.document.write('</body></html>');
  printWindow!.document.close();
}


  
}
