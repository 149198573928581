import { Component, OnInit } from '@angular/core';
import { CollectionReference, DocumentReference, Firestore, collection, doc, getDocs, orderBy, query, where } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';

@Component({
  selector: 'app-digi-dine-modules',
  templateUrl: './digi-dine-modules.component.html',
  styleUrls: ['./digi-dine-modules.component.less']
})
export class DigiDineModulesComponent implements OnInit {
  private paramSubscription: Subscription | undefined;
  constructor(private route: ActivatedRoute,private router: Router,private digiDineService : DigiDineService,private firestore: Firestore) { }
  outletHashId : string ="";
  dataCount: any;
  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.outletHashId = params['param1']; 
    });

    this.digiDineService.getDataCount(this.outletHashId).subscribe(
      response => {
        this.dataCount = response.response_data
      });
      this.orderCount();
  }

  orderCountData : any = 0;
  orderItemCountData : any = 0;
  async orderCount(){
    try {
      var docTableExist: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}`);
      const ordersQuery = query(
        collection(docTableExist, 'ActiveOrders'),
        where('order_status', '==', 'ACTIVE')
      );
      const querySnapshot = await getDocs(ordersQuery);
      this.orderCountData=querySnapshot.docs.length;

      querySnapshot.forEach(async docData=>{
        var docTableOrders: CollectionReference = collection(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${docData.id}/Orders`);
        const q = query(docTableOrders,where("status","!=","SERVED"),orderBy("status") , orderBy("created_date", "asc"),orderBy("name", "asc")); 
        const querySnapshotOrders = await getDocs(q);
        this.orderItemCountData = this.orderItemCountData+ querySnapshotOrders.docs.length
      });
    } catch (error) {
      
    }
     
  }
 
  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  navigateToCategories(){
    this.router.navigate(['/dashboard/digi-dine/categories', this.outletHashId]);
  }

  navigateToProducts(){
    this.router.navigate(['/dashboard/digi-dine/products', this.outletHashId]);
  }

  navigateToTables(){
    this.router.navigate(['/dashboard/digi-dine/tables', this.outletHashId]);
  }

  navigateToActiveOrders(){
    this.router.navigate(['/dashboard/digi-dine/active-orders', this.outletHashId]);
  }

  navigateToActiveOrdersKitchen(){
    this.router.navigate(['/dashboard/digi-dine/active-orders-kitchen', this.outletHashId]);
  }

  navigateToActiveOrderHistory(){
    this.router.navigate(['/dashboard/digi-dine/order-history', this.outletHashId]);
  }
}