import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VipCheckoutService } from 'src/app/services/vip-checkout.service';

@Component({
  selector: 'app-checkout-thankyou',
  templateUrl: './checkout-thankyou.component.html',
  styleUrls: ['./checkout-thankyou.component.less']
})
export class CheckoutThankyouComponent implements OnInit {

  transactionId: string | null = null;
  result : any;
  constructor(private activatedRoute: ActivatedRoute,
    private vipCheckoutService: VipCheckoutService,) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.transactionId = params['transaction_id'];
      console.log('Transaction ID:', this.transactionId);
      this.vipCheckoutService.syncVIPCheckout(this.transactionId).subscribe(
        response => {
          this.result = response;
          console.log(this.result);
        });
    });
  }

}
