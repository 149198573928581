import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { CashInService } from 'src/app/services/cash-in.service';
import { CashOutService } from 'src/app/services/cash-out.service';
import { OutletService } from 'src/app/services/outlet.service';

@Component({
  selector: 'app-simulate-cash-in',
  templateUrl: './simulate-cash-in.component.html',
  styleUrls: ['./simulate-cash-in.component.less']
})
export class SimulateCashInComponent implements OnInit {
  isLoading = false;
  banks: { name: string, code: string, amountFrom: number, amountTo: number }[] = [];
  outlets: { outlet_hash_id: string, wallet_amount: number, max_limit: number, consumed_amount: number, transaction_count: number, transaction_cash_in_count: number, transaction_cash_out_count: number, limit_start_datetime: string, limit_end_datetime: string }[] = [];
  cashInForm = this.fb.group({
    transaction_id: [this.generateTransactionId(), [Validators.required, Validators.pattern(/^\d{8}$/)]],
    account_number: ['', Validators.required],
    amount: [this.generateAmount(), [Validators.required, Validators.pattern(/^\d+(\.\d{1})?$/)]]
  });

  companyCode! : string;

  constructor(private fb: FormBuilder,  
    private cashInService: CashInService,
    public dialog: MatDialog) { 
    }

  ngOnInit(): void {
    
    this.cashInService.getCompanyCode().subscribe(
      response => {
        this.companyCode = response.response_data;
        this.cashInForm.patchValue({
          account_number: this.generateAccountNumber()
        });
      });
  }

  generateTransactionId(): number {
    return Math.floor(10000000 + Math.random() * 90000000); // This generates an 8 digit number.
  }

  generateAccountNumber(): string {
    return `${this.companyCode}${Math.floor(100 + Math.random() * 900)}` ; // This generates an 8 digit number.
  }

  generateAmount(): number {
    return Math.floor(10000 + Math.random() * 90000) ; // This generates an 8 digit number.
  }

  onSubmit(): void {
    if (this.cashInForm.valid) {
      const data = {
        ...this.cashInForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to simulate the cash in?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.cashInService.transactCashIn(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "The cash that was added has been registered as a cash influx.",
                          header: "Transaction successful"
                        }
                      });
                      // reset the form
                      this.ngOnInit();
                      this.cashInForm.reset();
                      this.cashInForm.markAsUntouched();
                      this.cashInForm.markAsPristine();

                      this.cashInForm.patchValue({
                        transaction_id: this.generateTransactionId()
                      });
                      this.cashInForm.patchValue({
                        amount: this.generateAmount()
                      });
                      
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: "Please ensure that your account number is 10 digits long and begins with the company code provided to you.",
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
          // user clicked No
        }
      });

      // this.confirmDialogService.confirm('Are you sure you want to perform this cash out?').subscribe(confirmed => {
      //   if (confirmed) {
      //     this.cashOutService.transactCashOut(data).subscribe(
      //       response => {
      //         if(response.response_result === "0000") {
      //           console.log("Transaction successful");
      //           // handle success case
      //         } else if(response.response_result === "0002") {
      //           console.log("Transaction failed: ", response.response_description);
      //           // handle failure case
      //         }
      //       },
      //       error => console.error(error)
      //     );
      //   }
      // });
      // this.cashOutService.transactCashOut(data).subscribe(
      //   response => console.log(response),
      //   error => console.error(error)
      // );
    //}
  }
}

  generatePartnerReferenceId(): string {
    const date = new Date();
    const dateString = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0');
    const randomString = Math.random().toString(36).toUpperCase().substring(2, 10);
    return dateString + randomString;
  }
}
