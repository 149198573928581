import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, public dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                header: 'Unauthorized',
                content: 'You are not authorized to perform this action. Please check your credentials or contact your system administrator if you believe this is in error.'
              }
            });
            this.router.navigate(['/login']);
          }

          return throwError(error);
        })
      );
  }
}
