
  

  <mat-card class="login-card">
    <p class="mat-body">This demo presents a walkthrough on how to deposit cash into a different bank using InstaPay.</p>
  
<p class="mat-body">Note: In our financial transactions, please note that we count the last two digits after a number as its decimal values. For example, if you're entering 10000, it's equivalent to 100.00 in our system. Essentially, think of the last two digits as cents in a peso. So, be sure to include the two extra digits for the decimal values when you're inputting an amount for a transaction</p>

    
    <form [formGroup]="cashInForm" (ngSubmit)="onSubmit()" class="login-form">
      <mat-form-field>
        <input matInput placeholder="Transaction ID (Auto-Generated)" formControlName="transaction_id" required readonly>
    </mat-form-field>
    
      
        <mat-form-field>
          <input matInput placeholder="Account Number (Auto-Generated)" formControlName="account_number" required readonly>
        </mat-form-field>
      
        <mat-form-field>
          <input matInput placeholder="Amount (Auto-Generated)" formControlName="amount" required readonly>
        </mat-form-field>
  
      
        <button mat-raised-button type="submit" [disabled]="cashInForm.invalid || isLoading" class="login-button" >
            
            <span *ngIf="!isLoading">Submit</span>
            <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
        </button>

        
      </form>
  </mat-card>
  