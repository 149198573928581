import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentInstructionsComponent } from 'src/app/components/payment-instructions/payment-instructions.component';
import { TransactionDetailDialogComponent } from 'src/app/components/transaction-detail-dialog/transaction-detail-dialog.component';
import { CashOutService } from 'src/app/services/cash-out.service';
import { VipCheckoutService } from 'src/app/services/vip-checkout.service';
import { VipPayService } from 'src/app/services/vip-pay.service';

@Component({
  selector: 'app-checkouts',
  templateUrl: './checkouts.component.html',
  styleUrls: ['./checkouts.component.less']
})
export class CheckoutsComponent implements OnInit {

  displayedColumns: string[] = ['partner_reference_no', 'transaction_id', 'amount_currency', 'requested_amount','status','transaction_state', 'requested_date', 'action'];
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;
  forSettlement!: number;
  forSettlementCount!: number;

  constructor(private vipCheckoutService: VipCheckoutService,public dialog: MatDialog
    ) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.pageSize);
  }
  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.vipCheckoutService.getVIPCheckouts(page, pageSize).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.forSettlement = response.response_data.for_settlement;
      this.forSettlementCount = response.response_data.for_settlement_count;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    }, error =>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false;  // S

    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }

  openDialog(cashInDetails:any){
    this.dialog.open(TransactionDetailDialogComponent, {
      width: '500px',
      data: {
        details :cashInDetails,
        type: "CASH_IN"
      } // The transaction details object you want to display
    });
  }


syncStatus(element : any){

  if (element.partner_reference_no.startsWith('GCO')) {
    this.vipCheckoutService.syncVIPCheckout(element.transaction_id).subscribe(
      response => {
        this.fetchData(this.currentPage, this.pageSize);
      });
  } 
  else  if (element.partner_reference_no.startsWith('GBO')) {
    this.vipCheckoutService.syncVIPCheckoutV2(element.transaction_id).subscribe(
      response => {
        this.fetchData(this.currentPage, this.pageSize);
      });
  }
  else {
      console.log('The reference ID does not start with GCO or GBO.');
  }

 
}

  openDialogPaymentInstructions(cashInData : any){
    this.dialog.open(PaymentInstructionsComponent, {
      data:{ "response_data" : {
        "account_number": cashInData.generated_account_number,
        "amount_currency": cashInData.amount_currency,
        "amount": cashInData.requested_amount,
        "partner_reference_id": cashInData.partner_reference_no,
        "created_date": cashInData.requested_date,
        "qrCodeBase64" : cashInData.qr_code_base64
       }} 
    });
  }

}
