
  
  
    <h3>Add Category</h3>

  <mat-card class="login-card">
    <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()" class="login-form">
        <mat-form-field>
          <input matInput placeholder="Name" formControlName="name" required>
        </mat-form-field>
  
      
        <button mat-raised-button type="submit" [disabled]="categoryForm.invalid || isLoading" class="login-button">
            
            <span *ngIf="!isLoading">Submit</span>
            <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
        </button>

        
      </form>
  </mat-card>
  