import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigiDineService {

  private baseUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }


  //** CATEGORY */

  getCategoriesList( outletHashId:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId
    }

    return this.http.post(`${this.baseUrl}digidine/categories_list`, data, { headers });
  }

  getCategories( pageNumber: number, pageSize: number,outletHashId:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId,
      'page_number' : pageNumber,
      'page_size' : pageSize
    }

    return this.http.post(`${this.baseUrl}digidine/categories`, data, { headers });
  }

  addCategory(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}digidine/add_category`, data, { headers });
  }

  updateCategory(data: any, id:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.put<any>(`${this.baseUrl}digidine/update_category/${id}`, data, { headers });
  }
  
  //** CATEGORY */

  //** PRODUCT */

  getProducts( pageNumber: number, pageSize: number,outletHashId:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId,
      'page_number' : pageNumber,
      'page_size' : pageSize
    }

    return this.http.post(`${this.baseUrl}digidine/products`, data, { headers });
  }

  addProduct(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}digidine/add_product`, data, { headers });
  }

  updateProduct(data: any, id:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.put<any>(`${this.baseUrl}digidine/update_product/${id}`, data, { headers });
  }
  

  //** PRODUCT */

  
  //** TABLE */

  getTables( pageNumber: number, pageSize: number,outletHashId:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId,
      'page_number' : pageNumber,
      'page_size' : pageSize
    }

    return this.http.post(`${this.baseUrl}digidine/tables`, data, { headers });
  }

  addTable(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}digidine/add_table`, data, { headers });
  }

  deleteTable(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}digidine/delete_table`, data, { headers });
  }

  //** TABLE */

  //** DATA COUNT */


  getDataCount( outletHashId:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId
    }

    return this.http.post(`${this.baseUrl}digidine/data_count`, data, { headers });
  }

  
  //** DATA COUNT */


  //** ORDER HISTORY */

  addOrderHistory(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}digidine/add_order_history`, data, { headers });
  }


  getOrderHistory( pageNumber: number, pageSize: number,outletHashId:any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId,
      'page_number' : pageNumber,
      'page_size' : pageSize
    }

    return this.http.post(`${this.baseUrl}digidine/order_histories`, data, { headers });
  }
  

  //** ORDER HISTORY */

  //** PUBLIC RESTO */

  getPublicRestoMenuList( outletHashId:any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    var data = {
      'outlet_hash_id' : outletHashId
    }

    return this.http.post(`${this.baseUrl}digidine/public_products`, data, { headers });
  }

  updateGenerateVIPPay(outletHashId: any, tableNumberId : any): Observable<any> {
    const headers = { 
      'Content-Type': 'application/json'
    };

    var data = {
      'outlet_hash_id' : outletHashId,
      'table_number_id' : tableNumberId
    }
    return this.http.post<any>(`${this.baseUrl}digidine/public_update_vip_pay`, data, { headers });
  }

  requestVIPPay(outletHashId: any, tableNumberId : any): Observable<any> {
    const headers = { 
      'Content-Type': 'application/json'
    };
    var data = {
      'outlet_hash_id' : outletHashId,
      'table_number_id' : tableNumberId
    }
    return this.http.post<any>(`${this.baseUrl}digidine/public_generate_vip_pay`, data, { headers });
  }


  //** PUBLIC RESTO */
}
