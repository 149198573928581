import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CashOutService {

  
  private baseUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  getBankDetails(): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');
      
    return this.http.get(`${this.baseUrl}instapay_banks`, { headers: headers });
  }

  getCashOuts(pageNumber: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}register_cash_outs?page_number=${pageNumber}&page_size=${pageSize}`;

    return this.http.get(url, { headers: headers });
  }

  transactCashOut(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}cash_out`, data, { headers });
  }

  
}
