<div class="cards-container">
    
      <img class="product-image cover-image" mat-card-image [src]="product.image" alt="Product image">
       <mat-card-content>
            <mat-card-title>{{product.name}}</mat-card-title>
            <mat-card-subtitle>{{product.description }}</mat-card-subtitle>
          </mat-card-content>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 10px; ">
            <button mat-raised-button color="secondary" (click)="decreaseQuantity()">
                <strong class="quantity-label">-</strong> 
            </button>
            <strong class="quantity-label">{{ quantity }}</strong> 
            <button mat-raised-button color="primary" (click)="increaseQuantity()">
                <strong class="quantity-label">+</strong> 
            </button>
        </div>
        
        
          <mat-card-actions class="fixed-button action-container">
            <mat-card-content>
              <mat-card-subtitle class="subtitle-amount">₱ {{(product.price*quantity) | number:'1.2-2' }}</mat-card-subtitle>
            </mat-card-content>
           
            
           
          

            
            <button mat-raised-button color="warn" (click)="addOrder()">
              <span *ngIf="!isLoading">Order Now</span>
              <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
            </button>
            
          </mat-card-actions>
          
  </div>

  <!-- (click)="openAddOrderDialog(product)" -->