import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OutletService } from 'src/app/services/outlet.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CashInService } from 'src/app/services/cash-in.service';



@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.less']
})
export class OutletsComponent implements OnInit {
  @Input() registeredCashIn: any;
  @ViewChild('hashCell') hashCell: any;
  outletDetails = [];
  displayedColumns: string[] =  [];  // Add all your columns here

  constructor(private outletService: OutletService,
    private cashInService: CashInService,
    private clipboard: Clipboard, private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.diplayedColumnsOrchestrate();
    this.outletService.getOutletDetails().subscribe(
      response => {
        this.outletDetails = response.response_data;
      },
      error => {
        console.error(error);
      }
    );
  }

  diplayedColumnsOrchestrate(){
    this.displayedColumns =  this.registeredCashIn != null ? ['outlet_hash_id', 'wallet_amount', 'max_limit', 'consumed_amount', 'transaction_count', 'transaction_cash_in_count'
  , 'transaction_cash_out_count', 'allocate'] : ['outlet_hash_id', 'wallet_amount', 'max_limit', 'consumed_amount', 'transaction_count', 'transaction_cash_in_count'
  , 'transaction_cash_out_count', 'limit_start_datetime', 'limit_end_datetime']; 
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this.snackBar.open('Copied to clipboard', 'Dismiss', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }



  /* Allocate registered cash in */
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();

  openConfirmationDialog(registeredCashIn : any, outlet_hash_id : string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to allocate your funds to the outlet?"
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        // user clicked Yes, make the API call
        this.cashInService.creditCashInToOutlet(outlet_hash_id, registeredCashIn.transaction_id).subscribe(
          response => {
            console.log('API call successful, response: ', response);
            this.triggerEvent.emit(/* pass the data you want here */);
          },
          error => {
            console.error('API call failed, error: ', error);
          }
        );
      } else {
        // user clicked No
      }
    });
  }

  /* Allocate registered cash in */


}
