import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { DigiDineService } from 'src/app/services/digi-dine.service';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.less']
})
export class EditProductDialogComponent implements OnInit {

  isLoading = false;
  productForm = this.fb.group({
    name: ['', Validators.required], 
    image: [''], 
    image_base_64: [''], 
    price: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]], //100.00
    description: ['', Validators.required], 
    category_id: ['', Validators.required], 
    is_active: ['', Validators.required], 
    show_in_kitchen: ['', Validators.required], 
  });
  outletHashId : any;
  id : any;

  constructor(@Inject(MAT_DIALOG_DATA) public data  : { outlet_hash_id: any, model: any },private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService) { 
    this.outletHashId= data;
    this.outletHashId= data.outlet_hash_id;
    this.id = data.model.id;
    this.convertImageToBase64(data.model.image)
    .then(base64 => {
      this.croppedImage =base64;
      this.productForm.patchValue({
        name: data.model.name,
        price : data.model.price.toFixed(2),
        description : data.model.description,
        category_id : data.model.category_id,
        is_active: data.model.is_active,
        show_in_kitchen: data.model.show_in_kitchen
      });

    })
    .catch(error => console.error(error));


    
  }
  

  
  categoryList: { id: number, name: string }[] = [];
  
  ngOnInit(): void {
    this.digiDineService.getCategoriesList(this.outletHashId).subscribe(
      response => {
        this.categoryList = response.response_data
      });
  }

  onSubmit(): void {
    if (this.productForm.valid) {
      const data = {
        outlet_hash_id: this.outletHashId,
        ...this.productForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to update product?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.digiDineService.updateProduct(data,this.id).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    if(response.response_result === "0001") {
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "Product successfully udpated!",
                          header: "Transaction successful"
                        }
                      });
                      // reset the form
                      // this.ngOnInit();
                      // this.cashOutForm.reset();
                      // this.cashOutForm.markAsUntouched();
                      // this.cashOutForm.markAsPristine();
                      this.dialog.closeAll();
                    } else if(response.response_result === "0002") {
                      this.isLoading = false;
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: response.response_description,
                          header: "Transaction error"
                        }
                      });
                      // handle failure case
                    }
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_description,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
        }
      });

     
  }
}

imageChangedEvent: any = '';
croppedImage: any = '';

onFileSelected(event: Event) {
  this.imageChangedEvent = event;
}

imageCropped(event: ImageCroppedEvent) {
  const reader = new FileReader();
  reader.onloadend = () => {
    this.croppedImage = reader.result;
    this.productForm.patchValue({
      image_base_64: this.croppedImage
    });
  };
  reader.readAsDataURL(event.blob!);
  
}


imageLoaded() {
  // show cropper
}

cropperReady() {
  // cropper ready
}

loadImageFailed() {
  // show message
}

async convertImageToBase64(imageUrl: string): Promise<string> {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
}


}
