import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OutletHashDialogComponent } from 'src/app/components/outlet-hash-dialog/outlet-hash-dialog.component';
import { CashInService } from 'src/app/services/cash-in.service';

@Component({
  selector: 'app-registered-cash-in',
  templateUrl: './registered-cash-in.component.html',
  styleUrls: ['./registered-cash-in.component.less']
})
export class RegisteredCashInComponent implements OnInit {
  displayedColumns: string[] = ['transaction_id', 'source_account_number', 'source_bank_code', 
    'destination_account_number', 'amount_currency', 'settlement_rail', 'amount', 'partner_status',
    'created_date','action'];

   
  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;

  constructor(private cashInService: CashInService,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.pageSize);
  }
  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.cashInService.getRegisteredPendingsCashIns(page, pageSize).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    }, error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }
  

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }

  openDialog(registeredCashIn : any): void {
    const dialogRef = this.dialog.open(OutletHashDialogComponent,{
      data : registeredCashIn,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      // Here, you can use 'result' variable which holds the data passed back from the dialog
     if(result) this.fetchData(this.currentPage, this.pageSize);
    });
  }
}
