<h1 mat-dialog-title>Transaction Details</h1>
<div mat-dialog-content >
    <div  class="details"  >
        <div class="row" *ngIf="keys.length == 0">
            <span class="label">No Data Available</span>
            <span class="value">No Data Available</span>
          </div>
        <div class="row" *ngFor="let key of keys">
          <span class="label">{{key}}</span>
          <span class="value">{{data[key]}}</span>
        </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>