import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  template: `
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <h2 style="padding: 0px 20px;"><br/>{{ breadcrumb }}</h2>
      <span *ngIf="!last"> / </span>
    </ng-container>
  `,
  styles: []
})
export class BreadCrumbComponent {
  breadcrumbs: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: string[] = []): string[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    // get the child routes
    let children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (let child of children) {
      // verify this is the primary route
      if (child.outlet !== 'primary') {
        continue;
      }

      // verify the custom data property 'breadcrumb' is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      let breadcrumb: string = child.snapshot.data[ROUTE_DATA_BREADCRUMB];

      // recursive
      return this.getBreadcrumbs(child, url, [...breadcrumbs, breadcrumb]);
    }

    // we should never get here, but just in case
    return breadcrumbs;
  }
}
