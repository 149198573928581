<div class="container">
    <mat-card class="card">
        
       <mat-card-title class="card-title"> 
            Payment Instructions 
            <img width="80" src="https://govipcenterstorage.blob.core.windows.net/gocashflex/go_vippay.png" alt="logo" class="logo">
          </mat-card-title>
          <p>Dear customer, please follow the below instructions to transfer the payment:</p>
        

          <div class="qrcode-container">
            <p>You're able to proceed with the payment through the Vips wallet. <br/>Just go through the following steps: firstly, select the 'Send' option, then proceed to scan the QR Code.
  
            </p>
            <div style="text-align:center" class="bshadow">
              <!-- <qrcode [qrdata]="qrValue(data.response_data.account_number,data.response_data.amount)" [width]="256" [errorCorrectionLevel]="'M'" cssClass="bshadow"></qrcode> -->
            
              <img [src]="imageData(data.response_data.qrCodeBase64)" alt="Base64 encoded image"  [width]="256" />
  
            </div>
  
          </div>
      
          <h1 style="width: 100%; text-align: center; font-weight: bolder;">-OR-</h1>
        <mat-card-content>

       
        <ol>
          <li>Sign in to your e-wallet account.</li>
          <li>Look for and select the 'Transfer Funds' option, then choose 'Instapay'.</li>
          <li>In the list of destination banks, select 'Netbank'.</li>
          <li>You will be prompted to enter an account number. Please input: 
            <strong>{{data.response_data.account_number}}</strong>
            <button mat-icon-button [cdkCopyToClipboard]="data.response_data.account_number">
              <mat-icon>content_copy</mat-icon>
            </button>
            </li>
            
            <li>
                Next, you'll need to specify the transfer amount. Please key in: 
                <strong>{{data.response_data.amount_currency}} {{data.response_data.amount | number:'1.2-2'}}</strong>
                <button mat-icon-button [cdkCopyToClipboard]="(data.response_data.amount | number:'1.2-2') || ''">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  
              </li>
              
              
          <li>Before finalizing the transfer, please review all the transaction details carefully. <br/> If everything is accurate, you can proceed with the transfer.</li>
        </ol>
        <p>Please note that your reference ID for this transaction is: <strong>{{data.response_data.partner_reference_id}}</strong></p>
        <p>This transaction was created on: <strong>{{data.response_data.created_date  | date:'yyyy-MM-dd hh:mm aa'}}</strong></p>
       
       

        
       
        <mat-dialog-actions>
            <button mat-button mat-dialog-close>Close</button>
          </mat-dialog-actions>
      </mat-card-content>
    </mat-card>
  </div>
  