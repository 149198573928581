
      <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="account_name">
      <th mat-header-cell *matHeaderCellDef> Account Name </th>
      <td mat-cell *matCellDef="let element"> {{element.account_name}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="account_number">
        <th mat-header-cell *matHeaderCellDef> Account Number </th>
        <td mat-cell *matCellDef="let element"> {{element.account_number}} </td>
    </ng-container>
    
    <!--- Define columns -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef > Amount </th>
        <td mat-cell *matCellDef="let element" > {{element.amount | number:'1.2-2'}} </td>
        </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="biller_code">
        <th mat-header-cell *matHeaderCellDef> Biller Code </th>
        <td mat-cell *matCellDef="let element"> {{element.biller_code}} </td>
      </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="recipient_mobile_no">
        <th mat-header-cell *matHeaderCellDef> Recepient Mobile Number </th>
        <td mat-cell *matCellDef="let element"> {{element.recipient_mobile_no}} </td>
      </ng-container>

      <!--- Define columns -->
     <ng-container matColumnDef="receive_id">
        <th mat-header-cell *matHeaderCellDef> Receive ID </th>
        <td mat-cell *matCellDef="let element"> {{element.receive_id}} </td>
      </ng-container>

      <ng-container matColumnDef="reference_id">
        <th mat-header-cell *matHeaderCellDef> Reference ID </th>
        <td mat-cell *matCellDef="let element"> {{element.reference_id}} </td>
      </ng-container>
   

        <!--- Define columns -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'warning': element.status === 'PENDING', 'completed': element.status === 'COMPLETED', 'failed': element.status === 'FAILED'}"> {{element.status}} </td>
        </ng-container>
        
        

         <!--- Define columns -->
     <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>


    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      