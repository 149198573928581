
  

  <mat-card class="login-card">
    <div *ngIf="!isProd">
    <p class="mat-body">This demo offers on how to cash out from your outlet.</p>
  </div>
    <form [formGroup]="cashOutForm" (ngSubmit)="onSubmit()" class="login-form">
        <mat-form-field>
          <input matInput placeholder="Account Name" formControlName="account_name" required>
        </mat-form-field>
      
        <mat-form-field>
          <input matInput placeholder="Account Number" formControlName="account_number" required>
        </mat-form-field>
      
        <mat-form-field>
          <input matInput placeholder="Amount" formControlName="amount" required>
        </mat-form-field>
      
        <mat-form-field>
          <mat-select placeholder="Biller Code" formControlName="biller_code" required>
            <mat-option *ngFor="let bank of banks" [value]="bank.code">{{bank.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field>
          <input matInput placeholder="Recipient Mobile Number" formControlName="recipient_mobile_number" required>
        </mat-form-field>


        <mat-form-field>
            <mat-select placeholder="Select Outlet" formControlName="outlet_hash_id" required>
                <mat-option *ngFor="let outlet of outlets" [value]="outlet.outlet_hash_id">[Balance: {{outlet.wallet_amount | number:'1.2-2'}}] {{outlet.outlet_hash_id}} </mat-option>
            </mat-select>
          </mat-form-field>

  
      
        <button mat-raised-button type="submit" [disabled]="cashOutForm.invalid || isLoading" class="login-button">
            
            <span *ngIf="!isLoading">Submit</span>
            <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
        </button>

        
      </form>
  </mat-card>
  