import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  viewerHeight = '100vh'; // use vh (viewport height) units to make it responsive
  viewerWidth = '100vw'; // use vw (viewport width) units to make it responsive
  pdfSrc= "https://govipcenterstorage.blob.core.windows.net/gocashflex/gcf103.pdf";

  constructor() { }


  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
onResize(event: Event) {
  this.viewerHeight = window.innerHeight + 'px';
  this.viewerWidth = window.innerWidth + 'px';
}

}
