import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CashOutService } from 'src/app/services/cash-out.service';

@Component({
  selector: 'app-cash-outs',
  templateUrl: './cash-outs.component.html',
  styleUrls: ['./cash-outs.component.less']
})
export class CashOutsComponent implements OnInit {

  displayedColumns: string[] = ['account_name', 'account_number', 'amount', 'biller_code', 'recipient_mobile_no', 'receive_id', 'status', 'reference_id', 'created_date', ];
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;

  constructor(private cashOutService: CashOutService,
    ) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.pageSize);
  }
  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.cashOutService.getCashOuts(page, pageSize).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }

}
