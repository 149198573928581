import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { AddProductDialogComponent } from '../../components/add-product-dialog/add-product-dialog.component';
import { EditProductDialogComponent } from '../../components/edit-product-dialog/edit-product-dialog.component';

@Component({
  selector: 'app-digi-dine-product',
  templateUrl: './digi-dine-product.component.html',
  styleUrls: ['./digi-dine-product.component.less']
})
export class DigiDineProductComponent implements OnInit {

  private paramSubscription: Subscription | undefined;
  constructor(private route: ActivatedRoute,private digiDineService : DigiDineService,
    public dialog: MatDialog) { }
  outletHashId : string ="";

  
  displayedColumns: string[] = ['image' , 'name',  'price', 'description','category_name' ,'show_in_kitchen','is_active',  'created_date', 'updated_date','action' ]; //'id',
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.outletHashId = params['param1']; 
      this.fetchData(this.currentPage, this.pageSize);
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }


  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.digiDineService.getProducts(page, pageSize,this.outletHashId).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }


  openAddProductDialog(): void {
    const dialogRef = this.dialog.open(AddProductDialogComponent, {
      width: '500px',
      // Pass any data you need to the dialog here
      data: this.outletHashId
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
      this.fetchData(this.currentPage, this.pageSize);
      console.log('The dialog was closed');
    });
   }

   openEditProductDialog(element : any): void {
    const dialogRef = this.dialog.open(EditProductDialogComponent, {
      width: '500px',
      // Pass any data you need to the dialog here
      data: {
        outlet_hash_id : this.outletHashId,
        model : element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
      this.fetchData(this.currentPage, this.pageSize);
      console.log('The dialog was closed');
    });
   }

}
