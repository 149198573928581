import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { PaymentInstructionsComponent } from 'src/app/components/payment-instructions/payment-instructions.component';
import { CashInService } from 'src/app/services/cash-in.service';
import { VipCheckoutService } from 'src/app/services/vip-checkout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-checkout-ch2',
  templateUrl: './request-checkout-ch2.component.html',
  styleUrls: ['./request-checkout-ch2.component.less']
})
export class RequestCheckoutCh2Component implements OnInit {

  public isProd = environment.production;
  isLoading = false;
   thankYouUrl = environment.thankYouUrlCh2;
   errorUrl= environment.errorUrl;

   banks: { bank_code: string, title: string, logo_url : string }[] = [];
   checkoutForm = this.fb.group({
     bank_code: ['', Validators.required], 
   });

  constructor(private fb: FormBuilder,  
    private vipCheckoutService: VipCheckoutService,
    public dialog: MatDialog) { }

    ngOnInit(): void {
      this.vipCheckoutService.getVIPCheckoutOptionsCh2().subscribe(
        response => {
          this.banks =  response.response_data;
        });
    }
  

  onSubmit(): void {
   
    if (this.checkoutForm.valid) {
      const data = {
        ...this.checkoutForm.value,
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to proceed the payment checkout?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.vipCheckoutService.generateVIPCheckoutCh2(this.mobileNumberJson()).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    window.location.href = response.response_data.redirect_uri;
                      
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_data,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
          // user clicked No
        }
      });
    }
}

  mobileNumberJson(){
     return {
     customer:  {
       fname: "Juan",
       lname: "dela Cruz",
       mname: "Mercado",
       email: "juandelacruz@govipcenter.com",
       mobile: "+639012345678",
       phone: "+6388888888",
       customer_id: "ACMGCF00000001",
       address:  {
         line1: "#88",
         line2: "Boston Ave",
         city: "Quezon City",
         province: "Metro Manila",
         zip_code: "1117",
         country: "Philippines"
        }
      },
     amount: {
       cur: "PHP",
       num: "15000"
      },
      from: {
        "bank_code" : this.checkoutForm.get('bank_code')!.value
      },
     client: {
       return_url: this.thankYouUrl,
       fail_url: this.errorUrl
      }
    }
  }

}
