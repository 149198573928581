
<div class="list-add">
    <button mat-raised-button color="primary" (click)="openAddCategoryDialog()">Add Category</button>
</div>

<table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    
    <!--- Define columns -->
    <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
            <mat-slide-toggle color="primary"  [(ngModel)]="element.is_active" [disabled]="true"></mat-slide-toggle>
        </td>
    </ng-container>
    

     
         <!--- Define columns -->
     <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

          <!--- Define columns -->
     <ng-container matColumnDef="updated_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Updated Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.updated_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>


  <!--- Define columns -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
    <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openEditCategoryDialog(element)"  class="button-spacing">Edit</button> </td>
  </ng-container>


    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      