
  
  
    <div class="mat-dialog-content">
        <h3>Add Discount</h3>
       
        <strong>Total Amount : {{totalAmount| number:'1.2-2'}}</strong><br/>
      
        <mat-card class="login-card">
         
            <form [formGroup]="billOutForm" (ngSubmit)="onSubmit()" class="login-form">
          
            <mat-form-field>
                <input matInput placeholder="Discount Amount" formControlName="discount_amount" required>
            </mat-form-field>
             
            
              <button mat-raised-button type="submit" [disabled]="billOutForm.invalid || isLoading" class="login-button">
                  
                  <span *ngIf="!isLoading">Submit</span>
                  <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
              </button>
      
              
            </form>
        </mat-card>

    </div>
    
  
    