
<div class="list-add">
    <button mat-raised-button color="primary" (click)="addTable()">Add Table</button>
    &nbsp;
    <button mat-raised-button color="secondary" (click)="deleteTable()">Remove Last Table</button>
</div>

<table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="table_number">
        <th mat-header-cell *matHeaderCellDef> Table Number </th>
        <td mat-cell *matCellDef="let element"> Table Number {{element.table_number}} </td>
    </ng-container>
    
    <!--- Define columns -->
    <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
            <mat-slide-toggle color="primary"  [(ngModel)]="element.is_active" [disabled]="true"></mat-slide-toggle>
        </td>
    </ng-container>
    

     
         <!--- Define columns -->
     <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

          <!--- Define columns -->
     <ng-container matColumnDef="updated_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Updated Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.updated_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>


  <!--- Define columns -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
    <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="printBase64Image(element.qr_code, element.table_number)"   class="button-spacing">Print QR</button> </td>
  </ng-container>
  <!-- -->

    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      