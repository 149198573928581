import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VipPayService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getVIPPays(pageNumber: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}vip_pays?page_number=${pageNumber}&page_size=${pageSize}`;

    return this.http.get(url, { headers: headers });
  }

 

  
}
