import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { DigiDineService } from 'src/app/services/digi-dine.service';

@Component({
  selector: 'app-edit-category-dialog',
  templateUrl: './edit-category-dialog.component.html',
  styleUrls: ['./edit-category-dialog.component.less']
})
export class EditCategoryDialogComponent implements OnInit {

  isLoading = false;
  categoryForm = this.fb.group({
    name: ['', Validators.required], 
    is_active: ['', Validators.required], 
  });
  outletHashId : any;
  id : any;

  constructor(@Inject(MAT_DIALOG_DATA) public data  : { outlet_hash_id: any, model: any },private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService) { 
    this.outletHashId= data.outlet_hash_id;
    this.id = data.model.id;
    this.categoryForm.patchValue({
      name: data.model.name,
      is_active: data.model.is_active
    });
    
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.categoryForm.valid) {
      const data = {
        outlet_hash_id: this.outletHashId,
        ...this.categoryForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to edit the selected category?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.digiDineService.updateCategory(data,this.id).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    if(response.response_result === "0001") {
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "Category successfully updated!",
                          header: "Transaction successful"
                        }
                      });
                      // reset the form
                      // this.ngOnInit();
                      // this.cashOutForm.reset();
                      // this.cashOutForm.markAsUntouched();
                      // this.cashOutForm.markAsPristine();
                      this.dialog.closeAll();
                    } else if(response.response_result === "0002") {
                      this.isLoading = false;
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: response.response_description,
                          header: "Transaction error"
                        }
                      });
                      // handle failure case
                    }
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_description,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
        }
      });

     
  }
}

}
