import { Component, OnInit } from '@angular/core';
import { CashOutService } from 'src/app/services/cash-out.service';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.less']
})
export class BanksComponent implements OnInit {
  bankDetails = [];
  displayedColumns: string[] = ['name', 'code', 'amountFrom', 'amountTo'];

  constructor(private cashOutService: CashOutService) { }

  ngOnInit(): void {
    this.cashOutService.getBankDetails().subscribe(
      response => {
        this.bankDetails = response.response_data;
      },
      error => {
        console.error(error);
      }
    );
  }
}