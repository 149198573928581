import { Component, Inject, OnInit, inject } from '@angular/core';
import {  collection, getDoc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { Firestore, doc, onSnapshot, DocumentReference, docSnapshots } from '@angular/fire/firestore';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-public-add-order-dialog',
  templateUrl: './public-add-order-dialog.component.html',
  styleUrls: ['./public-add-order-dialog.component.less']
})
export class PublicAddOrderDialogComponent implements OnInit {

  outletHashId : any;
  product : any;
  tableNumber : any;
productPrice = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data  : { outletHashId: any, model: any, tableNumber : any },
  private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService,
  public dialogSelf: MatDialogRef<PublicAddOrderDialogComponent>,
  private firestore: Firestore) { 
    this.outletHashId= data;
    this.outletHashId= data.outletHashId;
    this.tableNumber= data.tableNumber;
    this.product = data.model;
  }


  ngOnInit(): void {
   
  }
  quantity: number = 1;
  increaseQuantity() {
    this.quantity++;
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  isLoading = false;
  addOrder(){

    if(this.isLoading == false){

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to order this?"
    
      dialogRef.afterClosed().subscribe(async result => {
        if(result) { 
          this.isLoading = true;
  
  
          //CHECK IF TABLE NUMBER EXIST
          var docTableExist: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}`);
          const ordersQuery = query(
            collection(docTableExist, 'ActiveOrders'),  // Replace 'YourSubCollection' with the actual subcollection name
            where('table_number', '==', this.tableNumber),
            where('order_status', '==', 'ACTIVE')
          );
          const querySnapshot = await getDocs(ordersQuery);
  
          if(!querySnapshot.empty) {
            const firstDoc = querySnapshot.docs[0];
            this.addOrderMenu(firstDoc.id);	
          }else{
          this.isLoading = false;
          }
  
         
        } else {
        }
      });
  
    }

    



    
  }

  addOrderMenu(tableNumberId : any){
        const orderId = uuidv4();
        var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}/Orders/${orderId}`);

        let date: Date = new Date();

        setDoc(docOrder, { 
          id : this.data.model.id,
          image : this.data.model.image,
          name : this.data.model.name,
          price : this.data.model.price,
          status : "NEW",
          created_date :date,
          show_in_kitchen : this.data.model.show_in_kitchen,
          quantity : this.quantity
        }, {
          merge: true,
        }).then(res =>{
          this.digiDineService.updateGenerateVIPPay(this.outletHashId,tableNumberId).subscribe(
            response => {
              this.dialogSelf.close(true); 
              this.isLoading = false;
            }, error=>{
              this.dialogSelf.close(true); 
                this.isLoading = false;
            });
        }).catch(error=>{
          this.isLoading = false;
        });

        //RUN API
       

        
  }

}


