
<div style="display: flex; flex-wrap: wrap;">
    <mat-card *ngFor="let order of sortFoodByCreatedDateAsc(foodOrders)" style="width: 210px;max-width: 210px; margin: 10px;">
      <div style="height: 40px;">
        <strong  class="order-title-order; float:left;" >{{order.name}} </strong>
       
    </div>
    <div style="height: 30px;">
        <strong style="color:green;font-size: x-large;">ORDER COUNT : {{order.quantity}}</strong>
    </div>
      <img mat-card-image [src]="order.image">
      <div style="height: 30px;">
        <strong >TABLE NUMBER {{order.table_number}}</strong>
    </div>
    <div class="order-content"  *ngIf="order.status !== 'SERVED' && order.elapsedTime">
        <strong style="color:red;font-size: x-large;">{{order.elapsedTime}}</strong>
      </div>
    <div class="order-content"  *ngIf="order.status !== 'SERVED' && !order.elapsedTime">
   
    <strong style="color:red;font-size: x-large;">Loading...</strong>
    </div>
      <button *ngIf="order.status === 'NEW'" mat-raised-button (click)="updateOrderStatus(order.tableNumberId,order.id,'PREPARING')" style="background-color: green; color: white; width: 100%;"   >TAG AS PREPARING</button>
      <button *ngIf="order.status === 'PREPARING'" mat-raised-button (click)="updateOrderStatus(order.tableNumberId,order.id,'READY')" style="background-color: red; color: white; width: 100%;"   >TAG AS FOOD IS READY</button>
      <button *ngIf="order.status === 'READY'" mat-raised-button  style="background-color: black; color: white; width: 100%;"   >WAITER IS SERVING</button>
    </mat-card>
  </div>

  
