import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { TransactionDetailDialogComponent } from 'src/app/components/transaction-detail-dialog/transaction-detail-dialog.component';
import { UnifiedLedgerDialogComponent } from 'src/app/components/unified-ledger-dialog/unified-ledger-dialog.component';
import { UserLedgerDialogComponent } from 'src/app/components/user-ledger-dialog/user-ledger-dialog.component';
import { UserTransactionSummaryComponent } from 'src/app/components/user-transaction-summary/user-transaction-summary.component';
import { AuditOutletsService } from 'src/app/services/audit-outlets.service';
import { OutletService } from 'src/app/services/outlet.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-transactions',
  templateUrl: './audit-transactions.component.html',
  styleUrls: ['./audit-transactions.component.less']
})
export class AuditTransactionsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  public isProd = environment.production;
  isLoading = false;
  outlets: { id: number, username: string, email: string, created_date: string, manager_details : any }[] = [];
  currentDateTime : any;
 

  cashOutForm = this.fb.group({
    outlet_hash_id: ['', Validators.required]
  });

  constructor(private fb: FormBuilder,
    private auditOutletsService: AuditOutletsService,public dialog: MatDialog ) { }

  ngOnInit(): void {
    this.currentDateTime = new Date();
    this.searchSubscription = this.searchSubject.pipe(
      debounceTime(500) // Time in milliseconds between key events
    ).subscribe(newValue => {
      this.username = newValue.trim().toLowerCase();
      this.resetPaginator();
    });
    this.outletsWallet();
    this.fetchData(this.currentPage, this.pageSize);
  }

  resetPaginator() {
    this.paginator.firstPage();
    this.currentPage= 1;
    this.fetchData(this.currentPage, this.pageSize);
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

 
  
  onSubmit(): void {
    if (this.cashOutForm.valid) {
      const data = {
        ...this.cashOutForm.value,
      };

      this.fetchData(this.currentPage, this.pageSize);

    }
  }


  /** Outlet ledger list **/

  displayedColumns: string[] = ['id','username','combined_wallet','last_transaction_date','action'];
  displayedColumnsCashiers: string[] = ['user_id','first_name', 'last_name', 'contact_number', 'wallet','last_transaction_date', 'action'];
  


  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;
  selectedOutletHashId! : string;
  outletsWalletVal! : number;

  private searchSubject = new Subject<string>();
  private searchSubscription!: Subscription;
  username : string ="";

  outletsWallet(): void {
 // Spinner starts before API call
    this.auditOutletsService.outletsWallet().subscribe(response => {
       this.outletsWalletVal= response.response_data;
    });
  }


  fetchData( page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.auditOutletsService.getOutlets(page, pageSize, this.username).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchSubject.next(filterValue);
  }


  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
   this.fetchData(this.currentPage, this.pageSize);
  }
  managerDetails : any;
  openDialog(managerDetailsData : any): void {
    this.managerDetails = managerDetailsData;
  }
  isLoadingSummary = false;

  openDialogTransaction(id : number){
  
    this.isLoadingSummary = true;  // Spinner starts before API call
    this.auditOutletsService.getUserTransactionSummary(id).subscribe(response => {
      var dataSourceSummary = new MatTableDataSource([]);
      console.log(response)
      dataSourceSummary.data = response.response_data;
      this.isLoadingSummary = false; 
      this.dialog.open(UserTransactionSummaryComponent, {
        width: '100%',
        data: {
          details :dataSourceSummary
        } // The transaction details object you want to display
      });
    });
  }

  openDialogLedger(id : number){
  
    this.dialog.open(UserLedgerDialogComponent, {
      width: '100%',
      height: '80%', // or any other percentage or pixel value you want
      data: {
        userId :id
      }, // The transaction details object you want to display
      panelClass: 'custom-dialog-container' // Add a custom CSS class to the dialog container
    });
    
  }

  openDialogUnifiedLedger(id : number){
  
    this.dialog.open(UnifiedLedgerDialogComponent, {
      width: '100%',
      height: '80%', // or any other percentage or pixel value you want
      data: {
        userId :id
      }, // The transaction details object you want to display
      panelClass: 'custom-dialog-container' // Add a custom CSS class to the dialog container
    });
    
  }
}
