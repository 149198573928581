import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-gvc',
  templateUrl: './login-gvc.component.html',
  styleUrls: ['./login-gvc.component.less']
})
export class LoginGVCComponent implements OnInit {
  isLoading = false;
  loginForm!: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private environmentService : EnvironmentService) { }

    public isProd = environment.production;
  ngOnInit(): void {

    localStorage.removeItem('accessToken');

    this.loginForm = this.formBuilder.group({
      clientId: ['', Validators.required], //
      clientPassphrase: ['', Validators.required], //
      companyName: ['', Validators.required] //
    });
   
  }

  submit() {
    if (this.loginForm.valid) {
      const clientId = this.loginForm.get('clientId')!.value;
      const clientPassphrase = this.loginForm.get('clientPassphrase')!.value;
      const companyName = this.loginForm.get('companyName')!.value;
  
      this.isLoading = true;
  
      this.authService.login(clientId, clientPassphrase, companyName).subscribe(
        response => {
          this.isLoading = false;
          // Navigate to the dashboard
          this.router.navigate(['/dashboard-gvc/audit-transactions']);
        },
        error => {
          this.isLoading = false;
         
        }
      );
    }
  }
  
}
