<div class="container">
    <mat-card class="module-card" (click)="navigateToCategories()">
        <mat-card-content>
          <div class="flex-container">
            <div >
            <mat-icon class="larger-icon">category</mat-icon>
              <p>Manage Category </p>
            </div>
            <div class="flex-item" >
              <p class="count" >{{dataCount.categories}}</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

    <mat-card class="module-card" (click)="navigateToProducts()">
        <mat-card-content>
          <div class="flex-container">
            <div >
            <mat-icon class="larger-icon">local_cafe</mat-icon>
              <p>Manage Product</p>
            </div>
            <div class="flex-item" >
              <p class="count" >{{dataCount.products}}</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      
      <mat-card class="module-card" (click)="navigateToTables()">
        <mat-card-content>
          <div class="flex-container">
            <div >
            <mat-icon class="larger-icon">table_chart</mat-icon>
              <p>Manage Tables</p>
            </div>
            <div class="flex-item" >
              <p class="count" >{{dataCount.tables}}</p>
            </div>
          </div>
        </mat-card-content>
        
      </mat-card>


      <mat-card class="module-card" (click)="navigateToActiveOrders()">
        <mat-card-content>
          <div class="flex-container">
            <div >
            <mat-icon class="larger-icon">room_service</mat-icon>
              <p>Active Orders - Cashier/Waiters View</p>
            </div>
            <div class="flex-item" >
              <p class="count" >{{orderCountData}}</p>
            </div>
          </div>
        </mat-card-content>
        
      </mat-card>


      <mat-card class="module-card" (click)="navigateToActiveOrdersKitchen()">
        <mat-card-content>
          <div class="flex-container">
            <div >
            <mat-icon class="larger-icon">restaurant</mat-icon>
              <p>Active Orders - Kitchen View</p>
            </div>
            <div class="flex-item" >
              <p class="count" >{{orderItemCountData}}</p>
            </div>
          </div>
        </mat-card-content>
        
      </mat-card>
      
  
  
      <mat-card class="module-card" (click)="navigateToActiveOrderHistory()">
        <mat-card-content>
          <div class="flex-container">
            <div >
            <mat-icon class="larger-icon">receipt</mat-icon>
              <p>Order History</p>
            </div>
            <div class="flex-item" >
              <p class="count" >{{dataCount.order_histories}}</p>
            </div>
          </div>
        </mat-card-content>
        
      </mat-card>
  
    
    
  </div>
  