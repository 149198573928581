<!-- outlet-details.component.html -->

  <table mat-table matSort matTableResponsive [dataSource]="outletDetails" >
  
      <!-- Outlet Hash ID Column -->
      <ng-container matColumnDef="outlet_hash_id">
        <th mat-header-cell *matHeaderCellDef> Outlet Hash ID </th>
        <td #hashCell mat-cell *matCellDef="let element">
          <span>{{element.outlet_hash_id}}</span>
          <button mat-icon-button (click)="copyToClipboard(element.outlet_hash_id)" style="margin-left: auto;">
            <mat-icon class="copy-icon">content_copy</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <!-- Wallet Amount Column -->
      <ng-container matColumnDef="wallet_amount">
        <th mat-header-cell *matHeaderCellDef > Wallet Amount </th>
        <td mat-cell *matCellDef="let element" > {{element.wallet_amount | number:'1.2-2'}} </td>
      </ng-container>
  
    <!-- Max Limit Column -->
    <ng-container matColumnDef="max_limit">
        <th mat-header-cell *matHeaderCellDef > Max Limit </th>
        <td mat-cell *matCellDef="let element" > {{element.max_limit | number:'1.2-2'}} </td>
    </ng-container>

    <!-- Consumed Amount Column -->
    <ng-container matColumnDef="consumed_amount">
        <th mat-header-cell *matHeaderCellDef > Consumed Amount </th>
        <td mat-cell *matCellDef="let element" > {{element.consumed_amount | number:'1.2-2'}} </td>
    </ng-container>

    <!-- Transaction Count Column -->
    <ng-container matColumnDef="transaction_count">
        <th mat-header-cell *matHeaderCellDef > Transaction Count </th>
        <td mat-cell *matCellDef="let element" > {{element.transaction_count}} </td>
    </ng-container>

    <!-- Cash In Count Column -->
    <ng-container matColumnDef="transaction_cash_in_count">
        <th mat-header-cell *matHeaderCellDef > Cashin Count </th>
        <td mat-cell *matCellDef="let element" > {{element.transaction_cash_in_count}} </td>
    </ng-container>

    <!-- Cash Out Count Column -->
    <ng-container matColumnDef="transaction_cash_out_count">
        <th mat-header-cell *matHeaderCellDef > Cashout Count </th>
        <td mat-cell *matCellDef="let element" > {{element.transaction_cash_out_count}} </td>
    </ng-container>

    <!-- Limit Start DateTime Column -->
    <ng-container matColumnDef="limit_start_datetime">
        <th mat-header-cell *matHeaderCellDef > Limit Start </th>
        <td mat-cell *matCellDef="let element" > {{element.limit_start_datetime | date:'yyyy-MM-dd hh:mm aa'}} </td>
    </ng-container>

    <!-- Limit End DateTime Column -->
    <ng-container matColumnDef="limit_end_datetime">
        <th mat-header-cell *matHeaderCellDef > Limit End </th>
        <td mat-cell *matCellDef="let element" > {{element.limit_end_datetime | date:'yyyy-MM-dd hh:mm aa'}} </td>
    </ng-container>

      <!--- Define columns -->
      <ng-container  matColumnDef="allocate">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Allocate </th>
        <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openConfirmationDialog(registeredCashIn,element.outlet_hash_id)" class="button-spacing">Allocate</button> </td>
      </ng-container>

  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  