import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { CollectionReference, DocumentReference, Firestore, collection, collectionSnapshots, doc, docSnapshots, getDocs, orderBy, query, where } from '@angular/fire/firestore';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { PaymentInstructionsComponent } from 'src/app/components/payment-instructions/payment-instructions.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { AppPaymentInstructionsDialogComponent } from '../app-payment-instructions-dialog/app-payment-instructions-dialog.component';

@Component({
  selector: 'app-bill-details-dialog',
  templateUrl: './bill-details-dialog.component.html',
  styleUrls: ['./bill-details-dialog.component.less']
})
export class BillDetailsDialogComponent implements OnInit {

 
  outletHashId :any;
  tableNumber :any;
  tableNumberId :any;
  constructor(@Inject(MAT_DIALOG_DATA) public data  : { outletHashId : any,tableNumber:any },
  private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService,
  public dialogSelf: MatDialogRef<BillDetailsDialogComponent>,
  private firestore: Firestore) { 
   this.outletHashId = data.outletHashId;
   this.tableNumber = data.tableNumber;
   this.viewOrders();
  }

  ngOnInit(): void {
  }
  
  orders : any;
  totalSum:any;
  col: CollectionReference | undefined;
  docRefMonitor: DocumentReference | undefined;
  orderSession:any;
  async viewOrders(){
    
      var docTableExist: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}`);
      const ordersQuery = query(
        collection(docTableExist, 'ActiveOrders'),  // Replace 'YourSubCollection' with the actual subcollection name
        where('table_number', '==', this.tableNumber),
        where('order_status', '==', 'ACTIVE')
      );
      const querySnapshot = await getDocs(ordersQuery);

      if(!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
      
        this.docRefMonitor = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${firstDoc.id}`);
        docSnapshots(this.docRefMonitor).subscribe(snapCollection => {
            this.orderSession = snapCollection.data();
        });

        


        this.tableNumberId = firstDoc.id;
        this.col = collection(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${firstDoc.id}/Orders`);
        const q = query(this.col, orderBy("created_date", "asc"));  // Add this line
          collectionSnapshots(q).subscribe(snapCollection => {
            this.orders = snapCollection
            this.totalSum= 0;
            snapCollection.forEach(doc => {
              const documentData : any = doc.data();
              this.totalSum += (documentData.price *documentData.quantity);
            });
          });
        
      } 
    
       
  }
  onClose(): void {
    this.dialogSelf.close();
  }

  isLoading = false;
  payNow(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to bill out?"
  
    dialogRef.afterClosed().subscribe(async result => {
      if(result) { 
        
        this.isLoading = true;
        this.digiDineService.requestVIPPay(this.outletHashId,this.tableNumberId).subscribe(
          response => {
            // handle success case
            this.isLoading = false;
            
              //Show payment instructions
              this.dialog.open(AppPaymentInstructionsDialogComponent, {
                height:'80%',
                data: response,
                autoFocus: false
              });
              //Show payment instructions

             
              
          },
          error => {
            this.isLoading = false;
            this.dialog.open(ErrorDialogComponent, {
              data: {
                content: "Transaction error. Please call the waiter",
                header: "Transaction error"
              }
            });
          }
        );
      } 
    });

  }

 
}
