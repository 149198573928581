

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav [mode]="isLargeScreen ? 'side' : 'over'" class="sidenav"  [opened]="isLargeScreen">
      <br/>
      
      <mat-toolbar >
        <img src="https://govipcenter.com/assets/images/logo/GoVIP%20Logo.png" alt="logo" class="logo">
      </mat-toolbar>
      <mat-nav-list>
  
        <h3>Main Navigation</h3>
  
        <a *ngIf="!isProd" mat-list-item routerLink="/dashboard-gvc/home">
          <mat-icon>home</mat-icon>
          Home
        </a>
        <a mat-list-item routerLink="/dashboard-gvc/audit-transactions">
          <mat-icon>assignment_turned_in</mat-icon>
          Audit Outlets
        </a>
        
     
  
        <h3>Settings</h3>
        <a mat-list-item routerLink="/login-gvc">
          <mat-icon>logout</mat-icon>
          Logout
        </a>
  
      </mat-nav-list>
    </mat-sidenav>
    
    <mat-sidenav-content class="sidenav-content">
      <mat-toolbar color="primary">
        <button mat-icon-button (click)="sidenav.toggle()" *ngIf="!isLargeScreen">
          <mat-icon>menu</mat-icon>
        </button>
        <h2 class="title">GoVIPCenter Finance Tool Version 1.0</h2>
      </mat-toolbar>
      <!-- <app-breadcrumb class="header-class"></app-breadcrumb> -->
      <router-outlet ></router-outlet>
    </mat-sidenav-content>
    
  </mat-sidenav-container> 
  
  
  