<div class="mat-dialog-content">
<div class="container">
  <div class="card">
    <h2 class="title">Transaction Details</h2>
    <div class="details">
      <div class="row">
        <span class="label">Transaction ID</span>
        <span class="value">{{data.transaction_id}}</span>
      </div>
      <div class="row">
        <span class="label">Source Account Number</span>
        <span class="value">{{data.source_account_number}}</span>
      </div>
      <div class="row">
        <span class="label">Source Bank Code</span>
        <span class="value">{{data.source_bank_code}}</span>
      </div>
      <div class="row">
        <span class="label">Destination Account Number</span>
        <span class="value">{{data.destination_account_number}}</span>
      </div>
      <div class="row">
        <span class="label">Amount</span>
        <span class="value">{{data.amount| number:'1.2-2'}}</span>
      </div>
      <div class="row">
        <span class="label">Amount Currency</span>
        <span class="value">{{data.amount_currency}}</span>
      </div>
      <div class="row">
        <span class="label">Settlement Rail</span>
        <span class="value">{{data.settlement_rail}}</span>
      </div>
      <div class="row">
        <span class="label">Partner Status</span>
        <span class="value warning">{{data.partner_status}}</span>
      </div>
      <div class="row">
        <span class="label">Created Date</span>
        <span class="value">{{data.created_date | date:'yyyy-MM-dd hh:mm aa'}}</span>
      </div>
      <div class="row">
        <span class="label">Updated Date</span>
        <span class="value">{{data.updated_date | date:'yyyy-MM-dd hh:mm aa'}}</span>
      </div>

      
    </div>
  </div>
 
</div>

<div class="container">
  <div class="card">
    <h2 class="title">Please select an outlet where you would like to allocate your funds.</h2>
    <div class="details">
      <div class="row">
        <div mat-dialog-content>
          <!-- Your form or method to choose outlet hash key goes here -->
          <app-outlets (triggerEvent)="onTrigger($event)" [registeredCashIn]="data"></app-outlets>
        </div>
       
      </div>
    </div>
  </div>
 
</div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
</div>


