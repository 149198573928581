


  <mat-card class="login-card">

    <div *ngIf="!isProd">
    <p class="mat-body">This demo presents a walkthrough on how to request a payment.</p>
    <mat-label>
        The <code>partner_reference_id</code> is a unique identifier for your transactions. It is made up of three parts:
      </mat-label>
  
      <ul>
        <li>
          <b>Company Code:</b> A 7-digit company code, which is specific to your company. For example, "9002202".
        </li>
        <li>
          <b>Date:</b> A 8-digit representation of the current date (year, month, and day) in the "yyyyMMdd" format. For example, if the current date is 16th June 2023, it would be represented as "20230616".
        </li>
        <li>
          <b>Random Characters:</b> A set of 5 random uppercase letters or numbers. 
        </li>
      </ul>
  
      <mat-label>The three parts are combined without any separator. For instance, if your company code is "9002202", today's date is "20230616" and your random characters are "AAAAD", the <code>partner_reference_id</code> will be "900220220230616AAAAD".</mat-label>
    </div>
      <br/>

    <form [formGroup]="requestVIPPayForm" (ngSubmit)="onSubmit()" class="login-form">
        <mat-form-field>
          <input matInput placeholder="Partner Reference ID (Auto-Generated)" formControlName="partner_reference_id" required readonly>
      </mat-form-field>
        
        <mat-form-field>
          <input matInput [placeholder]="isProd ? 'Amount':  'Amount (Auto-Generated)'" formControlName="amount" required [readonly]="!isProd">
      </mat-form-field>
    
          
        
          <button mat-raised-button type="submit" [disabled]="requestVIPPayForm.invalid || isLoading" class="login-button" >
              
              <span *ngIf="!isLoading">Submit</span>
              <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
          </button>
  
          
        </form>
  
  </mat-card>
  

