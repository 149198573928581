
  
  
    <div class="mat-dialog-content">
        <h3>Add Product</h3>

        <mat-card class="login-card">
          <form [formGroup]="productForm" (ngSubmit)="onSubmit()" class="login-form">


            <mat-slide-toggle color="primary" formControlName="show_in_kitchen">Show in Kitchen</mat-slide-toggle>

            <mat-form-field>
                <mat-select placeholder="Category" formControlName="category_id" required>
                  <mat-option *ngFor="let category of categoryList" [value]="category.id">{{category.name}}</mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field>
                <input matInput placeholder="Name" formControlName="name" required>
              </mat-form-field>
    
              <mat-form-field>
                <input matInput placeholder="Price" formControlName="price" required>
              </mat-form-field>
    
              <mat-form-field>
                <textarea matInput placeholder="Description" formControlName="description" rows="3" required></textarea>
            </mat-form-field>
            

            <mat-form-field>
                <ngx-mat-file-input formControlName="image" placeholder="Image" 
                                    (change)="onFileSelected($event)" accept=".png,.jpg,.jpeg"></ngx-mat-file-input>
                <mat-icon matSuffix>image</mat-icon>
            </mat-form-field>
            
              
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="4 / 3"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
              ></image-cropper>
        
                <!-- <img *ngIf="croppedImage" [src]="croppedImage" alt="Image preview"> -->
            
              <button mat-raised-button type="submit" [disabled]="productForm.invalid || isLoading" class="login-button">
                  
                  <span *ngIf="!isLoading">Submit</span>
                  <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
              </button>
      
              
            </form>
        </mat-card>

    </div>
    
  
    