import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CashInService {

  
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getCompanyCode(): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}company_code`, { headers });
  }


  getRegisteredPendingsCashIns(page: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}register_cash_in_pendings?page_number=${page}&page_size=${pageSize}`, { headers });
  }

  getRegisteredCashIns(page: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}register_cash_ins?page_number=${page}&page_size=${pageSize}`, { headers });
  }


  creditCashInToOutlet(outlet_hash_id: string, transaction_id: string): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    };
    const body = {
      outlet_hash_id: outlet_hash_id,
      transaction_id: transaction_id
    };
    return this.http.post(`${this.baseUrl}credit_cash_in_to_outlet`, body, { headers: headers });
  }

  transactCashIn(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}simulate_register_cash_in`, data, { headers });
  }

  //Payment Gateway

  requestVIPPay(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}generate_vip_pay`, data, { headers });
  }

  acquireQRPHCashIn(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}acquire_qrph_cashin`, data, { headers });
  }
  
}
