import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { PublicAddOrderDialogComponent } from '../../components/public-add-order-dialog/public-add-order-dialog.component';
import { CollectionReference, DocumentReference, Firestore, collection, collectionSnapshots, doc, docSnapshots, getDoc, getDocs, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { v4 as uuidv4 } from 'uuid';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { BillDetailsDialogComponent } from '../../components/bill-details-dialog/bill-details-dialog.component';

@Component({
  selector: 'app-digi-dine-menu',
  templateUrl: './digi-dine-menu.component.html',
  styleUrls: ['./digi-dine-menu.component.less']
})
export class DigiDineMenuComponent implements OnInit {

  private paramSubscription: Subscription | undefined;
  outletHashId : string ="";
  tableNumber : number | undefined;
  constructor(private route: ActivatedRoute,private digiDineService : DigiDineService,
    public dialog: MatDialog,private firestore: Firestore ) { } 

  menuList: any;

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      let splitStr = params['param1'].split('>>>>>');
      this.tableNumber =splitStr[1]; 
      this.outletHashId =splitStr[2]; 
      this.viewOrders();
      this.digiDineService.getPublicRestoMenuList(this.outletHashId).subscribe(
        response => {
          this.menuList = response.response_data
        });
    });
  }


  openAddOrderDialog(product : any): void {
    const dialogRef = this.dialog.open(PublicAddOrderDialogComponent, {
      width: '500px',
      // Pass any data you need to the dialog here
      data: {
        outletHashId : this.outletHashId,
        model : product,
        tableNumber : this.tableNumber
      } 
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
      if(result){
        this.showBillDetails();
        this.dialog.open(ErrorDialogComponent, {
          data: {
            header: "Order Confirmation",
            content: "Your order has been successfully placed! The food is being prepared and will be served shortly. You may monitor the status of your order through your cart. Thank you for your patience"
          }
        });
        console.log('The dialog was closed' + result);
      }
    });
   }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }


  @ViewChild('drawer') drawer: MatDrawer | undefined;

  toggleSideNav(): void {
    this.drawer!.toggle();
  }

  col: CollectionReference | undefined;
  docRefMonitor: DocumentReference | undefined;
  totalSum = 0;
  orders :any;
  emptySession :boolean | undefined;
  orderSession : any;
  docRefMonitorSubscription: Subscription | undefined;
  async viewOrders(){
    try {
      var docTableExist: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}`);
      const ordersQuery = query(
        collection(docTableExist, 'ActiveOrders'),  // Replace 'YourSubCollection' with the actual subcollection name
        where('table_number', '==', this.tableNumber),
        where('order_status', '==', 'ACTIVE')
      );
      const querySnapshot = await getDocs(ordersQuery);

      if(!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];

        this.docRefMonitor = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${firstDoc.id}`);
        this.docRefMonitorSubscription =  docSnapshots(this.docRefMonitor).subscribe(snapCollection => {
          if (!snapCollection.exists) {
            console.log('The document does not exist.');
            this.emptySession = true;
          } else {
            var orderSession : any = snapCollection.data();
            this.orderSession = snapCollection.data();
            if(orderSession == null){
              this.emptySession = true;
            }
            else if(orderSession.order_status == "COMPLETED"){
              if(this.dialog.openDialogs.length > 0) {
                this.dialog.closeAll();
              }
              this.emptySession = true;
            }
            else if(this.orderSession.payment_status === 'PAID') {
             // this.docRefMonitorSubscription!.unsubscribe();
              if(this.dialog.openDialogs.length > 0) {
                this.dialog.closeAll();
              }
            }
          }
         
        });


        this.emptySession = false;
        this.col = collection(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${firstDoc.id}/Orders`);
        const q = query(this.col, orderBy("created_date", "asc"));  // Add this line
          collectionSnapshots(q).subscribe(snapCollection => {
            this.orders = snapCollection
            this.totalSum= 0;
            snapCollection.forEach(doc => {
              const documentData : any = doc.data();
              this.totalSum += (documentData.price *documentData.quantity);
            });
          });
        
      } else{
        this.dialog.open(ErrorDialogComponent, {
          data: {
            header: "Friendly Reminder",
            content: "Our waiter is diligently assembling a delightful menu for you. We appreciate your patience during this brief wait."
          }
        });
        this.emptySession = true;
      }
    } catch (error) {
      this.emptySession = true;
    }
       
  }

  refreshPage() {
    window.location.reload();
 }

 checkOrderPaymentStatus(orderSession : any){
  try {
    if(orderSession == null){

      return true;
    }else{
      return orderSession.payment_status != 'PAID';
    }
   
  } catch (error) {
    return true;
  }

 }

  async startSession(){

    var docTableExist: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}`);
    const ordersQuery = query(
      collection(docTableExist, 'ActiveOrders'),  // Replace 'YourSubCollection' with the actual subcollection name
      where('table_number', '==', this.tableNumber),
      where('order_status', '==', 'ACTIVE')
    );
    const querySnapshot = await getDocs(ordersQuery);

    if(querySnapshot.empty) {
        const tableNumberId = uuidv4();
          var docTable: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${tableNumberId}`);
          
          getDoc(docTable).then((docTableSnapshot) => {
            if (!docTableSnapshot.exists()) {
              let date: Date = new Date();
              setDoc(docTable, {
                  created_date :date,
                  table_number : this.tableNumber,
                  order_status : "ACTIVE"
              }).then(() => {
                this.viewOrders();
                this.emptySession = false;
                console.log("Document successfully written!");
              }).catch((error) => {
                console.error("Error writing document: ", error);
              });
            }
          });
    } 
  }

  showBillDetails(){
    
    const dialogRef = this.dialog.open(BillDetailsDialogComponent, {
      width: '100%',
      height: '80%',
      // Pass any data you need to the dialog here
      data: {
        outletHashId : this.outletHashId,
        tableNumber : this.tableNumber
      } 
    });
    

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
      if(result){
        console.log('The dialog was closed' + result);
      }
    });
  }

  filterProducts(products : any[], status : boolean){
    return products.filter(x=>x.is_active == status);
  }
}

