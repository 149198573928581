import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { PaymentInstructionsComponent } from 'src/app/components/payment-instructions/payment-instructions.component';
import { CashInService } from 'src/app/services/cash-in.service';
import { VipCheckoutService } from 'src/app/services/vip-checkout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-checkout',
  templateUrl: './request-checkout.component.html',
  styleUrls: ['./request-checkout.component.less']
})
export class RequestCheckoutComponent implements OnInit {

  public isProd = environment.production;
  isLoading = false;
   thankYouUrl = environment.thankYouUrl;
  
  banks: { value: string, code: string, image : string }[] = [];
  checkoutForm = this.fb.group({
    bank_code: ['', Validators.required], 
  });

  constructor(private fb: FormBuilder,  
    private vipCheckoutService: VipCheckoutService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.vipCheckoutService.getVIPCheckoutOptions().subscribe(
      response => {
        this.banks =  response.response_data;
      });
  }

  

  onSubmit(): void {
    if (this.checkoutForm.valid) {
      const data = {
        ...this.checkoutForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to proceed the payment checkout?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.vipCheckoutService.generateVIPCheckout(this.mobileNumberJson()).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    window.location.href = response.response_data.payment_url;
                      
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_data,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
          // user clicked No
        }
      });
  }
}

  mobileNumberJson(){
     return {
      customer: {
        first_name: "Abel",
        last_name: "Maclead",
        billing_address: {
          line1: "#88",
          line2: "Boston Ave",
          city_municipality: "Philadelphia",
          zip: "19132",
          state_province_region: "PA",
          country_code: "US"
        },
        shipping_address: {
          line1: "#88",
          line2: "Boston Ave",
          city_municipality: "Philadelphia",
          zip: "19132",
          state_province_region: "PA",
          country_code: "US"
        },
        contact: {
          email: "amaclead@domain.com",
          mobile: "+639175000011"
        }
      },
      payment: {
        description: "Payment for a product",
        amount: 10.5,
        currency: "PHP",
        option: this.checkoutForm.get('bank_code')!.value,
        other_references: [
          "REF1",
          "REF2"
        ]
      },
      route: {
        callback_url: this.thankYouUrl,
        notify_user: true
      },
      time_offset: "+08:00"
    };
  }

}
