

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav [mode]="isLargeScreen ? 'side' : 'over'" class="sidenav"  [opened]="isLargeScreen">
    <br/>
    
    <mat-toolbar >
      <img src="https://govipcenterstorage.blob.core.windows.net/gocashflex/go_app.png" alt="logo" class="logo">
    
    </mat-toolbar>
 
    <mat-nav-list>

    <mat-accordion>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                  Main Navigation
                </mat-panel-title>
            </mat-expansion-panel-header>
    
            <a *ngIf="!isProd" mat-list-item routerLink="/dashboard/home">
                <mat-icon>home</mat-icon>
                Home
            </a>
            <a mat-list-item routerLink="/dashboard/outlets">
              <mat-icon>store</mat-icon>
              Outlets
          </a>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                Collection
              </mat-panel-title>
          </mat-expansion-panel-header>
  
          <a *ngIf="!isProd" mat-list-item routerLink="/dashboard/simulate_cash_in">
            <mat-icon>format_align_justify</mat-icon>
             Cash Ins Simulator
          </a>
          <a mat-list-item routerLink="/dashboard/registered_cash_in">
            <mat-icon>receipt</mat-icon>
            Registered Cash Ins
          </a>
          <a mat-list-item routerLink="/dashboard/cash_ins">
            <mat-icon>money</mat-icon>
            Cash Ins
          </a>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
              Disbursement
            </mat-panel-title>
        </mat-expansion-panel-header>

        <a mat-list-item routerLink="/dashboard/cash_outs">
          <mat-icon>confirmation_number</mat-icon>
          Cash Outs
        </a>
        <a mat-list-item routerLink="/dashboard/cash_out_form">
          <mat-icon>local_library</mat-icon>
          Cash Out Form
        </a>
    </mat-expansion-panel>
    

    <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
            Payment Direct
          </mat-panel-title>
      </mat-expansion-panel-header>

      <a mat-list-item routerLink="/dashboard/acquire-direct-payment">
        <mat-icon>redeem</mat-icon>
        Acquire Direct Payment
      </a>

      <a mat-list-item routerLink="/dashboard/acquired-direct-payments">
        <mat-icon>subject</mat-icon>
        Acquired Direct Payments
      </a>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
          Payment Checkout
        </mat-panel-title>
    </mat-expansion-panel-header>

    

    <a mat-list-item routerLink="/dashboard/request_checkout">
      <mat-icon>redeem</mat-icon>
      Checkout (Channel 1)
    </a>

    <a mat-list-item routerLink="/dashboard/request_checkout_ch2">
      <mat-icon>redeem</mat-icon>
      Checkout (Channel 2)
    </a>

    <a mat-list-item routerLink="/dashboard/checkouts">
      <mat-icon>subject</mat-icon>
      Checkout List
    </a>
</mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
            Payment Gateway
          </mat-panel-title>
      </mat-expansion-panel-header>

      <a mat-list-item routerLink="/dashboard/request_payment">
        <mat-icon>redeem</mat-icon>
        Request Payment
      </a>

      <a mat-list-item routerLink="/dashboard/vip_pays">
        <mat-icon>subject</mat-icon>
        Payment List
      </a>
  </mat-expansion-panel>

  
  <mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
          Bank & Ledgers
        </mat-panel-title>
    </mat-expansion-panel-header>

    <a mat-list-item routerLink="/dashboard/banks">
      <mat-icon>account_balance</mat-icon>
      Banks
    </a>
    <a mat-list-item routerLink="/dashboard/outlet_ledgers">
      <mat-icon>account_balance_wallet</mat-icon>
      Outlet Ledgers
    </a>
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
      <mat-panel-title>
        DigiDine
      </mat-panel-title>
  </mat-expansion-panel-header>
  <a mat-list-item *ngFor="let item of outletDetails; let i = index" [routerLink]="['/dashboard/digi-dine', item.outlet_hash_id]">
    <mat-icon>fastfood</mat-icon>
      &nbsp;Store {{i+1}}
  </a>
 
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
      <mat-panel-title>
        Settings
      </mat-panel-title>
  </mat-expansion-panel-header>

  <a mat-list-item routerLink="/login">
    <mat-icon>logout</mat-icon>
    Logout
  </a>
</mat-expansion-panel>

    </mat-accordion>

    </mat-nav-list>
  </mat-sidenav>
  
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h2 class="title">GoAPPCenter Version 2.0</h2>
    </mat-toolbar>
    <app-breadcrumb class="header-class"></app-breadcrumb>
    <router-outlet ></router-outlet>
  </mat-sidenav-content>
  
</mat-sidenav-container> 


