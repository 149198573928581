<div *ngIf="!emptySession && checkOrderPaymentStatus(orderSession)">
  <mat-drawer-container class="example-container" autosize style="padding-bottom: 100px !important;">
    <!-- <mat-drawer #drawer mode="over" class="example-sidenav" opened="false" style="padding-bottom: 100px !important;">

    </mat-drawer> -->


    <mat-drawer-content>
      <!-- Header Container -->
      <div *ngFor="let category of menuList">
        <div class="header-container">
          <h1 class="header-title">{{category.name}}</h1>
        </div>

        <!-- Product Cards Container -->
        <div class="cards-container">
          
          <mat-card class="product-card" *ngFor="let product of filterProducts(category.products, true)">
            <img class="product-image cover-image" mat-card-image [src]="product.image" alt="Product image">
            <mat-card-content>
              <mat-card-title>{{product.name}} </mat-card-title>
            </mat-card-content>
            <mat-card-actions class="fixed-button">
              <mat-card-content>
                <mat-card-subtitle class="subtitle-amount">₱ {{product.price | number:'1.2-2' }}</mat-card-subtitle>
              </mat-card-content>
              <button mat-raised-button color="warn" (click)="openAddOrderDialog(product)">View Details</button>
            </mat-card-actions>
            <!-- Add this div -->
            
          </mat-card>

          <mat-card class="product-card" *ngFor="let product of filterProducts(category.products, false)">
            <img class="product-image cover-image" mat-card-image [src]="product.image" alt="Product image">
            <mat-card-content>
              <mat-card-title>{{product.name}} </mat-card-title>
            </mat-card-content>
            <mat-card-actions class="fixed-button">
              <mat-card-content>
                <mat-card-subtitle class="subtitle-amount">₱ {{product.price | number:'1.2-2' }}</mat-card-subtitle>
              </mat-card-content>
              <button mat-raised-button color="warn" (click)="openAddOrderDialog(product)">View Details</button>
            </mat-card-actions>
            <!-- Add this div -->
            <div  class="overlay" >
              <strong  style="color:white ; background-color: #c4555e;opacity:1.0 !important; padding: 10px; margin-top: -160px;
              ">NOT AVAILABLE</strong>
            </div>
          </mat-card>

        </div>

      </div>

    </mat-drawer-content>


  </mat-drawer-container>

<div *ngIf="orderSession">
  <div *ngIf="orderSession.discount_amount" type="button" class="floating-button-left">
    <mat-card-subtitle class="subtitle-amount-bill">Bill : ₱ {{totalSum-orderSession.discount_amount | number:'1.2-2'}}</mat-card-subtitle>
  </div>
  <div *ngIf="!orderSession.discount_amount" type="button" class="floating-button-left">
    <mat-card-subtitle class="subtitle-amount-bill">Bill : ₱ {{totalSum | number:'1.2-2'}}</mat-card-subtitle>
  </div>
</div>
 


  <!-- <button *ngIf="drawer.opened" type="button" mat-icon-button class="floating-button-out">
    <mat-icon class="material-icons-outlined">receipt</mat-icon>
  </button> -->

  <button type="button" mat-icon-button (click)="showBillDetails()" class="floating-button">
    <mat-icon class="material-icons-outlined">shopping_basket
    </mat-icon>
    <!--  *ngIf="!drawer.opened"<mat-icon *ngIf="drawer.opened" class="material-icons-outlined">close</mat-icon> -->
  </button>
</div>

<div *ngIf="emptySession">
  <div
    style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh; text-align: center;">
    <mat-icon class="material-icons-outlined custom-icon">fastfood</mat-icon>
    <br />
    <h2 style=" color: #283593;">Kindly hold on for just a moment. We're activating your menu for Table Number
      {{tableNumber}}. Your patience is greatly appreciated.</h2>
    <button type="button" mat-icon-button class="floating-button" (click)="refreshPage()">
      <mat-icon class="material-icons-outlined">arrow_forward</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!checkOrderPaymentStatus(orderSession)">
  <div
    style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh; text-align: center;">
    <mat-icon class="material-icons-outlined custom-icon">check_circle_outline</mat-icon>
    <br />
    <h2 style=" color: #283593;">Payment has been successfully processed. Please kindly await your receipt, which will be issued to you shortly by our server. We appreciate your patience.<br/>Thank you and see you soon!</h2>
   
  </div>
</div>