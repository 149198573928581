import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DocumentReference, Firestore, doc, setDoc } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { DigiDineService } from 'src/app/services/digi-dine.service';

@Component({
  selector: 'app-add-discount-dialog',
  templateUrl: './add-discount-dialog.component.html',
  styleUrls: ['./add-discount-dialog.component.less']
})
export class AddDiscountDialogComponent implements OnInit {

  isLoading = false;
  billOutForm = this.fb.group({
    total_amount: ['', Validators.required],
    discount_amount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
  },{ validator: this.discountAmountValidator });
  outletHashId : any;
  id : any;
  totalAmount : any;
  discountAmount : any;
  tableData : any;
  orderId : any;
  constructor(@Inject(MAT_DIALOG_DATA) public data  : { outlet_hash_id: any, order_id: any,total_amount:any,discount_amount:any }
  ,private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService,
  public dialogSelf: MatDialogRef<AddDiscountDialogComponent>,private firestore: Firestore) { 
    this.outletHashId= data;
    this.outletHashId= data.outlet_hash_id;
    this.totalAmount =data.total_amount;
    this.orderId =data.order_id;
    this.discountAmount =data.discount_amount;

    this.billOutForm.patchValue({
      total_amount:data.total_amount,
      discount_amount:data.discount_amount
    });
    
  }

  discountAmountValidator(control: AbstractControl) : {[key: string]: any} | null {
    const total_amount = control.get('total_amount')!.value;
    const discount_amount = control.get('discount_amount')!.value;
  
    if (discount_amount > total_amount) {
      return { 'discountGreaterThanTotal': true };
    }
  
    return null;
  }
  ngOnInit(): void {
    
  }

  onSubmit(): void {
    if (this.billOutForm.valid) {
      
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to add discount to this table?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 

          var docOrder: DocumentReference = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${this.orderId}`);
          setDoc(docOrder, {
            discount_amount : this.billOutForm.get('discount_amount')!.value
          }, {merge:true}).then(() => {
            this.isLoading = true;
            // user clicked Yes, make the API call
            this.digiDineService.updateGenerateVIPPay(this.outletHashId, this.orderId).subscribe(
                    response => {
                      // handle success case
                      this.isLoading = false;
                     
                        this.dialog.open(ErrorDialogComponent, {
                          data: {
                            content: "Discount applied successfully!",
                            header: "Transaction successful"
                          }
                        });
                        this.dialogSelf.close(true);
                       
                     
                    },
                    error => {
                      this.isLoading = false;
                        this.dialog.open(ErrorDialogComponent, {
                          data: {
                            content: "Discount applied successfully!",
                            header: "Transaction successful"
                          }
                        });
                        this.dialogSelf.close(true);
                    }
                  );
            console.log("Document successfully written!");
          }).catch((error) => {
            console.error("Error writing document: ", error);
          });


         
        } else {
        }
      });

     
  }
}



}
