import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { DigiDineService } from 'src/app/services/digi-dine.service';

@Component({
  selector: 'app-bill-out-dialog',
  templateUrl: './bill-out-dialog.component.html',
  styleUrls: ['./bill-out-dialog.component.less']
})
export class BillOutDialogComponent implements OnInit {

  isLoading = false;
  billOutForm = this.fb.group({
    order_id: ['', Validators.required], 
    payment_mode: ['', Validators.required], 
    reference_number: ['', Validators.required], 
    total_amount: ['', Validators.required], 
    discount_amount: ['', Validators.required], 
    grand_total_amount: ['', Validators.required]
  });
  outletHashId : any;
  id : any;
  totalAmount : any;
  tableData : any;
  discountAmount : any;
  constructor(@Inject(MAT_DIALOG_DATA) public data  : { outlet_hash_id: any, order_id: any,total_amount:any,table_data:any,discount_amount:any }
  ,private fb: FormBuilder,public dialog: MatDialog,private digiDineService : DigiDineService,
  public dialogSelf: MatDialogRef<BillOutDialogComponent>,) { 
    this.outletHashId= data;
    this.outletHashId= data.outlet_hash_id;
    this.totalAmount = data.total_amount ;
    this.tableData = data.table_data;
    this.discountAmount = data.discount_amount;
    if(data.table_data.payment_status == 'PAID'){
      this.billOutForm.patchValue({
        order_id: data.order_id,
        total_amount:data.total_amount- data.discount_amount,
        discount_amount:data.discount_amount,
        grand_total_amount:data.total_amount,
        payment_mode : data.table_data.payment_type,
        reference_number : data.table_data.generated_account_number
      });
    }else{
      this.billOutForm.patchValue({
        order_id: data.order_id,
        total_amount:data.total_amount- data.discount_amount,
        discount_amount:data.discount_amount,
        grand_total_amount:data.total_amount,
      });
    }
    


    
  }

  onPaymentModeChange(event: MatSelectChange) {
    const selectedPaymentMode = event.value;
  if(selectedPaymentMode == 'CASH'){
    this.billOutForm.patchValue({
      reference_number: 'NOT APPLICABLE'
    });
  }else{
    this.billOutForm.patchValue({
      reference_number: ''
    });
  }
  }
  

  
 paymentModeList: { id: string, name: string }[] = [
    {id: "GOCASHFLEX", name: "GOCASHFLEX"},
    {id: "CASH", name: "CASH"},
    {id: "OTHERS", name: "OTHERS"},
  ];
  
  ngOnInit(): void {
    
  }

  onSubmit(): void {
    if (this.billOutForm.valid) {
      const data = {
        outlet_hash_id: this.outletHashId,
        ...this.billOutForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to bill out this table?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.digiDineService.addOrderHistory(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    if(response.response_result === "0001") {
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: "Bill out complete!",
                          header: "Transaction successful"
                        }
                      });
                     
                      this.dialogSelf.close(true);
                    } else if(response.response_result === "0002") {
                      this.isLoading = false;
                      this.dialog.open(ErrorDialogComponent, {
                        data: {
                          content: response.response_description,
                          header: "Transaction error"
                        }
                      });
                      // handle failure case
                    }
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: error.error.response_description,
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
        }
      });

     
  }
}



}
