

<table mat-table matSort matTableResponsive [dataSource]="dataSource" >


    <ng-container matColumnDef="total_amount">
        <th mat-header-cell *matHeaderCellDef> Amount Paid </th>
        <td mat-cell *matCellDef="let element"> {{element.total_amount | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="discount_amount">
        <th mat-header-cell *matHeaderCellDef> Discount Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.discount_amount | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="grand_total_amount">
        <th mat-header-cell *matHeaderCellDef> Total Bill </th>
        <td mat-cell *matCellDef="let element"> {{element.grand_total_amount | number:'1.2-2'}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef> Order ID </th>
        <td mat-cell *matCellDef="let element"> {{element.order_id}} </td>
    </ng-container>

    <ng-container matColumnDef="payment_mode">
        <th mat-header-cell *matHeaderCellDef> Payment Method </th>
        <td mat-cell *matCellDef="let element"> {{element.payment_mode}} </td>
    </ng-container>

    <ng-container matColumnDef="reference_number">
        <th mat-header-cell *matHeaderCellDef> Payment Reference Number </th>
        <td mat-cell *matCellDef="let element"> {{element.reference_number}} </td>
    </ng-container>
     
         <!--- Define columns -->
     <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

     


  <!--- Define columns -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
    <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary"  (click)="openOrderDetailsDialog(element.order_id)"   class="button-spacing">View Details</button> </td>
  </ng-container>
  <!---->

    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      