import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { PaymentInstructionsComponent } from 'src/app/components/payment-instructions/payment-instructions.component';
import { CashInService } from 'src/app/services/cash-in.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-payment',
  templateUrl: './request-payment.component.html',
  styleUrls: ['./request-payment.component.less']
})
export class RequestPaymentComponent implements OnInit {

  public isProd = environment.production;
  isLoading = false;
  companyCode! : string;
  requestVIPPayForm = this.fb.group({
    partner_reference_id: ['', [Validators.required, Validators.pattern('\\w{7}\\d{8}[A-Z0-9]{5}')]],
    amount: [ this.isProd ? '' :this.generateAmount(), [Validators.required, Validators.pattern(/^\d+\.\d{2}$/)]]
  });

  constructor(private fb: FormBuilder,  
    private cashInService: CashInService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.cashInService.getCompanyCode().subscribe(
      response => {
        this.companyCode = response.response_data;
        this.requestVIPPayForm.patchValue({
          partner_reference_id: this.generatePartnerReferenceNumber()
        });
      });
  }

  generatePartnerReferenceNumber(): string {
    const currentDate = new Date();
    const yyyy = currentDate.getFullYear();
    let mm = String(currentDate.getMonth() + 1); // Months are zero based
    let dd = String(currentDate.getDate());
  
    // Pad with zero if necessary
    mm = mm.length === 1 ? '0' + mm : mm;
    dd = dd.length === 1 ? '0' + dd : dd;
  
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomChars = '';
    
    for(let i = 0; i < 5; i++) {
      randomChars += possibleChars[Math.floor(Math.random() * possibleChars.length)];
    }
    
    return this.companyCode + yyyy + mm + dd + randomChars;// This generates an 8 digit number.
  }

  generateAmount(): string {
    let amount = Math.floor(10000 + Math.random() * 90000);
    return (amount / 100).toFixed(2); // toFixed returns a string.
  }

  onSubmit(): void {
    if (this.requestVIPPayForm.valid) {
      const data = {
        ...this.requestVIPPayForm.value,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to request a payment?"
    
      dialogRef.afterClosed().subscribe(result => {
        if(result) { 
          this.isLoading = true;
          // user clicked Yes, make the API call
          this.cashInService.requestVIPPay(data).subscribe(
                  response => {
                    // handle success case
                    this.isLoading = false;
                    
                      //Show payment instructions
                      this.dialog.open(PaymentInstructionsComponent, {
                        data: response
                      });
                      //Show payment instructions


                      // reset the form
                      this.ngOnInit();
                      this.requestVIPPayForm.reset();
                      this.requestVIPPayForm.markAsUntouched();
                      this.requestVIPPayForm.markAsPristine();

                      this.requestVIPPayForm.patchValue({
                        transaction_id: this.generatePartnerReferenceNumber()
                      });
                      if(!this.isProd){
                        this.requestVIPPayForm.patchValue({
                          amount: this.generateAmount()
                        });
                      }
                      
                  },
                  error => {
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        content: "Please ensure that your partner reference number is 20 characters long and begins with the company code provided to you.",
                        header: "Transaction error"
                      }
                    });
                  }
                );
        } else {
          // user clicked No
        }
      });
  }



}

}
