import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCategoryDialogComponent } from '../../components/add-category-dialog/add-category-dialog.component';
import { EditCategoryDialogComponent } from '../../components/edit-category-dialog/edit-category-dialog.component';
import { OrderDetailsDialogComponent } from '../../components/order-details-dialog/order-details-dialog.component';


@Component({
  selector: 'app-digi-dine-order-history',
  templateUrl: './digi-dine-order-history.component.html',
  styleUrls: ['./digi-dine-order-history.component.less']
})
export class DigiDineOrderHistoryComponent implements OnInit {

  private paramSubscription: Subscription | undefined;
  constructor(private route: ActivatedRoute,private digiDineService : DigiDineService,
    public dialog: MatDialog) { }
  outletHashId : string ="";

  
  displayedColumns: string[] = ['order_id', 'payment_mode', 'reference_number', 'total_amount','discount_amount','grand_total_amount','created_date','action' ]; 
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.outletHashId = params['param1']; 
      this.fetchData(this.currentPage, this.pageSize);
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }


  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.digiDineService.getOrderHistory(page, pageSize,this.outletHashId).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }

  openOrderDetailsDialog(orderId : any): void {
    const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
      width: '500px',
      // Pass any data you need to the dialog here
      data:{
        outlet_hash_id : this.outletHashId,
        order_id : orderId
      } 
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
     
      console.log('The dialog was closed');
    });
  }
  
}
