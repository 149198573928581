import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { OutletService } from 'src/app/services/outlet.service';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  isLargeScreen = false;
  public isProd = environment.production;
  outletDetails : any;

  constructor(private breakpointObserver: BreakpointObserver,private router: Router,
    private outletService: OutletService, private cdr: ChangeDetectorRef) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isLargeScreen = false;
      } else {
        this.isLargeScreen = true;
      }
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => !this.isLargeScreen)
    ).subscribe(() => this.sidenav.close());
  }


  ngOnInit(): void {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('/dashboard/digi-dine/active-orders') ) {
          this.sidenav.close();
          this.cdr.detectChanges();
        } else {
          this.sidenav.open();
          this.cdr.detectChanges();
        }
      }
    });


    this.outletService.getOutletDetails().subscribe(
      response => {
        this.outletDetails = response.response_data;
      },
      error => {
        console.error(error);
      }
    );
  }

  

}
