 
 <div class="spinner-container" *ngIf="isLoadingSummary">
    <mat-progress-spinner class="spinner" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="details">
  <div class="row allwallet">
    <span class="label"><h1><strong>Outlets Wallet Grand Total</strong></h1></span>
    <span class="value"><h1><strong>PHP {{outletsWalletVal | number:'1.2-2' }}<br/><span class="value" style="font-size: large;">as of {{ currentDateTime | date:'medium' }}</span></strong></h1></span>
  </div>
</div>
 <mat-grid-list cols="4" rowHeight="630px">
    <mat-grid-tile  colspan="2" rowspan="1">
        <div class="scrollable-content">

        

       

        <div class="search-container" >
            <mat-form-field appearance="fill">
                <mat-label>Search Username</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search Username">
            </mat-form-field>
           
                    
               
        </div>

      
            

        <table mat-table matSort matTableResponsive [dataSource]="dataSource"  >
    <!--- Define columns -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> Username </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="combined_wallet">
        <th mat-header-cell *matHeaderCellDef> Combined Wallet </th>
        <td mat-cell *matCellDef="let element"> {{element.combined_wallet| number:'1.2-2'}} </td>
      </ng-container>

 <!--- Define columns -->
        <ng-container matColumnDef="last_transaction_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Last Transaction Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.last_transaction_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>
      
        <!--- Define columns -->
        <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>

       <!--- Define columns -->
       <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
        <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openDialog(element)" class="button-spacing">Details</button> </td>
      </ng-container>



    <!-- Other columns go here in the same way -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>



    
</mat-grid-tile>



<!--- MANAGER DETAILS -->



            <mat-grid-tile *ngIf="managerDetails"  colspan="2" rowspan="1">
                <div class="scrollable-content">
                    <div class="card">
                        <h2 class="title">Cashiers</h2>
                        <div class="details">
                            <table mat-table matSort matTableResponsive [dataSource]="managerDetails.cashier_details"  >
                                <!--- Define columns -->
                               
                                
                                <ng-container matColumnDef="user_id">
                                    <th mat-header-cell *matHeaderCellDef> ID </th>
                                    <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
                                  </ng-container>
                               
                                <ng-container matColumnDef="first_name">
                                  <th mat-header-cell *matHeaderCellDef> First Name </th>
                                  <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="last_name">
                                    <th mat-header-cell *matHeaderCellDef> Last Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="contact_number">
                                    <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                                    <td mat-cell *matCellDef="let element"> {{element.contact_number}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="wallet">
                                    <th mat-header-cell *matHeaderCellDef> Wallet </th>
                                    <td mat-cell *matCellDef="let element"> {{element.wallet| number:'1.2-2'}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="last_transaction_date">
                                    <th mat-header-cell *matHeaderCellDef class="right-align"> Last Transaction Date </th>
                                    <td mat-cell *matCellDef="let element" class="right-align"> {{element.last_transaction_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
                                    </ng-container>
                            
                                   <!--- Define columns -->
                                   <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
                                    <td mat-cell *matCellDef="let element" class="right-align"> 
                                        <button mat-raised-button color="primary" (click)="openDialogTransaction(element.user_id)" class="button-spacing">Summary</button>
                                        &nbsp;
                                        <button mat-raised-button color="primary" (click)="openDialogLedger(element.user_id)" class="button-spacing">Ledger</button> 
                                    </td>
                                  </ng-container>
                            
                            
                            
                                <!-- Other columns go here in the same way -->
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsCashiers"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsCashiers;"></tr>
                                </table>
                        </div>
                      
                        
                        <br/>
                        <h2 class="title">Manager Details</h2>
                        <div class="details">
                            <div class="row">
                                <span class="label">Manager Ledger</span>
                                <span class="value">  <button mat-raised-button color="primary" (click)="openDialogTransaction(managerDetails.id)" class="button-spacing">Summary</button>
                                    &nbsp;
                                    <button mat-raised-button color="primary" (click)="openDialogLedger(managerDetails.id)" class="button-spacing">Ledger</button> </span>
                              
                            </div>
                            <div class="row">
                                <span class="label">Unified Ledger</span>
                                <span class="value"> 
                                    <button mat-raised-button color="primary" (click)="openDialogUnifiedLedger(managerDetails.id)" class="button-spacing">Unified Ledger</button> </span>
                              
                            </div>
                            
                              <div class="row">
                                <span class="label">Cashiers Wallet</span>
                                <span class="value">{{managerDetails.cashiers_wallet |number:'1.2-2'}}</span>
                              </div>
                            <div class="row">
                                <span class="label">Manager's Wallet</span>
                                <span class="value">{{managerDetails.manager_wallet |number:'1.2-2'}}</span>
                              </div>

                              <div class="row">
                                <span class="label">Combined Wallet</span>
                                <span class="value"><strong>{{managerDetails.combined_wallet |number:'1.2-2'}}</strong></span>
                              </div>
                              
                            <div class="row">
                                <span class="label">Username</span>
                                <span class="value">{{managerDetails.username}}</span>
                              </div>
                              <div class="row">
                                <span class="label">Email</span>
                                <span class="value">{{managerDetails.email}}</span>
                              </div>
                          <div class="row">
                            <span class="label">First Name</span>
                            <span class="value">{{managerDetails.manager_details[0].first_name}}</span>
                          </div>
                          <div class="row">
                            <span class="label">Last Name</span>
                            <span class="value">{{managerDetails.manager_details[0].last_name}}</span>
                          </div>
                          <div class="row">
                            <span class="label">Contact Number</span>
                            <span class="value">{{managerDetails.manager_details[0].contact_number}}</span>
                          </div>
                          <div class="row">
                            <span class="label">Address</span>
                            <span class="value">{{managerDetails.manager_details[0].address}}</span>
                          </div>
                          <!-- <div class="row">
                            <span class="label">Birth Date</span>
                            <span class="value">{{managerDetails.manager_details[0].birth_date}}</span>
                          </div>
                          <div class="row">
                            <span class="label">Mobile Activation Date</span>
                            <span class="value">{{managerDetails.manager_details[0].mobile_activation_date| date:'yyyy-MM-dd hh:mm aa'}}</span>
                          </div>
                          <div class="row">
                            <span class="label">Email Activation Date</span>
                            <span class="value">{{managerDetails.manager_details[0].email_activation_date| date:'yyyy-MM-dd hh:mm aa'}}</span>
                          </div> -->
                        </div>
                      
                       </div>
            </div>


          

            </mat-grid-tile>

            
<!--- MANAGER DETAILS -->

  </mat-grid-list>

  <mat-grid-list cols="4" rowHeight="50px">
    <mat-grid-tile  colspan="2" rowspan="1">
  <div class="paginator-container">
    <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
    <mat-paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</mat-grid-tile>


</mat-grid-list>

