<div class="mat-elevation-z8">

    <h3 style="margin-left: 15px;">Amount for Settlement : <strong>PHP {{forSettlement | number:'1.2-2'}}</strong>
    <br/> Count for Settlement : <strong>{{forSettlementCount }}</strong> </h3>
    <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
      <!--- Define columns -->
      <ng-container matColumnDef="partner_reference_no">
        <th mat-header-cell *matHeaderCellDef> Partner Reference No. </th>
        <td mat-cell *matCellDef="let element"> {{element.partner_reference_no}} </td>
      </ng-container>
  
       <!--- Define columns -->
       <ng-container matColumnDef="transaction_id">
          <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
          <td mat-cell *matCellDef="let element"> {{element.transaction_id}} </td>
      </ng-container>
  
        <!--- Define columns -->
        <ng-container matColumnDef="amount_currency">
          <th mat-header-cell *matHeaderCellDef> Currency </th>
          <td mat-cell *matCellDef="let element"> {{element.amount_currency}} </td>
        </ng-container>
      
      <!--- Define columns -->
      <ng-container matColumnDef="requested_amount">
          <th mat-header-cell *matHeaderCellDef > Requested Amount </th>
          <td mat-cell *matCellDef="let element" > {{element.requested_amount | number:'1.2-2'}} </td>
          </ng-container>
  
          <!--- Define columns -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef > Status </th>
            <td mat-cell *matCellDef="let element" > <strong>{{element.status}} </strong> </td>
            </ng-container>

              <!--- Define columns -->
          <ng-container matColumnDef="transaction_state">
            <th mat-header-cell *matHeaderCellDef > Transaction State </th>
            <td mat-cell *matCellDef="let element" ><strong>{{element.transaction_state}} </strong> </td>
            </ng-container>
  
  
           <!--- Define columns -->
       <ng-container matColumnDef="requested_date">
          <th mat-header-cell *matHeaderCellDef > Requested Date </th>
          <td mat-cell *matCellDef="let element" > {{element.requested_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>
  
  
        <!-- qr_code_base64 -->
            
                        <!--- Define columns -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
          <td mat-cell *matCellDef="let element" class="right-align"> 
            <!-- <button *ngIf="element.cash_in_details.length !== 0"   mat-raised-button color="primary" (click)="openDialog(element.cash_in_details[0])" class="button-spacing">Payment Details</button>  -->
            <button *ngIf="element.status != 'OK.00.00' && !element.status.startsWith('ER')"   mat-raised-button color="primary" (click)="syncStatus(element)"   class="button-spacing">Sync Status</button>
          </td>
        </ng-container>
  
  
      <!-- Other columns go here in the same way -->
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <div class="paginator-container">
          <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
          <mat-paginator [length]="totalItems"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         (page)="onPageChange($event)">
          </mat-paginator>
        </div>
        
        
  </div>