

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-app-payment-instructions-dialog',
  templateUrl: './app-payment-instructions-dialog.component.html',
  styleUrls: ['./app-payment-instructions-dialog.component.less']
})
export class AppPaymentInstructionsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }


  qrValue(accountNumber : string, amount : any){
    return `GOCASHFLEX>>>>>${accountNumber}>>>>>${amount}`;
  }
  
imageData(base64Str : string){
return 'data:image/jpeg;base64,'+base64Str;

}

}
