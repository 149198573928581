import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OutletService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getOutletDetails(): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 'Authorization': 'Bearer ' + token };

    return this.http.get(`${this.baseUrl}outlet_details`, { headers });
  }

  getLedgerData(outletHashId: string,page: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };

    var data = {
      'outlet_hash_id' : outletHashId
    }

  return this.http.post(`${this.baseUrl}outlet_ledger?page_number=${page}&page_size=${pageSize}`, data, { headers });
}
}
