import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}
  private baseUrl = environment.apiUrl;

  login(clientId: string, clientPassphrase: string, companyName: string): Observable<any> {
    const body = new HttpParams()
      .set('client_id', clientId)
      .set('client_secret', clientPassphrase)
      .set('company_name', companyName)
      .set('grant_type', 'authorization_code');

    return this.http.post<any>(`${this.baseUrl}oauth/token`, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    }).pipe(
      tap(response => {
        if (response && response.response_data && response.response_data.access_token) {
          localStorage.setItem('accessToken', response.response_data.access_token);
        }
      })
    );
  }
}
