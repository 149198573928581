import { Component, Inject, OnInit } from '@angular/core';
import { CollectionReference, DocumentReference, Firestore, collection, collectionSnapshots, doc, docSnapshots, orderBy, query } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.less']
})
export class OrderDetailsDialogComponent implements OnInit {
  orderId:any;
  outletHashId:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data  : {outlet_hash_id:any, order_id: any},private firestore: Firestore) { 
    this.outletHashId= data.outlet_hash_id;
    this.orderId= data.order_id;
  }

  ngOnInit(): void {
    this.viewOrders();
  }

  orders :any;
  col: CollectionReference | undefined;
  totalSum:any = 0;
  docRefMonitor: DocumentReference | undefined;
  orderSession:any;
  viewOrders(){

    this.docRefMonitor = doc(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${this.orderId}`);
    docSnapshots(this.docRefMonitor).subscribe(snapCollection => {
        this.orderSession = snapCollection.data();
    });

    this.col = collection(this.firestore, `/DigiDinePublic/${this.outletHashId}/ActiveOrders/${this.orderId}/Orders`);
    const q = query(this.col, orderBy("created_date", "asc"));  // Add this line
      collectionSnapshots(q).subscribe(snapCollection => {
        this.orders = snapCollection
        this.totalSum= 0;
        snapCollection.forEach(doc => {
          const documentData : any = doc.data();
          this.totalSum += (documentData.price * documentData.quantity ) ;
        });
      });

  }

}
