import { Component,  Inject,  Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-user-transaction-summary',
  templateUrl: './user-transaction-summary.component.html',
  styleUrls: ['./user-transaction-summary.component.less']
})

export class UserTransactionSummaryComponent {
  displayedColumns: string[] = [ 'transactionType', 'transactionName', 'transactionCount', 
  'actualTransactionCount', 'totalAmount', 'actualTransactionAmount', 'discrepancyCount', 'discrepancyAmount', 
  'upfrontRebate']
  constructor(
    public dialogRef: MatDialogRef<UserTransactionSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { details: any }) { }
}
