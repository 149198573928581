import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicTableDialogComponent } from 'src/app/components/dynamic-table-dialog/dynamic-table-dialog.component';
import { AuditOutletsService } from 'src/app/services/audit-outlets.service';
import { CashInService } from 'src/app/services/cash-in.service';

@Component({
  selector: 'app-unified-transaction-ledger',
  templateUrl: './unified-transaction-ledger.component.html',
  styleUrls: ['./unified-transaction-ledger.component.less']
})
export class UnifiedTransactionLedgerComponent implements OnInit {
  @Input() userId: any;
  displayedColumns: string[] = ['username','transactionType','referenceNumber','transactionName','requestId','statusId','createdDate','completedDate', 'amount','runningTotal']; //, 'action'

  displayedColumnsWallets: string[] = ['username','role','wallet','wallet_adjustment']; //, 'action'
  dataSourceWallets = new MatTableDataSource([]);

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 100;
  totalItems!: number;
  currentPage: number = 1;
  username! : String;
  totalWallet! : number;
  totalWalletAdjustment! : number;

  constructor(private auditOutletsService: AuditOutletsService,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.pageSize);
  }
  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.auditOutletsService.getUnifiedLedger(this.userId,page, pageSize).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.dataSourceWallets.data = response.response_data.wallets;
      this.totalWallet = response.response_data.total_wallet;
      this.totalWalletAdjustment = response.response_data.total_wallet_adjustment;
      this.totalItems = response.response_data.total_count;
      this.username = response.response_data.username;
      this.isLoadingResults = false;  // Spinner stops after API call
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }
  

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }

 

  openDialog(details : string){
    var jsonData = JSON.parse(details);
    this.openDynamicTableDialog(jsonData);
  }

  openDynamicTableDialog(data: any) {
    this.dialog.open(DynamicTableDialogComponent, {
      data,
      width: '100%',
    });
  }
}
