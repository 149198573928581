
<!-- <h3>Username : {{username}} <br/> Wallet Balance : {{actualBalance| number:'1.2-2'}}</h3> -->
<table class="table-header left" mat-table matSort matTableResponsive [dataSource]="dataSourceWallets" >

    <!--- Define columns -->
<ng-container matColumnDef="username">
  <th mat-header-cell *matHeaderCellDef> Username </th>
  <td mat-cell *matCellDef="let element"> {{element.username}} </td>
</ng-container>

<!--- Define columns -->
<ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role </th>
    <td mat-cell *matCellDef="let element"> {{element.role}} </td>
  </ng-container>


<!--- Define columns -->
<ng-container matColumnDef="wallet">
    <th mat-header-cell *matHeaderCellDef> Wallet Balance </th>
    <td mat-cell *matCellDef="let element"> {{element.wallet| number:'1.2-2'}} </td>
  </ng-container>
  

 <!--- Define columns -->
 <ng-container matColumnDef="wallet_adjustment">
    <th mat-header-cell *matHeaderCellDef class="right-align"> Wallet Adjustment </th>
    <td mat-cell *matCellDef="let element" class="right-align"> {{element.wallet_adjustment| number:'1.2-2'}} </td>
</ng-container>

<!-- Other columns go here in the same way -->

<tr mat-header-row *matHeaderRowDef="displayedColumnsWallets"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumnsWallets;"></tr>
</table>

<div class="table-header right"  >
    <!--- Define columns -->
    <div class="details">
        <h2 class="title">Wallet Summary</h2>
    <div class="row">
        <span class="label">Unified Wallet</span>
        <span class="value"> {{totalWallet| number:'1.2-2'}} </span>
    </div>

    <div class="row">
        <span class="label">Unified Wallet Adjustment</span>
        <span class="value"> {{totalWalletAdjustment| number:'1.2-2'}} </span>
    </div>

    <!-- <div class="row">
        <span class="label">Expected Ending Ledger Running Balance</span>
        <span class="value"> {{totalWallet + totalWalletAdjustment| number:'1.2-2'}} </span>
    </div> -->
    
</div>
   
</div>

<div class="mat-elevation-z8">
   
    <table mat-table matSort matTableResponsive [dataSource]="dataSource" >

          <!--- Define columns -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> Username </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <!--- Define columns -->
      <ng-container matColumnDef="transactionType">
        <th mat-header-cell *matHeaderCellDef> Channel </th>
        <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
      </ng-container>
  
       <!--- Define columns -->
       <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="right-align"> Amount </th>
          <td mat-cell *matCellDef="let element" class="right-align"> {{element.amount| number:'1.2-2'}} </td>
      </ng-container>
      
      
       <!--- Define columns -->
       <ng-container matColumnDef="runningTotal">
          <th mat-header-cell *matHeaderCellDef class="right-align"> Running Total </th>
          <td mat-cell *matCellDef="let element" class="right-align"> {{element.runningTotal| number:'1.2-2'}} </td>
        </ng-container>

          <!--- Define columns -->
       <ng-container matColumnDef="referenceNumber">
        <th mat-header-cell *matHeaderCellDef> Reference Number </th>
        <td mat-cell *matCellDef="let element"> {{element.referenceNumber}} </td>
      </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="transactionName">
            <th mat-header-cell *matHeaderCellDef> Transaction Name </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionName}} </td>
          </ng-container>

            <!--- Define columns -->
       <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let element"> {{element.createdDate| date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="requestId">
            <th mat-header-cell *matHeaderCellDef> Request Id </th>
            <td mat-cell *matCellDef="let element"> {{element.requestId}} </td>
          </ng-container>

            <!--- Define columns -->
       <ng-container matColumnDef="completedDate">
        <th mat-header-cell *matHeaderCellDef> Completed Date </th>
        <td mat-cell *matCellDef="let element"> {{element.completedDate| date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="statusId">
            <th mat-header-cell *matHeaderCellDef> Status Id </th>
            <td mat-cell *matCellDef="let element"> {{element.statusId}} </td>
          </ng-container>
       

           <!--- Define columns -->
       <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
        <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openDialog(element.transactionDetails)" class="button-spacing">Details</button> </td>
      </ng-container>
  
      <!-- Other columns go here in the same way -->
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <div class="paginator-container">
          <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
          <mat-paginator [length]="totalItems"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         (page)="onPageChange($event)">
          </mat-paginator>
        </div>
        
        
  </div>