


<div class="table-header left"  >
    <!--- Define columns -->
    <div class="details">
        <h2 class="title">Wallet Summary</h2>
    <div class="row">
        <span class="label">Wallet</span>
        <span class="value"> {{actualBalance| number:'1.2-2'}} </span>
    </div>

    <div class="row">
        <span class="label">Adjusting Wallet Balance Based on Unbalanced Transactions</span>
        <span class="value"> {{walletAdjustment| number:'1.2-2'}} </span>
    </div>

    <!-- <div class="row">
        <span class="label">Expected Ending Ledger Running Balance</span>
        <span class="value"> {{totalWalletLedger| number:'1.2-2'}} </span>
    </div> -->
    
</div>
   
</div>

<div class="mat-elevation-z8">
   
    <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
      <!--- Define columns -->
      <ng-container matColumnDef="transactionType">
        <th mat-header-cell *matHeaderCellDef> Channel </th>
        <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
      </ng-container>
  
       <!--- Define columns -->
       <ng-container matColumnDef="openingBalance">
          <th mat-header-cell *matHeaderCellDef class="right-align"> Opening Balance </th>
          <td mat-cell *matCellDef="let element" class="right-align"> {{element.openingBalance| number:'1.2-2'}} </td>
      </ng-container>
      
        <!--- Define columns -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Amount </th>
            <td mat-cell *matCellDef="let element" class="right-align"> {{element.amount| number:'1.2-2'}} </td>
        </ng-container>
      
       <!--- Define columns -->
       <ng-container matColumnDef="runningTotal">
          <th mat-header-cell *matHeaderCellDef class="right-align"> Running Total </th>
          <td mat-cell *matCellDef="let element" class="right-align"> {{element.runningTotal| number:'1.2-2'}} </td>
        </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="discrepancy">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Discrepancy </th>
            <td mat-cell *matCellDef="let element" class="right-align failed"> {{computeDiscrepany(element.openingBalance ,element.runningTotal ,element.amount )| number:'1.2-2'}} </td>
        </ng-container>

          <!--- Define columns -->
       <ng-container matColumnDef="referenceNumber">
        <th mat-header-cell *matHeaderCellDef> Reference Number </th>
        <td mat-cell *matCellDef="let element"> {{element.referenceNumber}} </td>
      </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="transactionName">
            <th mat-header-cell *matHeaderCellDef> Transaction Name </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionName}} </td>
          </ng-container>

            <!--- Define columns -->
       <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let element"> {{element.createdDate| date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="requestId">
            <th mat-header-cell *matHeaderCellDef> Request Id </th>
            <td mat-cell *matCellDef="let element"> {{element.requestId}} </td>
          </ng-container>

            <!--- Define columns -->
       <ng-container matColumnDef="completedDate">
        <th mat-header-cell *matHeaderCellDef> Completed Date </th>
        <td mat-cell *matCellDef="let element"> {{element.completedDate| date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="statusId">
            <th mat-header-cell *matHeaderCellDef> Status Id </th>
            <td mat-cell *matCellDef="let element"> {{element.statusId}} </td>
          </ng-container>
       

           <!--- Define columns -->
       <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
        <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openDialog(element.transactionDetails)" class="button-spacing">Details</button> </td>
      </ng-container>
  
      <!-- Other columns go here in the same way -->
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
     

     
      <div class="paginator-container">
          <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
          <mat-paginator [length]="totalItems"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         (page)="onPageChange($event)">
          </mat-paginator>
         
        </div>
       
         
  </div>

 
  <div style="float: right;">
        <input type="number" min="1" [max]="maxPage" [(ngModel)]="currentPage">
        <button (click)="goToPage()">Go to Page</button>
      </div>