<mat-card class="login-card">

  <img width="200" src="https://govipcenterstorage.blob.core.windows.net/gocashflex/go_app.png" alt="logo" class="logo">
   
    <p *ngIf="!isProd" class="mat-body">This demo offers a detailed manual to the target audience on how GoAPPCenter works.</p>
  
    <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login-form">
      <mat-form-field appearance="fill">
        <mat-label>Client ID</mat-label>
        <input matInput id="clientId" type="password" formControlName="clientId">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Client Passphrase</mat-label>
        <input matInput id="clientPassphrase" type="password" formControlName="clientPassphrase">
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Company Name</mat-label>
        <input matInput id="companyName" type="text" formControlName="companyName">
      </mat-form-field>
  
      <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid || isLoading" class="login-button">
        <span *ngIf="!isLoading">Authorize Credentials</span>
        <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
      </button>
      
    </form>
  </mat-card>
  