<div class="container mat-dialog-content" *ngIf="data.type == 'CASH_OUT'">
    <div class="card">
      <h2 class="title">Transaction Details</h2>
      <div class="details">
        <div class="row">
          <span class="label">Partner Reference ID</span>
          <span class="value">{{data.details.partner_reference_id}}</span>
        </div>
        <div class="row">
          <span class="label">Transaction ID</span>
          <span class="value">{{data.details.transaction_id}}</span>
        </div>
        <div class="row">
          <span class="label">Account Name</span>
          <span class="value">{{data.details.account_name}}</span>
        </div>
        <div class="row">
          <span class="label">Account Number</span>
          <span class="value">{{data.details.account_number}}</span>
        </div>
        <div class="row">
          <span class="label">Amount</span>
          <span class="value">{{data.details.amount| number:'1.2-2'}}</span>
        </div>
        <div class="row">
          <span class="label">Biller Code</span>
          <span class="value">{{data.details.biller_code}}</span>
        </div>
        <div class="row">
          <span class="label">Recipient Mobile No</span>
          <span class="value">{{data.details.recipient_mobile_no}}</span>
        </div>
        <div class="row">
          <span class="label">Created Date</span>
          <span class="value">{{data.details.created_date| date:'yyyy-MM-dd hh:mm aa'}}</span>
        </div>
        <div class="row">
          <span class="label">Updated Date</span>
          <span class="value">{{data.details.updated_date| date:'yyyy-MM-dd hh:mm aa'}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="container mat-dialog-content" *ngIf="data.type == 'CASH_IN'">
    <div class="card">
        <h2 class="title">Transaction Details</h2>
        <div class="details">
          <div class="row">
            <span class="label">Transaction ID</span>
            <span class="value">{{data.details.transaction_id}}</span>
          </div>
          <div class="row">
            <span class="label">Source Account Number</span>
            <span class="value">{{data.details.source_account_number}}</span>
          </div>
          <div class="row">
            <span class="label">Source Bank Code</span>
            <span class="value">{{data.details.source_bank_code}}</span>
          </div>
          <div class="row">
            <span class="label">Destination Account Number</span>
            <span class="value">{{data.details.destination_account_number}}</span>
          </div>
          <div class="row">
            <span class="label">Amount</span>
            <span class="value">{{data.details.amount| number:'1.2-2'}}</span>
          </div>
          <div class="row">
            <span class="label">Amount Currency</span>
            <span class="value">{{data.details.amount_currency}}</span>
          </div>
          <div class="row">
            <span class="label">Settlement Rail</span>
            <span class="value">{{data.details.settlement_rail}}</span>
          </div>
          <div class="row">
            <span class="label">Partner Status</span>
            <span class="value" [ngClass]="{'warning': data.details.partner_status === 'PENDING', 'completed': data.details.partner_status === 'COMPLETED'}">{{data.details.partner_status}}</span>
          </div>
          <div class="row">
            <span class="label">Created Date</span>
            <span class="value">{{data.details.created_date | date:'yyyy-MM-dd hh:mm aa'}}</span>
          </div>
          <div class="row">
            <span class="label">Updated Date</span>
            <span class="value">{{data.details.updated_date | date:'yyyy-MM-dd hh:mm aa'}}</span>
          </div>
    
          
        </div>
      </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </div>
  