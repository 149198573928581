<div class="mat-elevation-z8">
   

  <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="partner_reference_no">
      <th mat-header-cell *matHeaderCellDef> Partner Reference No. </th>
      <td mat-cell *matCellDef="let element"> {{element.partner_reference_no}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="generated_account_number">
        <th mat-header-cell *matHeaderCellDef> Generated Account Number </th>
        <td mat-cell *matCellDef="let element"> {{element.generated_account_number}} </td>
    </ng-container>

      <!--- Define columns -->
      <ng-container matColumnDef="amount_currency">
        <th mat-header-cell *matHeaderCellDef> Currency </th>
        <td mat-cell *matCellDef="let element"> {{element.amount_currency}} </td>
      </ng-container>
    
    <!--- Define columns -->
    <ng-container matColumnDef="requested_amount">
        <th mat-header-cell *matHeaderCellDef > Requested Amount </th>
        <td mat-cell *matCellDef="let element" > {{element.requested_amount | number:'1.2-2'}} </td>
        </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Deposit Status </th>
            <td mat-cell *matCellDef="let element" 
                [ngClass]="{
                    'warning': element.cash_in_details.length === 0,
                    'completed': element.cash_in_details.length !== 0
                }">
                {{element.cash_in_details.length === 0 ? 'WAITING FOR PAYMENT' : 'PAYMENT RECEIVED'}} 
            </td>
        </ng-container>

         <!--- Define columns -->
     <ng-container matColumnDef="requested_date">
        <th mat-header-cell *matHeaderCellDef > Requested Date </th>
        <td mat-cell *matCellDef="let element" > {{element.requested_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>


      <!-- qr_code_base64 -->
          
                      <!--- Define columns -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
        <td mat-cell *matCellDef="let element" class="right-align"> 
          <button *ngIf="element.cash_in_details.length !== 0"   mat-raised-button color="primary" (click)="openDialog(element.cash_in_details[0])" class="button-spacing">Payment Details</button> 
          <button *ngIf="element.cash_in_details.length == 0"   mat-raised-button color="warning" (click)="openDialogPaymentInstructions(element)"   class="button-spacing">Payment Instructions</button>
        </td>
      </ng-container>


    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      
</div>