import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DigiDineService } from 'src/app/services/digi-dine.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCategoryDialogComponent } from '../../components/add-category-dialog/add-category-dialog.component';
import { EditCategoryDialogComponent } from '../../components/edit-category-dialog/edit-category-dialog.component';


@Component({
  selector: 'app-digi-dine-category',
  templateUrl: './digi-dine-category.component.html',
  styleUrls: ['./digi-dine-category.component.less']
})
export class DigiDineCategoryComponent implements OnInit {

  private paramSubscription: Subscription | undefined;
  constructor(private route: ActivatedRoute,private digiDineService : DigiDineService,
    public dialog: MatDialog) { }
  outletHashId : string ="";

  
  displayedColumns: string[] = ['name', 'is_active', 'created_date', 'updated_date','action' ]; //'id', 
  

  dataSource = new MatTableDataSource([]);
  isLoadingResults = false;
  pageSize: number = 10;
  totalItems!: number;
  currentPage: number = 1;

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.outletHashId = params['param1']; 
      this.fetchData(this.currentPage, this.pageSize);
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }


  fetchData(page: number, pageSize: number): void {
    this.isLoadingResults = true;  // Spinner starts before API call
    this.digiDineService.getCategories(page, pageSize,this.outletHashId).subscribe(response => {
      this.dataSource.data = response.response_data.items;
      this.totalItems = response.response_data.total_count;
      this.isLoadingResults = false;  // Spinner stops after API call
    },error=>{
      this.dataSource.data = [];
      this.totalItems = 0;
      this.isLoadingResults = false
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchData(this.currentPage, this.pageSize);
  }


  openAddCategoryDialog(): void {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '500px',
      // Pass any data you need to the dialog here
      data: this.outletHashId
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
      this.fetchData(this.currentPage, this.pageSize);
      console.log('The dialog was closed');
    });
  }

  openEditCategoryDialog(element : any): void {
    const dialogRef = this.dialog.open(EditCategoryDialogComponent, {
      width: '500px',
      // Pass any data you need to the dialog here
      data: {
        outlet_hash_id : this.outletHashId,
        model : element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog here. This could be a new category or an error
      this.fetchData(this.currentPage, this.pageSize);
      console.log('The dialog was closed');
    });
  }
  
}
