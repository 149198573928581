import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VipCheckoutService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getVIPCheckoutOptions(): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}checkout_options`;

    return this.http.get(url, { headers: headers });
  }

  generateVIPCheckout(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}generate_checkout`, data, { headers });
  }

  syncVIPCheckout(transactionId: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };

    var data = {
      'transaction_id' : transactionId
    }
    return this.http.post<any>(`${this.baseUrl}sync_checkout`, data, { headers });
  }

  getVIPCheckouts(pageNumber: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}vip_checkouts?page_number=${pageNumber}&page_size=${pageSize}`;

    return this.http.get(url, { headers: headers });
  }


  getVIPCheckoutOptionsCh2(): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}checkout_options_ch2`;

    return this.http.get(url, { headers: headers });
  }



  generateVIPCheckoutCh2(data: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(`${this.baseUrl}generate_checkout_ch2`, data, { headers });
  }

  syncVIPCheckoutV2(transactionId: any): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = { 
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'
    };

    var data = {
      'transaction_id' : transactionId
    }
    return this.http.post<any>(`${this.baseUrl}sync_checkout_ch2`, data, { headers });
  }


  
  getAcquiredQRPHS(pageNumber: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}acquired_qrphs?page_number=${pageNumber}&page_size=${pageSize}`;

    return this.http.get(url, { headers: headers });
  }

  getAvailableQRPHS(pageNumber: number, pageSize: number): Observable<any> {
    let token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}qrph_cashins_sandbox?page_number=${pageNumber}&page_size=${pageSize}`;

    return this.http.get(url, { headers: headers });
  }
  
}
