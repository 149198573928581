<h2 mat-dialog-title>Transactions</h2>
<mat-dialog-content>
  <table mat-table [dataSource]="data.details">

    <!-- Define the columns -->
    <!-- Add *ngIf in mat-cell to handle null values -->
    <ng-container matColumnDef="transactionType">
      <th mat-header-cell *matHeaderCellDef> Transaction Type </th>
      <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
    </ng-container>

    <ng-container matColumnDef="transactionName">
        <th mat-header-cell *matHeaderCellDef> Transaction Name </th>
        <td mat-cell *matCellDef="let element"> {{element.transactionName}} </td>
      </ng-container>

      <ng-container matColumnDef="transactionCount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Transaction Count </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.transactionCount}} </td>
      </ng-container>

      <ng-container matColumnDef="actualTransactionCount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Actual Transaction Count </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.actualTransactionCount}} </td>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Total Amount </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.totalAmount| number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="actualTransactionAmount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Actual Total Amount </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.actualTransactionAmount| number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="discrepancyCount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Discrepancy Count </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.discrepancyCount}} </td>
      </ng-container>

      <ng-container matColumnDef="discrepancyAmount">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Discrepancy Amount </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.discrepancyAmount| number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="upfrontRebate">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Upfront Rebate </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.upfrontRebate| number:'1.2-2'}} </td>
      </ng-container>

   

    <!-- Add other columns in the same way -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
