<div class="mat-elevation-z8">

    <h3 style="margin-left: 15px;">Amount for Settlement : <strong>PHP {{forSettlement | number:'1.2-2'}}</strong>
    <br/> Count for Settlement : <strong>{{forSettlementCount }}</strong> </h3>
    <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
      <!--- Define columns -->
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef> Recipient </th>
        <td mat-cell *matCellDef="let element"> {{element.recipient}} </td>
      </ng-container>
  
       <!--- Define columns -->
       <ng-container matColumnDef="amount_cur">
          <th mat-header-cell *matHeaderCellDef> Currency</th>
          <td mat-cell *matCellDef="let element"> {{element.amount_cur}} </td>
      </ng-container>
  
        <!--- Define columns -->
        <ng-container matColumnDef="amount_num">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.amount_num| number:'1.2-2'}} </td>
        </ng-container>

        <!--- Define columns -->
        <ng-container matColumnDef="reference_no">
            <th mat-header-cell *matHeaderCellDef> Reference Number </th>
            <td mat-cell *matCellDef="let element"> {{element.reference_no}} </td>
          </ng-container>

             <!--- Define columns -->
        <ng-container matColumnDef="qrph_invoice_no">
          <th mat-header-cell *matHeaderCellDef> QRPH Invoice Number </th>
          <td mat-cell *matCellDef="let element"> {{element.qrph_invoice_no}} </td>
        </ng-container>
  
          
          <!--- Define columns -->
          <ng-container matColumnDef="payment_method">
            <th mat-header-cell *matHeaderCellDef > Payment Method </th>
            <td mat-cell *matCellDef="let element" > <strong>{{element.payment_method}} </strong> </td>
            </ng-container>

            

              <!--- Define columns -->
          <ng-container matColumnDef="transaction_state">
            <th mat-header-cell *matHeaderCellDef > Transaction State </th>
            <td mat-cell *matCellDef="let element" ><strong>{{element.transaction_state}} </strong> </td>
            </ng-container>
  
  
           <!--- Define columns -->
       <ng-container matColumnDef="created_date">
          <th mat-header-cell *matHeaderCellDef > Created Date </th>
          <td mat-cell *matCellDef="let element" > {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>

             <!--- Define columns -->
       <ng-container matColumnDef="transaction_date">
        <th mat-header-cell *matHeaderCellDef > Transaction Date </th>
        <td mat-cell *matCellDef="let element" > {{element.transaction_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

         <!--- Define columns -->
       <ng-container matColumnDef="datetime_acquired">
        <th mat-header-cell *matHeaderCellDef > Acquired Date </th>
        <td mat-cell *matCellDef="let element" > {{element.datetime_acquired | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>
  
  
        <!-- qr_code_base64 -->
            
                        <!--- Define columns -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
          <td mat-cell *matCellDef="let element" class="right-align"> 
            <!-- <button *ngIf="element.status != 'OK.00.00' && !element.status.startsWith('ER')"   mat-raised-button color="primary" (click)="syncStatus(element)"   class="button-spacing">Sync Status</button> -->
          </td>
        </ng-container>
  
  
      <!-- Other columns go here in the same way -->
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <div class="paginator-container">
          <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
          <mat-paginator [length]="totalItems"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         (page)="onPageChange($event)">
          </mat-paginator>
        </div>
        
        
  </div>