import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-ledger-dialog',
  templateUrl: './user-ledger-dialog.component.html',
  styleUrls: ['./user-ledger-dialog.component.less']
})
export class UserLedgerDialogComponent  {

  constructor(
    public dialogRef: MatDialogRef<UserLedgerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userId: any}) { }

}
