
<div class="list-add">
    <button mat-raised-button color="primary" (click)="openAddProductDialog()">Add Product</button>
</div>

<!-- displayedColumns: string[] = ['id', 'name', 'image' , 'price', 'description','category_name' ,'is_active',  'created_date', 'updated_date','action' ]; -->

<table mat-table matSort matTableResponsive [dataSource]="dataSource" >
    <!--- Define columns -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

     <!--- Define columns -->
     <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!--- Define columns -->
    <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Image </th>
        <td mat-cell *matCellDef="let element"> 
            <img [src]="element.image" alt="Image description" style="width:100px; height:auto;"> 
        </td>
    </ng-container>


      <!--- Define columns -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let element"> {{element.price| number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="description-column"> Description </th>
        <td mat-cell *matCellDef="let element" class="description-column"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="category_name">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let element"> {{element.category_name}} </td>
    </ng-container>
    

        <!--- Define columns -->
        <ng-container matColumnDef="show_in_kitchen">
            <th mat-header-cell *matHeaderCellDef> Show in Kitchen </th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle color="primary"  [(ngModel)]="element.show_in_kitchen" [disabled]="true"></mat-slide-toggle>
            </td>
        </ng-container>

    <!--- Define columns -->
    <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
            <mat-slide-toggle color="primary"  [(ngModel)]="element.is_active" [disabled]="true"></mat-slide-toggle>
        </td>
    </ng-container>
    

     
         <!--- Define columns -->
     <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Created Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>

          <!--- Define columns -->
     <ng-container matColumnDef="updated_date">
        <th mat-header-cell *matHeaderCellDef class="right-align"> Updated Date </th>
        <td mat-cell *matCellDef="let element" class="right-align"> {{element.updated_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
      </ng-container>


  <!--- Define columns -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
    <td mat-cell *matCellDef="let element" class="right-align"> <button mat-raised-button color="primary" (click)="openEditProductDialog(element)"  class="button-spacing">Edit</button> </td>
  </ng-container>


    <!-- Other columns go here in the same way -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div class="paginator-container">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
        <mat-paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      
      