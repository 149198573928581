
<div class="card-container">

<mat-card class="login-card">

   
    <p class="mat-body">This demo presents a walkthrough on how to acquire a direct payment via QRPH.</p>
    <mat-label>
        The <code>reference_no</code> is a unique identifier provided by your client during their transfer transaction.
      </mat-label>
      <mat-label>
        The <code>amount</code> represents the monetary value specified by your client in the transfer transaction.
      </mat-label>



    <form [formGroup]="requestVIPPayForm" (ngSubmit)="onSubmit()" class="login-form">
        <mat-form-field>
          <input matInput placeholder="Reference Number" formControlName="reference_no" required>
      </mat-form-field>
        
        <mat-form-field>
          <input matInput placeholder="Amount" formControlName="amount" required>
      </mat-form-field>
    
          
        
          <button mat-raised-button type="submit" [disabled]="requestVIPPayForm.invalid || isLoading" class="login-button" >
              
              <span *ngIf="!isLoading">Submit</span>
              <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
          </button>
  
          
        </form>
  
  </mat-card>
  

    
  <mat-card class="right-card">
    <p class="mat-body">System-generated sample transaction reference number and amount (for testing purposes only).</p>

    <div class="mat-elevation-z8">
      <table mat-table matSort matTableResponsive [dataSource]="dataSource" >
        <!--- Define columns -->
        <ng-container matColumnDef="recipient">
          <th mat-header-cell *matHeaderCellDef> Recipient </th>
          <td mat-cell *matCellDef="let element"> {{element.recipient}} </td>
        </ng-container>
    
         <!--- Define columns -->
         <ng-container matColumnDef="amount_cur">
            <th mat-header-cell *matHeaderCellDef> Currency</th>
            <td mat-cell *matCellDef="let element"> {{element.amount_cur}} </td>
        </ng-container>
    
          <!--- Define columns -->
          <ng-container matColumnDef="amount_num">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.amount_num| number:'1.2-2'}} </td>
          </ng-container>
  
          <!--- Define columns -->
          <ng-container matColumnDef="reference_no">
              <th mat-header-cell *matHeaderCellDef> Reference Number </th>
              <td mat-cell *matCellDef="let element"> {{element.reference_no}} </td>
            </ng-container>

            <ng-container matColumnDef="qrph_invoice_no">
              <th mat-header-cell *matHeaderCellDef> QRPH Invoice Number </th>
              <td mat-cell *matCellDef="let element"> {{element.qrph_invoice_no}} </td>
            </ng-container>

            
    
            <!--- Define columns -->
            <ng-container matColumnDef="payment_method">
              <th mat-header-cell *matHeaderCellDef > Payment Method </th>
              <td mat-cell *matCellDef="let element" > <strong>{{element.payment_method}} </strong> </td>
              </ng-container>
  
              
  
                <!--- Define columns -->
            <ng-container matColumnDef="transaction_state">
              <th mat-header-cell *matHeaderCellDef > Transaction State </th>
              <td mat-cell *matCellDef="let element" ><strong>{{element.transaction_state}} </strong> </td>
              </ng-container>
    
    
             <!--- Define columns -->
         <ng-container matColumnDef="created_date">
            <th mat-header-cell *matHeaderCellDef > Created Date </th>
            <td mat-cell *matCellDef="let element" > {{element.created_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
          </ng-container>
  
               <!--- Define columns -->
         <ng-container matColumnDef="transaction_date">
          <th mat-header-cell *matHeaderCellDef > Transaction Date </th>
          <td mat-cell *matCellDef="let element" > {{element.transaction_date | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>
  
           <!--- Define columns -->
         <ng-container matColumnDef="datetime_acquired">
          <th mat-header-cell *matHeaderCellDef > Acquired Date </th>
          <td mat-cell *matCellDef="let element" > {{element.datetime_acquired | date:'yyyy-MM-dd hh:mm aa'}} </td>
        </ng-container>
    
    
          <!-- qr_code_base64 -->
              
                          <!--- Define columns -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Action </th>
            <td mat-cell *matCellDef="let element" class="right-align"> 
              <!-- <button *ngIf="element.status != 'OK.00.00' && !element.status.startsWith('ER')"   mat-raised-button color="primary" (click)="syncStatus(element)"   class="button-spacing">Sync Status</button> -->
            </td>
          </ng-container>
    
    
        <!-- Other columns go here in the same way -->
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <div class="paginator-container">
            <mat-spinner *ngIf="isLoadingResults" [diameter]="25"></mat-spinner>
            <mat-paginator [length]="totalItems"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="[5]"
                           (page)="onPageChange($event)">
            </mat-paginator>
          </div>
          
          
    </div>
  </mat-card>
  </div>
  

