
  
    

    <button type="button" mat-icon-button (click)="onClose()" class="floating-button">
        <mat-icon class="material-icons-outlined">close
        </mat-icon>
        <!--  *ngIf="!drawer.opened"<mat-icon *ngIf="drawer.opened" class="material-icons-outlined">close</mat-icon> -->
      </button>
    <button *ngIf="tableNumberId && totalSum > 0" (click)="payNow()" type="button" mat-icon-button class="floating-button-out" style="background-color: green;">
    <label class="material-icons-outlined" style="font-size: x-small;" >PAY NOW</label>
  </button>
  
<div class="order-list" *ngIf="orders.length==0">
    NO ORDER YET
  </div>
  <div class="order-list" *ngIf="orders.length>0">
    <div class="order-item" *ngFor="let order of orders">

      <div class="order-item-inner">
        <img class="product-image-order rounded-image" [src]="order.data().image" alt="Product image">

        <div class="order-content" style="align-items: start;">
          <label class="order-title-order">{{order.data().name}}</label>
          <strong *ngIf="order.data().quantity > 1" style="color:green">&nbsp;&nbsp;{{order.data().quantity}} Orders</strong> 
        </div>
      </div>
      <div class="order-content" style="margin-right: 5px;">
        <p class="order-price">₱ {{(order.data().price * order.data().quantity) | number:'1.2-2' }}</p>
        <label [ngClass]="{'status-new': order.data().status === 'NEW',
            'status-preparing': order.data().status === 'PREPARING',
            'status-ready': order.data().status === 'READY',
            'status-served': order.data().status === 'SERVED'}">
          {{order.data().status}}
        </label>
      </div>
    </div>
  </div>
  <br/> <br/>
    <mat-card-subtitle style="margin:0px" class="subtitle-amount-bill">Bill : ₱ {{totalSum | number:'1.2-2'}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="orderSession.discount_amount" style="margin:0px" class="subtitle-amount-bill">Discount : ₱ {{orderSession.discount_amount| number:'1.2-2'}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="orderSession.discount_amount" style="margin:0px; color: red;" class="subtitle-amount-bill">Amount to be Paid : ₱ {{totalSum-orderSession.discount_amount| number:'1.2-2'}}</mat-card-subtitle>
    <br/> <br/>
    <!-- <button mat-raised-button color="warn" >Pay Now</button> -->