<!-- loading-spinner-overlay.component.html -->
<div *ngIf="isLoading" class="overlay-container">
    <mat-spinner></mat-spinner>
</div>


<div style="padding: 10px;">
    <button *ngFor="let table of removeOccupiedTables(dataSource,tables)"
        style="background-color: green; color: white; font-weight: bolder;width: 90px;height: 90px;"
        (click)="startSession(table.table_number)">OPEN TABLE #{{table.table_number}}</button>
</div>

<div class="parent">

    <div class="left-child">
        <mat-drawer-container class="example-container" autosize style="padding-bottom: 100px !important;">
            <mat-drawer-content>
                <!-- Header Container -->

                <div *ngFor="let table of tables"
                    style="max-width: fit-content; float: left; border: black solid 1px; margin: 5px;">
                    <div class="header-container">
                        <h1 class="header-title">Table Number {{table.data().table_number}}</h1>
                    </div>
                    <div class="header-container" *ngIf="orders[table.id].length == 0">
                        <h3 class="header-title">NO ORDER YET {{table.data().table_number}}</h3>
                    </div>

                    <div class="cards-container" *ngIf="orders[table.id].length == 0"> <br />
                        <button mat-raised-button (click)="killSession(table.id)"
                            style="background-color: blue; color: white; padding: 10px !important;">KILL
                            SESSION</button><br /><br />
                    </div>

                    <div class="cards-container" *ngIf="orders[table.id].length > 0">

                        <div class="order-item" *ngFor="let order of orders[table.id]">
                            <div class="order-item-inner">
                                <button *ngIf="order.data().status !== 'SERVED'"
                                    (click)="cancelOrder(table.id,order.id,order.data().name,order.data().quantity)"
                                    style="background-color: red; color: white;"><strong>X</strong></button>

                                <div class="order-content" style="align-items: start;">
                                    <label [ngClass]="{'strikethrough': order.data().status === 'SERVED'}"
                                        class="order-title-order">{{order.data().name}}</label>
                                    <strong [ngClass]="{'strikethrough': order.data().status === 'SERVED'}"
                                        class="order-title-order" style="color: green;">{{order.data().quantity}}
                                        Order{{order.data().quantity > 1 ? "s":""}}</strong>
                                </div>
                            </div>
                            <div class="order-content" style="margin-right: 5px;">
                                <p class="order-price">₱ {{(order.data().price*order.data().quantity) | number:'1.2-2'
                                    }}</p>
                                <div class="order-content"
                                    *ngIf="order.data().status !== 'SERVED' && order.elapsedTime">
                                    <strong class="order-price" style="color:blue">{{order.elapsedTime}}</strong>
                                </div>
                                <div class="order-content"
                                    *ngIf="order.data().status !== 'SERVED' && !order.elapsedTime">
                                    <strong class="order-price" style="color:blue">Loading...</strong>
                                </div>
                                <div *ngIf="order.data().show_in_kitchen === true">
                                    <button *ngIf="order.data().status === 'NEW'" mat-raised-button
                                        style="background-color: black; color: white;">WAITING TO COOK</button>
                                    <button *ngIf="order.data().status === 'PREPARING'" mat-raised-button
                                        style="background-color: black; color: white;">PREPARING FOOD</button>
                                    <button *ngIf="order.data().status === 'READY'" mat-raised-button
                                        (click)="updateOrderStatus(table.id,order.id,'SERVED')"
                                        style="background-color: orange; color: black;">TAG AS SERVED</button>
                                </div>

                            </div>

                        </div>

                        <hr />
                        <!-- TOTAL AMOUNT -->
                        <div class="order-item">
                            <div class="order-item-inner">

                                <div class="order-content">
                                    <label class="order-title-order" style="font-weight: bolder;">Total Bill</label>
                                </div>
                            </div>
                            <div class="order-content" style="margin-right: 5px;">
                                <p class="order-price" style="color:green;font-weight: bolder;">₱
                                    {{totalAmount(orders[table.id]) | number:'1.2-2' }}</p>
                            </div>
                        </div>

                        <div *ngIf="table.data().discount_amount" class="order-item">
                            <div class="order-item-inner">
                                <div class="order-content">
                                    <label class="order-title-order" style="font-weight: bolder;">Discount
                                        Amount</label>
                                </div>
                            </div>
                            <div class="order-content" style="margin-right: 5px;">
                                <p class="order-price" style="color:green;font-weight: bolder;">₱
                                    {{table.data().discount_amount | number:'1.2-2' }}</p>
                            </div>
                        </div>

                        <div *ngIf="table.data().discount_amount" class="order-item">
                            <div class="order-item-inner">
                                <div class="order-content">
                                    <label class="order-title-order" style="font-weight: bolder;">Final Bill</label>
                                </div>
                            </div>
                            <div class="order-content" style="margin-right: 5px;">
                                <p class="order-price" style="color:green;font-weight: bolder;">₱
                                    {{(totalAmount(orders[table.id])-table.data().discount_amount) | number:'1.2-2' }}
                                </p>
                            </div>
                        </div>

                        <!-- TOTAL AMOUNT -->

                    </div>
                    <div class="header-container" *ngIf="checkIfAllServed(orders[table.id])">
                        <br />  
                        <label *ngIf="table.data().payment_status == 'PAID'" mat-raised-button
                            style="background-color: green; color: white; padding: 10px !important;">Paid Via
                            {{table.data().payment_type}}</label>
                    </div>
                    <div class="header-container" *ngIf="checkIfAllServed(orders[table.id])">
                        <br />
                        <button *ngIf="table.data().payment_status != 'PAID'" mat-raised-button
                            (click)="addDiscount(table.id,totalAmount(orders[table.id]),table.data().discount_amount) "
                            style="background-color: red; color: white; padding: 10px !important;">DISCOUNT</button>

                        &nbsp; &nbsp; &nbsp;
                        <button mat-raised-button
                            (click)="billOut(table.id,totalAmount(orders[table.id]), table,table.data().discount_amount) "
                            style="background-color: blue; color: white; padding: 10px !important;">BILL OUT</button>
                        <br />
                        <br />
                    </div>
                </div>

            </mat-drawer-content>


        </mat-drawer-container>
    </div>

    <div class="right-child">

        <div class="cards-container" *ngFor="let order of sortFoodByCreatedDateAsc(foodOrders)" style="margin: 5px;">
            <div class="order-item">
                <div class="order-item-inner">

                    <div class="order-content" style="align-items: start;">
                        <label class="order-title-order">{{order.name}}</label>
                        <strong class="order-title-order">Table Number {{order.table_number}}</strong>
                    </div>
                </div>
                <div class="order-content" style="margin-right: 5px;">
                    <p class="order-price">₱ {{order.price | number:'1.2-2' }}</p>

                    <button *ngIf="order.status === 'NEW'" mat-raised-button
                        (click)="updateOrderStatus(order.tableNumberId,order.id,'SERVED')"
                        style="background-color: orange; color: white;">TAG AS SERVED</button>
                    <div class="order-content" *ngIf="order.status !== 'SERVED' && order.elapsedTime">
                        <strong class="order-price" style="color:blue">{{order.elapsedTime}}</strong>
                    </div>
                    <div class="order-content" *ngIf="order.status !== 'SERVED' && !order.elapsedTime">
                        <strong class="order-price" style="color:blue">Loading...</strong>
                    </div>
                </div>
            </div>
            <hr />


        </div>



    </div>

</div>