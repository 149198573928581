import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatGridListModule } from '@angular/material/grid-list';

import { LoginComponent } from './pages/login/login.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { OutletsComponent } from './pages/outlets/outlets.component';
import { AuthInterceptor } from './security/auth.interceptor';
import { RegisteredCashInComponent } from './pages/registered-cash-in/registered-cash-in.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { OutletHashDialogComponent } from './components/outlet-hash-dialog/outlet-hash-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CashInsComponent } from './pages/cash-ins/cash-ins.component';
import { BanksComponent } from './pages/banks/banks.component';
import { CashOutsComponent } from './pages/cash-outs/cash-outs.component';
import { CashOutFormComponent } from './pages/cash-out-form/cash-out-form.component';
import { OutletLedgersComponent } from './pages/outlet-ledgers/outlet-ledgers.component';
import { TransactionDetailDialogComponent } from './components/transaction-detail-dialog/transaction-detail-dialog.component';
import { SimulateCashInComponent } from './pages/simulate-cash-in/simulate-cash-in.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { RequestPaymentComponent } from './pages/request-payment/request-payment.component';
import { PaymentInstructionsComponent } from './components/payment-instructions/payment-instructions.component';
import { VipPaysComponent } from './pages/vip-pays/vip-pays.component';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive.directive';
import { LoginGVCComponent } from './pages-govipcenter/login/login-gvc.component';
import { DashboardGvcComponent } from './pages-govipcenter/dashboard-gvc/dashboard-gvc.component';
import { AuditTransactionsComponent } from './pages-govipcenter/audit-transactions/audit-transactions.component';
import { UserTransactionSummaryComponent } from './components/user-transaction-summary/user-transaction-summary.component';
import { UserTransactionLedgerComponent } from './pages-govipcenter/user-transaction-ledger/user-transaction-ledger.component';
import { UserLedgerDialogComponent } from './components/user-ledger-dialog/user-ledger-dialog.component';
import { DynamicTableDialogComponent } from './components/dynamic-table-dialog/dynamic-table-dialog.component';
import { UnifiedTransactionLedgerComponent } from './pages-govipcenter/unified-transaction-ledger/unified-transaction-ledger.component';
import { UnifiedLedgerDialogComponent } from './components/unified-ledger-dialog/unified-ledger-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DigiDineModulesComponent } from './pages/applications/digi-dine/digi-dine-modules/digi-dine-modules.component';
import { DigiDineCategoryComponent } from './pages/applications/digi-dine/digi-dine-category/digi-dine-category.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AddCategoryDialogComponent } from './pages/applications/components/add-category-dialog/add-category-dialog.component';
import { EditCategoryDialogComponent } from './pages/applications/components/edit-category-dialog/edit-category-dialog.component';
import { DigiDineProductComponent } from './pages/applications/digi-dine/digi-dine-product/digi-dine-product.component';
import { AddProductDialogComponent } from './pages/applications/components/add-product-dialog/add-product-dialog.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EditProductDialogComponent } from './pages/applications/components/edit-product-dialog/edit-product-dialog.component';
import { DigiDineTableComponent } from './pages/applications/digi-dine/digi-dine-table/digi-dine-table.component';
import { DigiDineMenuComponent } from './pages/applications/digi-dine/digi-dine-menu/digi-dine-menu.component';
import { PublicAddOrderDialogComponent } from './pages/applications/components/public-add-order-dialog/public-add-order-dialog.component';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { DigiDineActiveOrdersComponent } from './pages/applications/digi-dine/digi-dine-active-orders/digi-dine-active-orders.component';
import { DigiDineActiveOrdersKitchenComponent } from './pages/applications/digi-dine/digi-dine-active-orders-kitchen/digi-dine-active-orders-kitchen.component';
import { BillOutDialogComponent } from './pages/applications/components/bill-out-dialog/bill-out-dialog.component';
import { DigiDineOrderHistoryComponent } from './pages/applications/digi-dine/digi-dine-order-history/digi-dine-order-history.component';
import { OrderDetailsDialogComponent } from './pages/applications/components/order-details-dialog/order-details-dialog.component';
import { BillDetailsDialogComponent } from './pages/applications/components/bill-details-dialog/bill-details-dialog.component';
import { AppPaymentInstructionsDialogComponent } from './pages/applications/components/app-payment-instructions-dialog/app-payment-instructions-dialog.component';
import { AddDiscountDialogComponent } from './pages/applications/components/add-discount-dialog/add-discount-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RequestCheckoutComponent } from './pages/request-checkout/request-checkout.component';
import { CheckoutThankyouComponent } from './pages/checkout-thankyou/checkout-thankyou.component';
import { CheckoutsComponent } from './pages/checkouts/checkouts.component';
import { RequestCheckoutCh2Component } from './pages/request-checkout-ch2/request-checkout-ch2.component';
import { CheckoutThankyouCh2Component } from './pages/checkout-thankyou-ch2/checkout-thankyou-ch2.component';
import { DirectPaymentComponent } from './pages/direct-payment/direct-payment.component';
import { DirectPaymentsAcquiredComponent } from './pages/direct-payments-acquired/direct-payments-acquired.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorDialogComponent,
    DashboardComponent,
    HomeComponent,
    SettingsComponent,
    OutletsComponent,
    RegisteredCashInComponent,
    OutletHashDialogComponent,
    ConfirmationDialogComponent,
    CashInsComponent,
    BanksComponent,
    CashOutsComponent,
    CashOutFormComponent,
    OutletLedgersComponent,
    TransactionDetailDialogComponent,
    SimulateCashInComponent,
    BreadCrumbComponent,
    RequestPaymentComponent,
    PaymentInstructionsComponent,
    VipPaysComponent,
    LoginGVCComponent,
    MatTableResponsiveDirective,
    DashboardGvcComponent,
    AuditTransactionsComponent,
    UserTransactionSummaryComponent,
    UserTransactionLedgerComponent,
    UserLedgerDialogComponent,
    DynamicTableDialogComponent,
    UnifiedTransactionLedgerComponent,
    UnifiedLedgerDialogComponent,
    DigiDineModulesComponent,
    DigiDineCategoryComponent,
    AddCategoryDialogComponent,
    EditCategoryDialogComponent,
    DigiDineProductComponent,
    AddProductDialogComponent,
    EditProductDialogComponent,
    DigiDineTableComponent,
    DigiDineMenuComponent,
    PublicAddOrderDialogComponent,
    DigiDineActiveOrdersComponent,
    DigiDineActiveOrdersKitchenComponent,
    BillOutDialogComponent,
    DigiDineOrderHistoryComponent,
    OrderDetailsDialogComponent,
    BillDetailsDialogComponent,
    AppPaymentInstructionsDialogComponent,
    AddDiscountDialogComponent,
    RequestCheckoutComponent,
    CheckoutThankyouComponent,
    CheckoutsComponent,
    RequestCheckoutCh2Component,
    CheckoutThankyouCh2Component,
    DirectPaymentComponent,
    DirectPaymentsAcquiredComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatTableModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatSelectModule,
    MatGridListModule,
    MatExpansionModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    MaterialFileInputModule,
    ImageCropperModule,
    QRCodeModule,
    FormsModule,
    ClipboardModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
