
<div class="mat-dialog-content">
    <h3>Order Details</h3>

    <mat-card class="login-card">

<mat-drawer-container class="example-container" autosize >
    <mat-drawer-content>
        <!-- Header Container -->

      

            <div class="cards-container" *ngIf="orders.length > 0">
                <div class="order-item" *ngFor="let order of orders">
                    <div class="order-item-inner">

                        <div class="order-content">
                            <label [ngClass]="{'strikethrough': order.data().status === 'SERVED'}"
                                class="order-title-order">{{order.data().name}} X </label>
                                <strong [ngClass]="{'strikethrough': order.data().status === 'SERVED'}"
                                        class="order-title-order" style="color: green;">{{order.data().quantity}} Order{{order.data().quantity > 1 ? "s":""}}</strong>
                               
                        </div>
                    </div>
                    <div class="order-content" style="margin-right: 5px;">
                        <p class="order-price">₱ {{(order.data().price * order.data().quantity) | number:'1.2-2' }}</p>
                    </div>
                </div>
<hr/>
                <!-- TOTAL AMOUNT -->
                <div class="order-item" >
                    <div class="order-item-inner">

                        <div class="order-content">
                            <label class="order-title-order" style="font-weight: bolder;">Total Amount</label>
                        </div>
                    </div>
                    <div class="order-content" style="margin-right: 5px;">
                        <p class="order-price" style="color:green;font-weight: bolder;">₱ {{totalSum | number:'1.2-2' }}</p>

                    </div>
                </div>
                <div *ngIf="orderSession.discount_amount" class="order-item" >
                    <div class="order-item-inner">

                        <div class="order-content">
                            <label class="order-title-order" style="font-weight: bolder;">Discount</label>
                        </div>
                    </div>
                    <div class="order-content" style="margin-right: 5px;">
                        <p class="order-price" style="color:green;font-weight: bolder;">₱ {{orderSession.discount_amount | number:'1.2-2' }}</p>

                    </div>
                </div>

                <div *ngIf="orderSession.discount_amount" class="order-item" >
                    <div class="order-item-inner">

                        <div class="order-content">
                            <label class="order-title-order" style="font-weight: bolder;">Total Bill</label>
                        </div>
                    </div>
                    <div class="order-content" style="margin-right: 5px;">
                        <p class="order-price" style="color:green;font-weight: bolder;">₱ {{totalSum -orderSession.discount_amount | number:'1.2-2' }}</p>

                    </div>
                </div>

                
                <!-- TOTAL AMOUNT -->

            </div>
          
       

    </mat-drawer-content>


</mat-drawer-container>

</mat-card>

</div>


