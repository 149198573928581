<div class="mat-elevation-z8">
      <table mat-table matSort matTableResponsive [dataSource]="bankDetails" >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>
  
      <ng-container matColumnDef="amountFrom">
        <th mat-header-cell *matHeaderCellDef> Amount From </th>
        <td mat-cell *matCellDef="let element"> {{element.amountFrom | number:'1.2-2'}} </td>
      </ng-container>
  
      <ng-container matColumnDef="amountTo">
        <th mat-header-cell *matHeaderCellDef> Amount To </th>
        <td mat-cell *matCellDef="let element"> {{element.amountTo | number:'1.2-2'}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  